<template>
  <v-container fill-height fluid class="overflow-auto px-sm-7 pb-7">

    <FormGallery ref="formGallery" @formGallerySaved="createUpdateData"></FormGallery>
    <DialogDeleteConfirmation ref="dialogDeleteConfirmation" @dialogDeleteConfirmed="deleteData"></DialogDeleteConfirmation>

    <v-snackbar :color="snackbar.color" v-model="snackbar.show" timeout="5000">
      <span>{{snackbar.message}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>
    
    <v-row class="justify-center align-self-start">
      <v-col cols="12" sm="10" md="10">
        <v-layout column>
          
          <!-- DETAIL -->
          <v-flex>

            <!-- TITLE -->
            <v-row>
              <v-col cols="12">
                <span class="title d-flex"><v-icon class="mr-2">mdi-image-multiple-outline</v-icon>Galleries</span>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-progress-linear :style="isLoadingData ? '' : 'visibility:hidden'" indeterminate color="bg-teal"></v-progress-linear>

            <!-- SEARCH BOX -->
            <v-row>
              <v-col cols="12">
                <v-text-field dense solo :disabled="isLoadingData" v-model="searchValue" append-icon="mdi-magnify" label="Search" single-line hide-details>
                </v-text-field>
              </v-col>
            </v-row>

          </v-flex>
          
          <!-- PROGRAM LIST -->
          <v-flex xs12 class="mt-5" style="height:0px;">
            <v-col cols="12" class="mt-5 grey--text text-caption-1 text-center" v-if="galleryList(this.searchValue).length === 0">
              No Data Available
            </v-col>

            <v-slide-y-transition hide-on-leave group class="ma-0">
              <v-row v-for="(program, index) in galleryList(this.searchValue)" :key="index">
                <v-col cols="12">
                  <v-card class="pa-5">

                    <!-- PROGRAM NAME -->
                    <v-row>
                      <v-col cols="6" class="fc-black text-admin-title fw-500">{{program.name}}</v-col>
                      <v-col cols="6" class="text-right">
                        <v-btn class="mb-1" :disabled="isLoadingData" small :color="program.view !== 'video' ? 'bg-teal' : 'grey'" :dark="!isLoadingData" 
                          @click="program.view = 'image'"
                        >View Image</v-btn>
                        <v-btn :disabled="isLoadingData" class="ml-2 mb-1" small :color="program.view === 'video' ? 'bg-teal' : 'grey'" :dark="!isLoadingData" 
                          @click="program.view = 'video'"
                        >View Video</v-btn>
                      </v-col>
                    </v-row>

                    <!-- PROGRAM GALLERY -->
                    <v-row v-if="program.view === 'image'">
                      <v-col cols="12" class="d-flex justify-end">
                        <v-btn :disabled="isLoadingData" small color="bg-teal" :dark="!isLoadingData" @click="openFormGallery('add','image',program)">
                          <v-icon left small>mdi-plus</v-icon>Add More Image
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="text-admin-caption grey--text" v-if="program.program_image.length === 0">No image</v-col>
                      <v-col cols="12" v-if="program.program_image.length > 0">
                        <v-row>
                          <v-col cols="12" md="4" lg="3" v-for="(gallery, index) in program.program_image" :key="index">
                            <v-card>
                              <v-img width="100%" height="250"
                                :src="gallery.imgError ? gallery.imgError : (gallery.image && gallery.image.filename) ? imgPath.path+gallery.image.filename+refreshImage : imgPath.imgEmpty"
                                :lazy-src="imgPath.lazyPath"
                                v-on:error="gallery.imgError = imgPath.imgError"
                              >
                                <template v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                  </v-row>
                                </template>

                                <div class="p-absolute d-flex w-100 h-100 justify-end align-start pr-2 pt-2">
                                  <v-chip class="ml-2" small color="bg-teal" text-color="white">
                                    Position <v-avatar right class="grey lighten-3 black--text">{{gallery.position}}</v-avatar>
                                  </v-chip>
                                </div>
                                
                              </v-img>
                              <v-card-actions>
                                <v-btn text color="error" @click="openDialogDeleteConfirmation(gallery.id, program.name+' image with position '+gallery.position)">Delete</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn text class="fc-teal" @click="openFormGallery('edit','image',program,gallery)">Update</v-btn>
                                
                              </v-card-actions>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row v-if="program.view === 'video'">
                      <v-col cols="12" class="d-flex justify-end">
                        <v-btn :disabled="isLoadingData" small color="bg-teal" :dark="!isLoadingData" @click="openFormGallery('add','video',program)">
                          <v-icon left small>mdi-plus</v-icon>Add More Video
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="text-admin-caption grey--text" v-if="program.program_video.length === 0">No video</v-col>
                      <v-col cols="12" v-if="program.program_video.length > 0">
                        <v-row>
                          <v-col cols="12" md="4" lg="3" v-for="(gallery, index) in program.program_video" :key="index">
                            <v-card class="p-relative">
                              <video width="100%" controls>
                                <source class="h-100" :src="imgPath.path+gallery.image.filename" type="video/mp4">
                                Your browser does not support HTML video.
                              </video>
                              <div class="p-absolute d-flex w-100 h-100 justify-end align-start pr-2 pt-2" style="top:0;">
                                <v-chip class="ml-2" small color="bg-teal" text-color="white">
                                  Position <v-avatar right class="grey lighten-3 black--text">{{gallery.position}}</v-avatar>
                                </v-chip>
                              </div>

                              <v-card-actions>
                                <v-btn text color="error" @click="openDialogDeleteConfirmation(gallery.id, program.name+' video with position '+gallery.position)">Delete</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn text class="fc-teal" @click="openFormGallery('edit','video',program,gallery)">Update</v-btn>
                                
                              </v-card-actions>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                  </v-card>
                </v-col>
              </v-row>
            </v-slide-y-transition>
            
          </v-flex>

        </v-layout>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import config from '../../../config';
  import { mapState, mapGetters, mapActions } from 'vuex';
  import FormGallery from '../../components/AdminApp/FormGallery';
  import DialogDeleteConfirmation from '../../components/AdminApp/DialogDeleteConfirmation';

  export default {
    metaInfo: {
      title: 'Bali Conservation Adventures',
    },

    components: {
      FormGallery,
      DialogDeleteConfirmation
    },

    data: () => ({
      isLoadingData: false,
      snackbar: { show: false, color: '', message: ''},

      refreshImage: '',
      imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
      searchValue: '',
      panel: null,
    }),

    computed: {
      ...mapState({
        gallery: state => state.admin_gallery.data,
      }),
      ...mapGetters({
        galleryList: 'admin_gallery/searchGallery',
      }),
      galleryListCount(){
        return this.galleryList(this.searchValue).length;
      },
    },

    watch: {
      'galleryListCount': {
        handler: function(newValue, oldValue) {
          if(oldValue !== newValue){
            this.panel = null;
          }
        },
        deep: true
      },
    },

    mounted(){
      this.isLoadingData = true;
      this.getGallery().then((response) => {
        if(response.errors){
          this.callSnackbar('error',response.errors);
          return;
        }
        this.isLoadingData = false;
      });
    },

    methods: {
      ...mapActions({
        getGallery: 'admin_gallery/getGallery'
      }),

      actionRefreshImage(){
        const now = Date.now();
        this.refreshImage = '?'+now;
      },

      openFormGallery(action,type,program,image){
        this.$refs.formGallery.openForm(action,type,program,image);
      },

      createUpdateData(data){
        if(data.errors){
          this.callSnackbar('error',data.errors)
          return;
        }
        this.callSnackbar('success',data.success)
        this.actionRefreshImage()
      },

      openDialogDeleteConfirmation(id, name){
        this.$refs.dialogDeleteConfirmation.openDialog(id, name);
      },

      deleteData(id){
        this.isLoadingData = true;
        this.$store.dispatch('admin_gallery/deleteGallery',id).then((data) => {
          this.isLoadingData = false;
          if(data.errors){
            this.callSnackbar('error',data.errors);
            return;
          }
          this.callSnackbar('success',data.success);
          this.actionRefreshImage()
        });
      },

      callSnackbar(color,message){
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
      }
    }

  }
</script>