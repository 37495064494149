import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":"","fill-height":""}},[_c(VFlex,{staticClass:"d-block d-lg-none",staticStyle:{"margin-top":"80px"}},[_c(VImg,{staticClass:"h-100",attrs:{"eager":"","src":_vm.banner.imgError ? _vm.banner.imgError : _vm.banner.image && _vm.banner.image.filename ? _vm.imgPath.path+_vm.banner.image.filename : '/'},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{staticClass:"fc-teal",attrs:{"indeterminate":""}})],1)]},proxy:true}])})],1),_c(VFlex,{staticClass:"d-lg-block d-none",staticStyle:{"height":"100vh"}},[_c(VImg,{staticClass:"h-100",attrs:{"eager":"","src":_vm.banner.imgError ? _vm.banner.imgError : _vm.banner.image && _vm.banner.image.filename ? _vm.imgPath.path+_vm.banner.image.filename : '/'},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{staticClass:"fc-teal",attrs:{"indeterminate":""}})],1)]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }