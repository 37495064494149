<template>

  <v-dialog scrollable v-model="isFormOpened" persistent max-width="800px">

    <ImageManager ref="imageManager" @onImageSelected="onImageSelected"></ImageManager>

    <v-snackbar :color="snackbar.color" v-model="snackbar.show" timeout="5000">
      <span>{{snackbar.message}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-form ref="form" v-model="isFormValid" lazy-validation>
      <v-card v-if="isFormOpened">

        <!-- TOOLBAR -->
        <v-toolbar dark color="bg-teal">
          <!-- TITLE -->
          <v-toolbar-title v-if="action == 'add'">Add New Story / Blog</v-toolbar-title>
          <v-toolbar-title v-if="action == 'edit'">Edit Story / Blog</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- CLOSE BUTTON -->
          <v-btn :disabled="isLoadingSubmit" icon dark @click="isFormOpened = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-progress-linear :style="isLoadingSubmit ? '' : 'visibility:hidden'" indeterminate color="secondary"></v-progress-linear>

        <!-- FORM INPUT -->
        <v-card-text>
          <v-container>
            <v-row class="justify-center">

              <!-- PHOTO -->
              <v-col cols="12" sm="8" class="d-flex justify-center">
                <v-hover v-slot:default="{ hover }">
                  <div class="d-flex cursor-pointer p-relative w-100" @click="openImageManager('update',formData.image)">
                    <v-img height="250px"
                      :src="isImageError ? imgPath.imgError+refreshImage : (formData.image && formData.image.filename) ? imgPath.path+formData.image.filename+refreshImage : imgPath.imgEmpty+refreshImage"
                      :lazy-src="imgPath.lazyPath+refreshImage"
                      v-on:error="isImageError = true"
                    >
                    </v-img>
                    <div class="p-absolute d-flex w-100 h-100 justify-center align-center" v-if="hover">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn fab small dark color="blue-grey" class="mr-1" v-bind="attrs" v-on="on">
                              <v-icon>mdi mdi-image-search</v-icon>
                          </v-btn>
                        </template>
                        <span>Change Image</span>
                      </v-tooltip>
                    </div>
                  </div>
                </v-hover>
              </v-col>

              <!-- NAME -->
              <v-col cols="12">
                <v-text-field :disabled="isLoadingSubmit" v-model="formData.name" :rules="nameRules" label="Name*" required></v-text-field>
              </v-col>

              <!-- BRIEF -->
              <v-col cols="12">
                <v-textarea :disabled="isLoadingSubmit" v-model="formData.brief" :rules="briefRules" label="Brief*" auto-grow required></v-textarea>
              </v-col>

              <!-- SELECT -->
              <v-col cols="12">
                <v-select :items="type" item-text="nama" item-value="id" v-model="formData.type" label="Type*"></v-select>
              </v-col>


              <!-- SHOW -->
              <v-col cols="12" md="6" class="d-flex align-center py-0">
                <v-switch color="success" :disabled="isLoadingSubmit" v-model="formData.show" inset label="Show on homepage"></v-switch>
              </v-col>

              <!-- POSITION -->
              <v-col cols="12" md="6" class="d-flex align-center py-0">
                <v-text-field :disabled="isLoadingSubmit" v-model="formData.position" :rules="positionRules" min=1 type="number" label="Position*" required></v-text-field>
                <v-btn :disabled="isLoadingSubmit" icon @click="formData.position > 1 ? formData.position-- : 1"><v-icon>mdi-minus-circle</v-icon></v-btn>
                <v-btn :disabled="isLoadingSubmit" icon @click="formData.position++" ><v-icon>mdi-plus-circle</v-icon></v-btn>
              </v-col>

              <!-- CONTENT -->
              <v-row class="ma-0 mt-10">
                <v-col cols="6" class="text-admin-body d-flex align-center">Content</v-col>
                <v-col cols="6" class="text-end"><v-btn small @click="openImageManager('preview')">Open Image Library</v-btn></v-col>
              </v-row>
              <v-col cols="12" class="pt-2 custom-editor pa-0 px-3" style="height:600px;" v-if="editor">
                  <ckeditor :editor="editor" v-model="formData.content" :config="editorConfig" class="pa-1"></ckeditor>
              </v-col>

              

            </v-row>
          </v-container>
        </v-card-text>
        

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="isLoadingSubmit" color="grey darken-1" text @click="isFormOpened = false;">Cancel</v-btn>
          <v-btn :disabled="isLoadingSubmit" class="fc-teal" text @click="formSave()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>

</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex'
  import config from '../../../config';
  import ImageManager from '../../components/AdminApp/ImageManager';

  import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
  import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
  import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
  import Heading from '@ckeditor/ckeditor5-heading/src/heading';
  import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
  import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
  import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
  import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle';
  import Indent from '@ckeditor/ckeditor5-indent/src/indent';
  import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
  import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
  import Table from '@ckeditor/ckeditor5-table/src/table';
  import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
  import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
  import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
  import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
  import Image from '@ckeditor/ckeditor5-image/src/image';
  import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert';
  import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
  import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
  import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
  import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
  import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
  import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';

  export default {
    components: {
      ImageManager
    },

    data: () => ({
      editor: null,
      editorConfig: {
        plugins: [EssentialsPlugin,ParagraphPlugin,Heading,BoldPlugin,ItalicPlugin,LinkPlugin,ListStyle,Indent,IndentBlock,BlockQuote, Alignment,
          Image, ImageInsert, ImageToolbar, ImageCaption, ImageStyle, ImageResize, Table, TableToolbar, TableProperties, TableCellProperties, MediaEmbed,
          SimpleUploadAdapter
        ],
        toolbar: {
            items: ['heading','|','bold','italic','link','bulletedList','numberedList','|','alignment','outdent', 'indent','|','imageInsert','blockQuote','insertTable','mediaEmbed','undo','redo',
            ]
        },
        table: {
            contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells','tableProperties', 'tableCellProperties'],
            tableProperties: {},
            tableCellProperties: {}
        },
        image: {
          styles: ['alignLeft', 'alignCenter', 'alignRight'],
          resizeOptions: [
            {name: 'imageResize:original',label: 'Original',value: null},
            {name: 'imageResize:50',label: '50%',value: '50'},
            {name: 'imageResize:75',label: '75%',value: '75'}
          ],
          toolbar: ['imageStyle:alignLeft','imageStyle:alignCenter','imageStyle:alignRight','|','imageResize','|','imageTextAlternative'],
          upload: {
            types: ['svg','png','jpg','jpeg']
          }
        },
        simpleUpload: {
          uploadUrl: config.EDITOR_API_URL,
          withCredentials: true,
          headers: {
            'X-CSRF-TOKEN': 'CSRF-Token',
            Authorization: 'Bearer <JSON Web Token>'
          }
        }
      },
    
      refreshImage: '',
      imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },

      action: null,
      isImageError: false,
      isFormOpened: false,
      isFormValid: true,
      isLoadingSubmit: false,
      snackbar: { show: false, color: '', message: ''},

      formData: {
        id: null,
        image: null,
        name: null,
        type: 1,
        brief: null,
        position: 1,
        show: 0,
        content: null
      },

      type:[
        {id:1,nama:'Story'},
        {id:2,nama:'Blog'}
      ],

      nameRules: [ 
        v => (!!v) || 'Name is required', 
        v => (v && !!v.trim()) || 'Name is required', 
        v => (v && v.trim().length > 3) || 'Name must be more than 3 characters'
      ],
      positionRules: [ 
        v => (!isNaN(parseInt(v))) || 'Position must be a number', 
        v => (!isNaN(parseInt(v)) && v > 0) || 'Position must be greater than 0', 
        v => (!isNaN(parseInt(v)) && (v%1 === 0)) || 'Position cannot be a decimal', 
      ],
      briefRules: [ 
        v => !!v || 'Brief is required', 
        v => (v && !!v.trim()) || 'Brief is required', 
        v => (v && v.trim().length > 10) || 'Brief must be more than 10 characters'
      ],
    }),

    computed: {
      ...mapGetters({
        getAccessToken: 'admin_auth/accessToken',
      }),
    },

    mounted(){
      this.editorConfig.simpleUpload.headers.Authorization = `Bearer ${this.getAccessToken}`
    },

    methods: {
      actionRefreshImage(){
        const now = Date.now();
        this.refreshImage = '?'+now;
      },

      initEditor(){
        setTimeout(() => {
          this.editor = ClassicEditor
        }, 500);
      },

      initVariable(){
        this.action = 'add';
        this.isImageError = false;
        this.formData.id = null;
        this.formData.image = null;
        this.formData.name = null;
        this.formData.type = 1;
        this.formData.brief = null;
        this.formData.position = 1;
        this.formData.show = 0;
        this.formData.content = null;
        this.initEditor();
        this.actionRefreshImage();
      },

      openForm(action, item){
        this.editor = null;
        if(!action){
          return;
        }

        this.initVariable();
        if(action == 'edit' && item){
          this.action = 'edit';
          this.formData.id = item.id;
          this.formData.image = item.image;
          this.formData.name = item.name;
          this.formData.type = item.type;
          this.formData.brief = item.brief;
          this.formData.position = item.position;
          this.formData.show = item.show;
          this.formData.content = item.content;
        }
        this.isFormOpened = true;
        this.$nextTick(() => this.$refs.form.resetValidation())
      },

      formSave(){
        this.isLoadingSubmit = true;

        if(this.$refs.form.validate()){
          this.formData.image_id = this.formData && this.formData.image && this.formData.image.id;
          const target = (this.action === 'edit') ? 'admin_story/updateStory' :  'admin_story/addStory';
          this.$store.dispatch(target, this.formData).then((data) => {
            this.isLoadingSubmit = false;
            if(data.errors){
              this.callSnackbar('error',data.errors);
              return;
            }
            this.$emit('formTestimonialSaved',data)
            this.isFormOpened = false;  
          });
          return;
        }

        this.callSnackbar('error','Please fill all the required field');
        this.isLoadingSubmit = false;
      },

      openImageManager(action,currentImage){
        this.$refs.imageManager.openImageManager(action,'image',currentImage);
      },

      onImageSelected(event){
        this.formData.image = event.selectedImage;
      },

      callSnackbar(color,message){
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
      }

    },

  }
</script>