<template>

  <v-container fluid class="pa-0">
    <Jumbotron id="home"></Jumbotron>
    <About id="about" class="bg-yellow pt-0 pt-md-10"></About>
    <Feed id="feed" class="bg-yellow pb-10"></Feed>
    <WhyBook id="whyBook" class="bg-yellow pb-10"></WhyBook>
    <Program id="program" class="bg-yellow pb-10"></Program>
    <Quote id="quote" class="bg-yellow"></Quote>
    <Story id="story" class="bg-yellow2"></Story>
    <Testimonial id="testimonial" class="bg-yellow pb-10"></Testimonial>
    <Gallery id="gallery" class="bg-yellow pb-0"></Gallery>
    <Contact id="contact" class="bg-yellow2 pb-10"></Contact>
  </v-container>  

</template>

<script>
  import Jumbotron from '../../components/WebApp/Jumbotron';
  import About from '../../components/WebApp/About';
  import Feed from '../../components/WebApp/Feed';
  import WhyBook from '../../components/WebApp/WhyBook';
  import Program from '../../components/WebApp/Program';
  import Quote from '../../components/WebApp/Quote';
  import Story from '../../components/WebApp/Story';
  import Testimonial from '../../components/WebApp/Testimonial';
  import Gallery from '../../components/WebApp/Gallery';
  import Contact from '../../components/WebApp/Contact';

  export default {
    metaInfo: {
      title: 'Bali Conservation Adventures',
      meta: [
        { name: 'description', content: 'Bali Conservation Adventures is an organization formed to support wildlife conservation in Bali through tourism.'},
        { property: 'og:locale', content: "en_US"},
        { property: 'og:type', content: 'website'},    
        { property: 'og:title', content: "Bali Conservation Adventures"},
        { property: 'og:description', content: 'Bali Conservation Adventures is an organization formed to support wildlife conservation in Bali through tourism.'},
        { property: 'og:url', content: 'https://www.baliconservationadventures.com/'},
        { property: 'og:image', content: 'https://baliconservationadventures.com/assets/logo/logo.png'},
        { property: 'og:site_name', content: 'Bali Conservation Adventures'},
        { name: 'robots', content: 'index,follow'} 
      ],
      link: [
        {rel: 'canonical', href: 'https://www.baliconservationadventures.com'}
      ]
    },
    
    components: {
      Jumbotron, About, Feed, WhyBook, Program, Quote, Story, Testimonial, Gallery, Contact, 
    }
  }
</script>
