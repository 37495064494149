<template>
	<v-container fluid>

		<v-row>
			<v-col cols="12">

				<v-row class="text-center mb-0 mb-sm-10">
					<v-col cols="0" sm="2" class="d-none d-sm-flex"></v-col>
					<v-col cols="12" sm="8">
						<v-row class="d-flex justify-center">
							<v-col cols="12" class="text-header text-center fw-700 fc-teal">TESTIMONIALS</v-col>
						</v-row>
					</v-col>
					<v-col cols="0" sm="2" class="d-none d-sm-flex"></v-col>
				</v-row>

				<v-row class="mt-n10">
					<v-carousel cycle class="carousel-black" hide-delimiter-background :hide-delimiters="$vuetify.breakpoint.smAndUp" :show-arrows="$vuetify.breakpoint.smAndUp" height="100%" prev-icon="mdi-arrow-left" next-icon="mdi-arrow-right">
            <v-carousel-item v-for="testi in testimonials" :key="testi.id">
							<v-row>
								<v-col cols="2"></v-col>

								<v-col cols="12" sm="8">
									<v-row v-if="$vuetify.breakpoint.mdAndUp">
										<v-col cols="4">
											<v-card class="overflow-hidden" style="height:250px;z-index:1;">
												<v-img class="hover-zoom" eager height="100%"
													:src="testi.imgError ? testi.imgError : testi.image && testi.image.filename ? imgPath.path+testi.image.filename : imgPath.imgEmpty"
													v-on:error="testi.imgError = imgPath.imgError"
												>
													<template v-slot:placeholder>
														<v-row class="fill-height ma-0" align="center" justify="center">
															<v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
														</v-row>
													</template>
												</v-img>
											</v-card>
										</v-col>
										<v-col cols="8"></v-col>
									</v-row>

									<v-row class="ma-0" :style="$vuetify.breakpoint.mdAndUp ? 'margin-top: -220px!important' : ''">

										<v-col v-if="$vuetify.breakpoint.mdAndUp" cols="2"></v-col>
										<v-col cols="12" md="10">
											<v-card light class="pa-10">
												<v-row>
													<v-col v-if="$vuetify.breakpoint.mdAndUp" cols="2"></v-col>
													<v-col cols="12" md="10">
														<v-row>
															<v-col v-if="$vuetify.breakpoint.smAndDown" cols="12" class="d-flex justify-center">
																<v-card style="width: 150px; height: 150px; border-radius: 100%;">
																	<v-img height="100%" eager :src="testi.imgError ? testi.imgError : testi.image && testi.image.filename ? imgPath.path+testi.image.filename : imgPath.imgEmpty"
																		:lazy-src="imgPath.lazyPath"
																		v-on:error="testi.imgError = imgPath.imgError"
																	></v-img>
																</v-card>
															</v-col>
															<v-col cols="12" class="pb-0 text-subtitle fc-black fw-700 text-center text-md-left">{{testi.name}}</v-col>
															<v-col cols="12" class="pt-0 grey--text text-caption fw-400 text-center text-md-left">{{testi.caption}}</v-col>
															<v-col cols="12" class="text-body fw-200 fc-black text-justify ws-break-spaces">{{testi.testimonial}}</v-col>
														</v-row>
													</v-col>
													
												</v-row>
											</v-card>
										</v-col>

									</v-row>
								</v-col>

								<v-col cols="2"></v-col>
							</v-row>
            </v-carousel-item>
          </v-carousel>
				</v-row>

			</v-col>
		</v-row>

	</v-container>
</template>


<script>
	import config from '../../../config';
  import { mapState, mapActions } from 'vuex'
	
  export default {
    data: () => ({
			imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
		}),

		computed: {
			...mapState({
				testimonials: state => state.testimonials.data,
			}),
		}
  }
</script>