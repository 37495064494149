<template>

  <v-dialog v-model="isDialogOpened" persistent max-width="600px">

    <v-card>

      <!-- TOOLBAR -->
      <v-toolbar dark color="error">
        <!-- TITLE -->
        <v-toolbar-title>Delete Confirmation</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- CLOSE BUTTON -->
        <v-btn icon dark @click="isDialogOpened = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- DETAILS -->
      <v-card-text>
        <v-container>
          <v-row>
            
            <!-- TITLE -->
            <v-col cols="12">
              Are you sure want to delete this data ?

              <v-card class="pa-3 mt-4" v-if="dialogData.name">
                {{dialogData.name}}
              </v-card>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="dialogDeleteConfirmed()">Delete</v-btn>
        <v-btn color="grey darken-1" text @click="isDialogOpened = false;">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
  export default {

    data: () => ({
      isDialogOpened: false,
      dialogData: {
        id: null,
        name: null
      }
    }),

    methods: {
      initVariable: function(){
        this.dialogData.id = null;
        this.dialogData.name = null;
      },

      openDialog: function (id, name) {
        this.initVariable();
        this.dialogData.id = id;
        this.dialogData.name = name;
        this.isDialogOpened = true;
      },

      dialogDeleteConfirmed: function(){
        this.isDialogOpened = false;
        this.$emit('dialogDeleteConfirmed',this.dialogData.id)
      },

    },

  }
</script>