import api from '../../api/webapp-api';

// initial state
const state = () => ({
	data: [],
})

// getters
const getters = {
	getHomeGallerySlider: (state)  => {
		const existImages = state.data.filter((gallery) => gallery.image);
		const onlyImages = existImages.filter((gallery) => gallery.image.type === '1' && gallery.program.show === 1);

		const groupedImageByProgram = [];
		for(let i=0;i<onlyImages.length;i++){
			const programIndex = groupedImageByProgram.findIndex((program) => program.id === onlyImages[i].program.id)
			if(programIndex >= 0){
				groupedImageByProgram[programIndex].images.push({...onlyImages[i].image, program: onlyImages[i].program})
			}
			if(programIndex < 0){
				groupedImageByProgram.push({...onlyImages[i].program, images:[{...onlyImages[i].image, program: onlyImages[i].program}]})
			}
		}

		const galleryResult = [];
		for(let i=0;i<onlyImages.length;i++){
			for(let j=0;j<groupedImageByProgram.length;j++){
				if(groupedImageByProgram[j].images[i]){
					galleryResult.push(groupedImageByProgram[j].images[i])
				}
			}
		}

		return {fourItemPerSlide: galleryResult.slice(0,4), sixItemPerSlide: galleryResult.slice(0,6)};
	},

	getGalleryProgram: (state)  => {
		const galleries = state.data.filter((gallery) => gallery.image);

		const result = galleries
		.map((a) => ({sort: Math.random(), value: a}))
		.sort((a, b) => a.sort - b.sort)
		.map((a) => a.value)

		return result;
	},

	getGalleryByCategoryAndType: (state) => (categoryId, type)  => {
		if(!categoryId || !type){
			return [];
		}
		const galleryByCategory = state.data.find((gallery) => gallery.id === categoryId) || null;
		const galleryByType = galleryByCategory && galleryByCategory.program_gallery.filter((gallery) => gallery.image.type === type) || [];
		return galleryByType;
	},
}

// actions
const actions = {
	getGallery({commit}){
		return new Promise((resolve,reject) => {
			api.getGallery().then(response => {
				commit('SET_GALLERY', response.data)
				resolve({'success':response.data});
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	getGalleryHome({commit}){
		return new Promise((resolve,reject) => {
			api.getGalleryHome().then(response => {
				commit('SET_GALLERY_HOME', response.data)
				resolve({'success':response.data});
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},
}

// mutations
const mutations = {
	SET_GALLERY(state, gallery){
		state.data = gallery;
	},
	SET_GALLERY_HOME(state, gallery){
		state.data = gallery;
	},
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}