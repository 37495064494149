import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VParallax } from 'vuetify/lib/components/VParallax';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VRow,{staticClass:"w-100 ma-0"},[_c(VParallax,{staticClass:"w-100",staticStyle:{"filter":"brightness(90%)"},attrs:{"height":"400","src":require('@/assets/about-parallax.jpg')}},[_c(VRow,{staticClass:"d-flex align-center"},[_c(VCol,{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c(VCard,{staticClass:"pa-5 text-subtitle fc-black text-center h-100 fw-600",staticStyle:{"text-transform":"none","background":"rgba(245, 245, 245, .7)","border-radius":"25px"},on:{"click":function($event){return _vm.openAboutModal()}}},[_c(VRow,[_c(VCol,{staticClass:"fc-teal",attrs:{"cols":"12"}},[_vm._v("Why book Bali Conservation Adventures ?")])],1)],1)],1)],1)],1)],1),_c('AboutModal',{ref:"aboutModalRef"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }