<template>

  <v-dialog eager scrollable v-model="isFormOpened" persistent max-width="1200px">

    <FormImage ref="formImage" @formImageSaved="onFormImageSaved"></FormImage>
    <DialogDeleteConfirmation ref="dialogDeleteConfirmation" @dialogDeleteConfirmed="deleteData"></DialogDeleteConfirmation>

    <v-snackbar :color="snackbar.color" v-model="snackbar.show" timeout="5000">
      <span>{{snackbar.message}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-card v-if="isFormOpened">

      <!-- TOOLBAR -->
      <v-toolbar dark color="bg-teal">
        <!-- TITLE -->
        <v-toolbar-title>Select {{type === 'image' ? 'Image' : 'Video'}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- CLOSE BUTTON -->
        <v-btn icon dark @click="isFormOpened = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-progress-linear :style="isLoadingData ? '' : 'visibility:hidden'" indeterminate color="secondary"></v-progress-linear>

      <!-- FORM INPUT -->
      <v-card-text>
        <v-row v-if="images">

          <v-col cols="3" v-for="image in getImageByType(type)" :key="image.id">

            <v-card class="p-relative cursor-pointer pa-1" :class="image.id === (selectedImage && selectedImage.id) ? '' : 'v-card--flat'" @click="actionSelectImage(image)">

              <v-slide-y-transition>
                <v-flex v-if="image.id === (selectedImage && selectedImage.id)" class="p-absolute bg-teal" style="z-index:1;right:0;top:0;">
                  <v-icon color="white">mdi mdi-star</v-icon>
                </v-flex>
              </v-slide-y-transition>

              <v-flex v-if="image.type === '1' && type === 'image'">
                <v-img width="100%" eager
                  :src="image.imgError ? image.imgError : image.filename ? imgPath.path+image.filename : imgPath.imgEmpty"
                  :lazy-src="imgPath.lazyPath"
                  v-on:error="image.imgError = imgPath.imgError"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-flex>

              <v-flex v-if="image.type === '2' && type === 'video' && image.filename">
                <video width="100%" controls>
                  <source class="h-100" :src="imgPath.path+image.filename" type="video/mp4">
                  Your browser does not support HTML video.
                </video>
              </v-flex>

              <v-card-actions v-if="image.id === (selectedImage && selectedImage.id)">
                <v-btn text color="error" @click="openDialogDeleteConfirmation(image.id, '')">Delete</v-btn>
                <v-spacer></v-spacer>
                <v-btn v-if="action === 'preview'" text class="fc-teal" @click="copyImageURL(imgPath.path+image.filename)">Copy URL</v-btn>
                <v-btn v-if="action !== 'preview'" text class="fc-teal" @click="sendSelectedImage()">Select</v-btn>
              </v-card-actions>

            </v-card>



          </v-col>


        </v-row>
      </v-card-text>

      <v-card-actions class="pa-5">
        <v-btn class="fc-teal" @click="openFormImage()"><v-icon class="mr-2">mdi-image-plus</v-icon>Upload</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="isFormOpened = false;">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
  import config from '../../../config';
  import { mapState, mapActions, mapGetters } from 'vuex'
  import FormImage from './FormImage';
  import DialogDeleteConfirmation from '../../components/AdminApp/DialogDeleteConfirmation';

  export default {
    components: {
      FormImage, DialogDeleteConfirmation
    },

    data: () => ({
      isFormOpened: false,
      isLoadingData: false,
      snackbar: { show: false, color: '', message: ''},

      action: null,
      type: null,
      selectedImage: {},

      refreshImage: '',
      imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
    }),

    computed: {
      ...mapState({
        images: state => state.admin_image.data,
      }),
      ...mapGetters({
        getImageByType: 'admin_image/getImageByType',
      }),
    },

    methods: {
      initVariable: function(){
        this.action = 'add';
      },

      actionRefreshImage(){
        const now = Date.now();
        this.refreshImage = '?'+now;
      },

      actionSelectImage(image){
        this.selectedImage = image;
      },

      openImageManager(action, type, currentImage){
        if(!action){
          return;
        }
        this.$store.dispatch('admin_image/getImage');
        this.initVariable();
        this.isFormOpened = true;
        this.action = action;
        this.type = type;
        this.selectedImage = currentImage;
      },

      sendSelectedImage(){
        this.isFormOpened = false;
        this.$emit('onImageSelected',{action: this.action, selectedImage: this.selectedImage})
      },

      openFormImage(){
        this.$refs.formImage.openForm(this.type);
      },

      onFormImageSaved(data){
        if(data.errors){
          this.callSnackbar('error',data.errors)
          return;
        }
        this.callSnackbar('success',data.success)
        this.isFormOpened = false;
        setTimeout(() => {
          this.isFormOpened = true;
        }, 500);
      },

      openDialogDeleteConfirmation(id, name){
        this.$refs.dialogDeleteConfirmation.openDialog(id, name);
      },

      deleteData(id){
        this.isLoadingData = true;
        this.$store.dispatch('admin_image/deleteImage',id).then((data) => {
          this.isLoadingData = false;
          if(data.errors){
            this.callSnackbar('error',data.errors);
            return;
          }
          this.callSnackbar('success',data.success);
        });
      },

      copyImageURL(url){
        const el = document.createElement('textarea');  
        el.value = url;                                 
        el.setAttribute('readonly', '');                
        el.style.position = 'absolute';                     
        el.style.left = '-9999px';                      
        document.body.appendChild(el);                  
        const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;                                    
        el.select();                                    
        document.execCommand('copy');                   
        document.body.removeChild(el);                  
        if (selected) {                                 
          document.getSelection().removeAllRanges();    
          document.getSelection().addRange(selected);   
        }

        this.callSnackbar('success', 'Image '+url+' copied!')
      },

      callSnackbar(color,message){
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
      }

    },

  }
</script>