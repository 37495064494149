<template>

  <v-dialog v-model="isFormOpened" persistent max-width="600px">

    <v-snackbar :color="snackbar.color" v-model="snackbar.show" timeout="5000">
      <span>{{snackbar.message}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-form ref="form" v-model="isFormValid" lazy-validation>
      <v-card>

        <!-- TOOLBAR -->
        <v-toolbar dark class="bg-teal">
          <!-- TITLE -->
          <v-toolbar-title v-if="action == 'add'">Add New Quote</v-toolbar-title>
          <v-toolbar-title v-if="action == 'edit'">Edit Quote</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- CLOSE BUTTON -->
          <v-btn :disabled="isLoadingSubmit" icon dark @click="isFormOpened = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-progress-linear :style="isLoadingSubmit ? '' : 'visibility:hidden'" indeterminate color="secondary"></v-progress-linear>

        <!-- FORM INPUT -->
        <v-card-text>
          <v-container>
            <v-row>

              <!-- QUOTE -->
              <v-col cols="12" class="pt-0">
                <v-textarea :disabled="isLoadingSubmit" v-model="formData.quote" :rules="quoteRules" label="Quote*" auto-grow required class="pt-0"></v-textarea>
              </v-col>

              <!-- AUTHOR -->
              <v-col cols="12">
                <v-textarea :disabled="isLoadingSubmit" v-model="formData.author" :rules="authorRules" label="Author*" rows="3" auto-grow required></v-textarea>
              </v-col>

              <!-- POSITION -->
              <v-col cols="12" class="d-flex align-center">
                <v-text-field :disabled="isLoadingSubmit" v-model="formData.position" :rules="positionRules" min=1 type="number" label="Position*" required></v-text-field>
                <v-btn :disabled="isLoadingSubmit" icon @click="formData.position > 1 ? formData.position-- : 1"><v-icon>mdi-minus-circle</v-icon></v-btn>
                <v-btn :disabled="isLoadingSubmit" icon @click="formData.position++" ><v-icon>mdi-plus-circle</v-icon></v-btn>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="isLoadingSubmit" color="grey darken-1" text @click="isFormOpened = false;">Cancel</v-btn>
          <v-btn :disabled="isLoadingSubmit" class="fc-teal" text @click="formSave()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>

</template>

<script>
  import config from '../../../config';
  export default {

    data: () => ({
      action: null,
      isFormOpened: false,
      isFormValid: true,
      isLoadingSubmit: false,
      snackbar: { show: false, color: '', message: ''},

      formData: {
        id: null,
        position: null,
        author: null,
        quote: null,
      },

      positionRules: [ 
        v => (!isNaN(parseInt(v))) || 'Position must be a number', 
        v => (!isNaN(parseInt(v)) && v > 0) || 'Position must be greater than 0', 
        v => (!isNaN(parseInt(v)) && (v%1 === 0)) || 'Position cannot be a decimal', 
      ],
      authorRules: [ 
        v => (!!v) || 'Author is required', 
        v => (v && !!v.trim()) || 'Author is required', 
        v => (v && v.trim().length > 3) || 'Author must be more than 3 characters'
      ],
      quoteRules: [ 
        v => !!v || 'Quote is required', 
        v => (v && !!v.trim()) || 'Quote is required', 
        v => (v && v.trim().length > 10) || 'Quote must be more than 10 characters'
      ],
    }),

    methods: {
      initVariable(){
        this.action = 'add';
        this.formData.id = null;
        this.formData.position = 1;
        this.formData.author = null;
        this.formData.quote = null;
      },

      openForm(action, item){
        if(!action){
          return;
        }

        this.initVariable();
        if(action == 'edit' && item){
          this.action = 'edit';
          this.formData.id = item.id;
          this.formData.position = item.position;
          this.formData.author = item.author;
          this.formData.quote = item.quote;
        }
        this.isFormOpened = true;
        this.$nextTick(() => this.$refs.form.resetValidation())
      },

      formSave(){
        this.isLoadingSubmit = true;

        if(this.$refs.form.validate()){
          const target = (this.action === 'edit') ? 'admin_quote/updateQuote' :  'admin_quote/addQuote';
          this.$store.dispatch(target, this.formData).then((data) => {
            this.isLoadingSubmit = false;
            if(data.errors){
              this.callSnackbar('error',data.errors);
              return;
            }
            this.$emit('formQuoteSaved',data)
            this.isFormOpened = false;  
          });
          return;
        }

        this.callSnackbar('error','Please fill all the required field');
        this.isLoadingSubmit = false;
      },

      callSnackbar(color,message){
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
      }

    },

  }
</script>