<template>
  <v-main class="h-100 w-100 pa-0 bg-yellow">
    <v-row class="overflow-hidden" style="padding-top:90px;height:100vh">

      <GalleryModal ref="galleryModalRef"></GalleryModal>

      <v-col cols="3" class="d-none d-lg-block pa-5 h-100 overflow-auto">
        <v-row class="ma-0">
          <v-col cols="12" class="text-subtitle fw-700 fc-teal ml-3">GALLERY</v-col>

          <v-col cols="12" class="" v-for="(program) in programs" :key="program.id">
            <v-btn rounded @click="selectCategory(program.id)" class="button-category"
              :text="selectedCategory!==program.id" 
              :outlined="selectedCategory===program.id"
              :class="selectedCategory===program.id ? 'fc-teal' : ''"
            ><span class="fc-black text-left" style="white-space:break-spaces;">{{program.name}}</span></v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="d-block d-lg-none">
        <v-row class="ma-0">
          <v-col cols="12" class="text-subtitle fw-700 fc-teal ml-3 text-center">GALLERY</v-col>
          <v-col cols="12">
            <v-tabs background-color="bg-yellow" grow centered center-active show-arrows hide-slider>
              <v-tab :ripple="false" active-class="transparent" v-for="(program) in programs" :key="program.id" @click="selectCategory(program.id)">
                <v-btn rounded 
                  :text="selectedCategory!==program.id" 
                  :outlined="selectedCategory===program.id" 
                  :class="selectedCategory===program.id ? 'fc-teal' : ''"
                >{{program.name}}</v-btn>
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>

      </v-col>

      <v-col cols="12" lg="9" class="px-10 h-100 overflow-auto">
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn @click="selectType('1')" rounded :text="selectedType!=='1'" :dark="selectedType==='1'" :class="selectedType==='1' ? 'bg-teal' : ''">Photos</v-btn>
            <v-btn @click="selectType('2')" rounded :text="selectedType!=='2'" :dark="selectedType==='2'" :class="selectedType==='2' ? 'bg-teal ml-3' : 'ml-3'">Videos</v-btn>
          </v-col>

          <v-col cols="12">
            <v-row class="ma-0">

              <template v-for="(item,index) in getGalleryByCategoryAndType(selectedCategory, selectedType)">
                <template v-if="selectedType === '1' && $vuetify.breakpoint.smAndDown">
                  <v-col cols="12" :key="index" :style="$vuetify.breakpoint.xsOnly ? 'height:250px' : 'height:500px'" class="pa-0 overflow-hidden" >
                    <v-card tile class="h-100 w-100 hover-zoom transparent" v-if="item.image.type === '1'" @click="openGalleryDialog(index)">
                      <v-img height="100%" 
                        :src="item.imgError ? item.imgError : item.image && item.image.filename ? imgPath.path+item.image.filename : imgPath.imgEmpty"
                        v-on:error="item.imgError = imgPath.imgError"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-col>
                </template>

                <template v-if="selectedType === '1' && $vuetify.breakpoint.mdAndUp">
                  <v-col v-if="index%6 === 0" cols="4" :key="index" style="height:500px" class="pa-0 overflow-hidden">
                    <v-card tile class="h-100 w-100 hover-zoom transparent" v-if="item.image.type === '1'" @click="openGalleryDialog(index)">
                      <v-img height="100%"
                        :src="item.imgError ? item.imgError : item.image && item.image.filename ? imgPath.path+item.image.filename : imgPath.imgEmpty"
                        v-on:error="item.imgError = imgPath.imgError"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-col>

                  <v-col v-if="index%6 === 1" cols="8" :key="index" style="height:500px" class="pa-0 overflow-hidden" >
                    <v-card tile class="h-100 w-100 hover-zoom transparent" v-if="item.image.type === '1'" @click="openGalleryDialog(index)">
                      <v-img height="100%"
                        :src="item.imgError ? item.imgError : item.image && item.image.filename ? imgPath.path+item.image.filename : imgPath.imgEmpty"
                        v-on:error="item.imgError = imgPath.imgError"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-col>

                  <v-col cols="8" v-if="index%6 === 2" :key="index" class="pa-0" style="height:500px;">
                    <v-row class="ma-0 h-100">
                      <v-col cols="12" class="pa-0 overflow-hidden h-50">
                        <v-card tile class="h-100 w-100 hover-zoom transparent" @click="openGalleryDialog(index)">
                          <v-img height="100%"
                            :src="item.imgError ? item.imgError : item.image && item.image.filename ? imgPath.path+item.image.filename : imgPath.imgEmpty"
                            v-on:error="item.imgError = imgPath.imgError"
                          >
                            <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-card>
                      </v-col>
                      <v-col v-if="getGalleryByCategoryAndType(selectedCategory, selectedType)[index+1]" cols="12" class="pa-0 overflow-hidden h-50">
                        <v-card tile class="h-100 w-100 hover-zoom transparent" @click="openGalleryDialog(index+1)">
                          <v-img height="100%"
                            :src="getGalleryByCategoryAndType(selectedCategory, selectedType)[index+1].imgError ? getGalleryByCategoryAndType(selectedCategory, selectedType)[index+1].imgError : getGalleryByCategoryAndType(selectedCategory, selectedType)[index+1].image && getGalleryByCategoryAndType(selectedCategory, selectedType)[index+1].image.filename ? imgPath.path+getGalleryByCategoryAndType(selectedCategory, selectedType)[index+1].image.filename : imgPath.imgEmpty"
                            v-on:error="getGalleryByCategoryAndType(selectedCategory, selectedType)[index+1].imgError = imgPath.imgError"
                          >
                            <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="4" v-if="index%6 === 4" :key="index" style="height:500px;" class="pa-0 overflow-hidden">
                    <v-card tile class="h-100 w-100 hover-zoom transparent" v-if="item.image.type === '1'" @click="openGalleryDialog(index)">
                      <v-img height="100%"
                        :src="item.imgError ? item.imgError : item.image && item.image.filename ? imgPath.path+item.image.filename : imgPath.imgEmpty"
                        v-on:error="item.imgError = imgPath.imgError"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-col>

                  <v-col v-if="index%6 === 5" cols="12" :key="index" style="height:500px;" class="pa-0 overflow-hidden">
                    <v-card tile class="h-100 w-100 hover-zoom transparent" v-if="item.image.type === '1'" @click="openGalleryDialog(index)">
                      <v-img height="100%"
                        :src="item.imgError ? item.imgError : item.image && item.image.filename ? imgPath.path+item.image.filename : imgPath.imgEmpty"
                        v-on:error="item.imgError = imgPath.imgError"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-col>

                </template>

                <template v-if="selectedType === '2'">
                  <v-col cols="12" md="6" :key="index">
                    <v-flex class="w-100" v-if="item.image.type === '2'">
                      <video width="100%" controls>
                        <source class="h-100" :src="imgPath.path+item.image.filename" type="video/mp4">
                        Your browser does not support HTML video.
                      </video>
                    </v-flex>
                  </v-col>
                </template>
              </template>

            </v-row>
          </v-col>

        </v-row>
      </v-col>

    </v-row>
  </v-main>
</template>


<script>
  import GalleryModal from '../../components/WebApp/GalleryModal';
	import config from '../../../config';
  import { mapState, mapActions, mapGetters } from 'vuex'
	
  export default {
    metaInfo: {
      title: 'Bali Conservation Adventures | Gallery',
      meta: [
        { name: 'description', content: 'Bali Conservation Adventures is an organization formed to support wildlife conservation in Bali through tourism.'},
        { property: 'og:locale', content: "en_US"},
        { property: 'og:type', content: 'website'},    
        { property: 'og:title', content: "Bali Conservation Adventures | Gallery"},
        { property: 'og:description', content: 'Bali Conservation Adventures is an organization formed to support wildlife conservation in Bali through tourism.'},
        { property: 'og:url', content: 'https://www.baliconservationadventures.com/gallery'},
        { property: 'og:image', content: 'https://baliconservationadventures.com/assets/logo/logo.png'},
        { property: 'og:site_name', content: 'Bali Conservation Adventures'},
        { name: 'robots', content: 'index,follow'} 
      ],
      link: [
        {rel: 'canonical', href: 'https://www.baliconservationadventures.com/gallery'}
      ]
    },

    components: {GalleryModal},
      
    data: () => ({
			imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
      selectedCategory: null,
      selectedType: '1',
		}),

		computed: {
			...mapState({
        programs: state => state.programs.data,
				galleries: state => state.galleries.data,
      }),
      ...mapGetters({
        getGalleryByCategoryAndType: 'galleries/getGalleryByCategoryAndType',
      }),
		},

    mounted(){
      this.getGallery();
      this.getStory();
      this.getProgram().then((data) => {
        this.selectedCategory = data.success[0].id;
        this.selectedType = '1';
      });
		},

		methods:{
			...mapActions({
        getGallery: 'galleries/getGallery',
        getProgram: 'programs/getProgram',
        getStory: 'stories/getStory'
      }),

      selectCategory(categoryId){
        this.selectedCategory = categoryId;
        this.selectedType = '1';
      },

      selectType(type){
        this.selectedType = type;
      },

      openGalleryDialog(index){
        this.$refs.galleryModalRef.open(this.getGalleryByCategoryAndType(this.selectedCategory, this.selectedType), index);
      }
    }
  }
</script>