import api from "../../api/api";

// initial state
const state = () => ({
	data: {}
})

// getters
const getters = {}

// actions
const actions = {
	getBanner({commit,state}){
		return new Promise((resolve,reject) => {
			api.getBanner().then(response => {
				commit('SET_BANNER', response.data[0])
				resolve({'success':response.data[0]});
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	updateBanner({commit,state},banner){
		return new Promise((resolve,reject) => {
			api.updateBanner(1,banner).then(response => {
				commit('UPDATE_BANNER', response.data.data)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	}
}

// mutations
const mutations = {
	SET_BANNER(state, banner){
		state.data = banner;
	},
	UPDATE_BANNER(state, banner){
		state.data = banner;
	},
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}