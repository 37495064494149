import api from '../../api/webapp-api';

// initial state
const state = () => ({
	data: [],
})

// getters
const getters = {}

// actions
const actions = {
	getTestimonial({commit}){
		return new Promise((resolve,reject) => {
			api.getTestimonial().then(response => {
				commit('SET_TESTIMONIAL', response.data)
				resolve({'success':response.data});
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},
}

// mutations
const mutations = {
	SET_TESTIMONIAL(state, testimonial){
		state.data = testimonial;
	},
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}