<template>
  <v-main class="w-100 h-100 pa-0 bg-yellow">
    <v-row class="overflow-hidden p-relative h-100 ma-0" style="padding-top:90px;">

      <v-col cols="1" lg="2" class=""></v-col>

      <v-col cols="10" lg="7" class="">
        <v-row v-if="getStoryById($route.params.id)">
          <v-col cols="12" class="text-header fw-700 fc-black text-center">{{getStoryById($route.params.id).name}}</v-col>
          <v-col cols="12">
            <span v-html="getStoryById($route.params.id).content"></span>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="1" class="d-block d-lg-none"></v-col>

      <v-col cols="12" lg="3" class="pa-0 pa-lg-10" v-if="getStoryAndBlogExplore($route.params.id)">

        <v-row class="story-detail-section w-100 overflow-auto ma-0 d-flex align-baseline">
          <v-col cols="12" sm="6" lg="12" class="pa-0 w-100" v-if="getStoryAndBlogExplore($route.params.id).stories">
            <v-row v-if="getStoryAndBlogExplore($route.params.id).stories.length > 0" class="ma-0 mb-5 w-100">
              <v-col cols="12" class="text-title fw-600 fc-black pb-0 text-center text-lg-left">Story</v-col>
              <v-col cols="12" class="overflow-hidden d-flex justify-center justify-lg-start" v-for="story in getStoryAndBlogExplore($route.params.id).stories" :key="story.id">
                <v-btn :href="'/story/'+story.storyId" rounded outlined class="fc-black d-flex justify-start" style="max-width: 100%;">
                    <v-col cols="auto" class="px-0"><v-icon left>mdi-chevron-right</v-icon></v-col>
                    <v-col class="px-0" style="white-space: pre-wrap;"><span>{{story.name}}</span></v-col>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" lg="12" class="pa-0 w-100" v-if="getStoryAndBlogExplore($route.params.id).blogs">
            <v-row v-if="getStoryAndBlogExplore($route.params.id).blogs.length > 0" class="ma-0 mt-5">
              <v-col cols="12" class="text-title fw-600 fc-black pb-0 text-center text-lg-left">Blog</v-col>
              <v-col cols="12" class="overflow-hidden d-flex justify-center justify-lg-start" v-for="blog in getStoryAndBlogExplore($route.params.id).blogs" :key="blog.id">
                <v-btn :href="'/story/'+blog.storyId" rounded outlined class="fc-black d-flex justify-start" style="max-width: 100%;">
                  <v-col cols="auto" class="px-0"><v-icon left>mdi-chevron-right</v-icon></v-col>
                  <v-col class="px-0" style="white-space: pre-wrap;"><span>{{blog.name}}</span></v-col>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-col>

    </v-row>

  </v-main>
</template>

<script>
	import config from '../../../config';
  import { mapState, mapActions, mapGetters } from 'vuex'
	
  export default {
    metaInfo: {
      title: 'Bali Conservation Adventures | Story',
      meta: [
        { name: 'description', content: 'Bali Conservation Adventures is an organization formed to support wildlife conservation in Bali through tourism.'},
        { property: 'og:locale', content: "en_US"},
        { property: 'og:type', content: 'website'},    
        { property: 'og:title', content: "Bali Conservation Adventures | Story"},
        { property: 'og:description', content: 'Bali Conservation Adventures is an organization formed to support wildlife conservation in Bali through tourism.'},
        { property: 'og:url', content: 'https://www.baliconservationadventures.com/story'},
        { property: 'og:image', content: 'https://baliconservationadventures.com/assets/logo/logo.png'},
        { property: 'og:site_name', content: 'Bali Conservation Adventures'},
        { name: 'robots', content: 'index,follow'} 
      ],
      link: [
        {rel: 'canonical', href: 'https://www.baliconservationadventures.com/story'}
      ]
    },

    data: () => ({
			imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
      storyDetail: null,
      storyExplore: []
		}),

		computed: {
			...mapState({
				stories: state => state.stories.data,
			}),
      ...mapGetters({
        getStoryById: 'stories/getStoryById',
        getStoryAndBlogExplore: 'stories/getStoryAndBlog'
      }),
		},

    mounted(){
      this.getProgram();
      this.getStory();
		},

		methods:{
			...mapActions({
        getProgram: 'programs/getProgram',
        getStory: 'stories/getStory'
      }),
    }
  }
</script>