import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/WebApp/Main.vue'
import Home from '../views/WebApp/Home.vue'
import About from '../views/WebApp/About.vue'
import Program from '../views/WebApp/Program.vue'
import ProgramDetail from '../views/WebApp/ProgramDetail.vue'
import Story from '../views/WebApp/Story.vue'
import StoryDetail from '../views/WebApp/StoryDetail.vue'
import Gallery from '../views/WebApp/Gallery.vue'

import AdminLogin from '../views/AdminApp/AdminLogin.vue'
import AdminMain from '../views/AdminApp/AdminMain.vue'
import AdminProfile from '../views/AdminApp/AdminProfile.vue'
import AdminUser from '../views/AdminApp/AdminUser.vue'
import AdminBanner from '../views/AdminApp/AdminBanner.vue'
import AdminProgram from '../views/AdminApp/AdminProgram.vue'
import AdminProgramDetail from '../views/AdminApp/AdminMain.vue'
import AdminStory from '../views/AdminApp/AdminStory.vue'
import AdminStoryDetail from '../views/AdminApp/AdminStoryDetail.vue'
import AdminGallery from '../views/AdminApp/AdminGallery.vue'
import AdminQuote from '../views/AdminApp/AdminQuote.vue'
import AdminTestimonial from '../views/AdminApp/AdminTestimonial.vue'
import AdminContact from '../views/AdminApp/AdminContact.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: '/about',
        name: 'About',
        component: About
      },
      {
        path: '/program',
        name: 'Program',
        component: Program
      },
      {
        path: '/program/:id',
        name: 'ProgramDetail',
        component: ProgramDetail
      },
      {
        path: '/story',
        name: 'Story',
        component: Story
      },
      {
        path: '/story/:id',
        name: 'StoryDetail',
        component: StoryDetail
      },
      {
        path: '/gallery',
        name: 'Gallery',
        component: Gallery
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: AdminLogin,
    meta: { requiresVisitor: true }
  },
  {
    path: '/admin',
    name: 'AdminMain',
    component: AdminMain,
    children: [
      { path: '/admin', component: AdminProfile, name: 'AdminProfile', meta: { requiresAuth: true } },
      { path: '/admin/user', component: AdminUser, meta: { requiresAuth: true } },
      { path: '/admin/banner', component: AdminBanner, meta: { requiresAuth: true } },
      { path: '/admin/program', component: AdminProgram, meta: { requiresAuth: true } },
      { path: '/admin/program/:id', component: AdminProgramDetail, meta: { requiresAuth: true } },
      { path: '/admin/story', component: AdminStory, meta: { requiresAuth: true } },
      { path: '/admin/story/:id', component: AdminStoryDetail, meta: { requiresAuth: true } },
      { path: '/admin/gallery', component: AdminGallery, meta: { requiresAuth: true } },
      { path: '/admin/quote', component: AdminQuote, meta: { requiresAuth: true } },
      { path: '/admin/testimonial', component: AdminTestimonial, meta: { requiresAuth: true } },
      { path: '/admin/contact', component: AdminContact, meta: { requiresAuth: true } },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
