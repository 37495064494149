<template>
  <v-container fill-height fluid class="overflow-auto px-sm-7 pb-7">

    <FormProgram ref="formProgram" @formProgramSaved="createUpdateData"></FormProgram>
    <DialogDeleteConfirmation ref="dialogDeleteConfirmation" @dialogDeleteConfirmed="deleteData"></DialogDeleteConfirmation>

    <v-snackbar :color="snackbar.color" v-model="snackbar.show" timeout="5000">
      <span>{{snackbar.message}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>
    
    <v-row class="justify-center align-self-start">
      <v-col cols="12" sm="10" md="10">
        <v-layout column>
          
          <!-- DETAIL -->
          <v-flex>

            <!-- TITLE -->
            <v-row>
              <v-col cols="6">
                <span class="title d-flex"><v-icon class="mr-2">mdi-clipboard-text-multiple-outline</v-icon>Programs</span>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn :disabled="isLoadingData" small color="bg-teal" :dark="!isLoadingData" @click="openFormProgram('add')">
                  <v-icon left small>mdi-plus</v-icon>Add New
                </v-btn>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-progress-linear :style="isLoadingData ? '' : 'visibility:hidden'" indeterminate color="bg-teal"></v-progress-linear>

            <!-- SEARCH BOX -->
            <v-row>
              <v-col cols="12">
                <v-text-field dense solo :disabled="isLoadingData" v-model="searchValue" append-icon="mdi-magnify" label="Search" single-line hide-details>
                </v-text-field>
              </v-col>
            </v-row>

          </v-flex>
          
          <!-- PROGRAM LIST -->
          <v-flex xs12 class="mt-5" style="height:0px;">
            <v-col cols="12" class="mt-5 grey--text text-caption-1 text-center" v-if="programList(this.searchValue).length === 0">
              No Data Available
            </v-col>

            <v-slide-y-transition hide-on-leave group class="row ma-0">
              <v-row v-for="(program, index) in programList(this.searchValue)" :key="index">
                <v-col cols="12">
                  <v-card class="pa-5">

                    <!-- PROGRAM MAIN -->
                    <v-row>
                      <v-col cols="12" md="11">
                        <v-row>
                          
                          <!-- IMAGE -->
                          <v-col cols="12" md="3" class="d-flex justify-center">
                            <div class="d-flex w-100">
                              <v-img width="225" height="300"
                                :src="program.imgError ? program.imgError : (program.image && program.image.filename) ? imgPath.path+program.image.filename+refreshImage : imgPath.imgEmpty"
                                :lazy-src="imgPath.lazyPath"
                                v-on:error="program.imgError = imgPath.imgError"
                              >
                                <template v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </div>
                          </v-col>

                          <!-- NAME BRIEF SHOW POSITION -->
                          <v-col cols="12" md="9">
                            <v-row class="ma-0">
                              <v-col cols="12" class="pa-0">
                                <v-row class="ma-0">
                                  <v-col cols="12" md="8" class="pa-0">
                                    <v-row class="ma-0">
                                      <v-col cols="12" class="fc-black text-admin-title fw-500 pa-0">{{program.name}}</v-col>
                                      <v-col cols="12" class="fc-black text-admin-body fw-300 pa-0 mt-5" style="white-space: break-spaces;">{{program.brief}}</v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col cols="12" md="4" class="text-right pa-0">
                                    <v-chip small color="bg-teal" text-color="white" class="mb-1">
                                      Show <v-avatar right class="grey lighten-3">
                                        <v-icon :class="program.show === 1 ? 'success--text' : 'error--text'">{{program.show === 1 ? 'mdi-check-bold' : 'mdi-close-thick'}}</v-icon>
                                      </v-avatar>
                                    </v-chip>
                                    <v-chip class="ml-2 mb-1" small color="bg-teal" text-color="white">
                                      Position <v-avatar right class="grey lighten-3 black--text">{{program.position}}</v-avatar>
                                    </v-chip>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>

                        </v-row>
                      </v-col>
                      
                      <!-- ACTION DOTS -->
                      <v-col cols="2" md="1" class="text-right" :style="$vuetify.breakpoint.mdAndDown ? 'position:absolute;right:0;top:0;' : ''">
                        <v-menu transition="slide-x-transition" bottom left offset-y :nudge-width="100">
                          <template v-slot:activator="{ on }">
                            <v-btn :disabled="isLoadingData" small icon v-on="on">
                              <v-icon small>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list dense>
                            <v-list-item :disabled="isLoadingData"  @click="openFormProgram('edit',program)">
                              <v-list-item-icon class="mr-1"><v-icon small>mdi-pencil-outline</v-icon></v-list-item-icon>
                              <v-list-item-title>Edit</v-list-item-title>
                            </v-list-item>
                            <v-list-item :disabled="isLoadingData" @click="openDialogDeleteConfirmation(program.id,program.name)">
                              <v-list-item-icon class="mr-1"><v-icon small>mdi-trash-can-outline</v-icon></v-list-item-icon>
                              <v-list-item-title>Delete</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>
                    </v-row>

                    <!-- PROGRAM DETAIL -->
                    <v-row class="ma-0">
                      <v-col cols="12" class="pa-0">
                        <v-expansion-panels class="expansion-transparent">
                          <v-expansion-panel>

                            <v-expansion-panel-header class="pa-0">
                              <v-btn small text :ripple="false" class="d-flex justify-end expansion-button">VIEW PROGRAM DETAIL</v-btn>
                            </v-expansion-panel-header>
                            
                            <v-expansion-panel-content eager class="mt-5">

                              <!-- PROGRAM SLIDER -->
                              <v-card outlined class="mb-2 px-5">
                                <v-row><v-col cols="12" class="fc-black fw-500 pb-0 text-center text-md-left">Program Sliders</v-col></v-row>
                                <v-row v-if="program.program_slider.length === 0"><v-col cols="12" class="grey--text">No data</v-col></v-row>
                                <v-row v-if="program.program_slider.length > 0">
                                  <v-col cols="12" sm="6" md="3" class="d-flex" v-for="slider in program.program_slider" :key="slider.id">
                                    <div class="d-flex w-100">
                                      <v-img width="90px"
                                        :src="slider.imgError ? slider.imgError : (slider.image && slider.image.filename) ? imgPath.path+slider.image.filename+refreshImage : imgPath.imgEmpty"
                                        :lazy-src="imgPath.lazyPath"
                                        v-on:error="slider.imgError = imgPath.imgError"
                                      >
                                        <template v-slot:placeholder>
                                          <v-row class="fill-height ma-0" align="center" justify="center">
                                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                          </v-row>
                                        </template>
                                      </v-img>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-card>

                              <!-- PROGRAM DETAIL -->
                              <v-card outlined class="mb-2 px-5" v-if="program.program_detail">
                                <v-row class="ma-0">
                                  
                                  <!-- CONTENT -->
                                  <v-col cols="12" class="py-2">
                                    <v-row>
                                      <v-col cols="12" class="fc-black fw-500 pb-0 text-center text-md-left">Program Content</v-col>
                                      <v-col cols="12" md="4">
                                        <div class="d-flex w-100">
                                          <v-img width="90px"
                                            :src="program.program_detail.imgError ? program.program_detail.imgError : 
                                                  (program.program_detail.image && program.program_detail.image.filename) ? 
                                                  imgPath.path+program.program_detail.image.filename+refreshImage : imgPath.imgEmpty"
                                            :lazy-src="imgPath.lazyPath"
                                            v-on:error="program.program_detail.imgError = imgPath.imgError"
                                          >
                                            <template v-slot:placeholder>
                                              <v-row class="fill-height ma-0" align="center" justify="center">
                                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                              </v-row>
                                            </template>
                                          </v-img>
                                        </div>
                                      </v-col>
                                      <v-col cols="12" md="8" class="fc-black text-admin-body fw-300 text-justify" :class="program.program_detail.content ? '' : 'grey--text'" style="white-space: break-spaces;">{{program.program_detail.content || 'No data'}}</v-col>
                                    </v-row>
                                  </v-col>

                                  <!-- ITINERARY -->
                                  <v-col cols="12" class="py-2">
                                    <v-row>
                                      <v-col cols="6" class="fc-black fw-500 pb-0">Itinerary</v-col>
                                      <v-col cols="6" class="text-right pb-0">
                                        <v-chip small color="bg-teal" text-color="white">
                                          Show <v-avatar right class="grey lighten-3">
                                            <v-icon :class="program.program_detail.itinerary_show === 1 ? 'success--text' : 'error--text'">
                                              {{program.program_detail.itinerary_show === 1 ? 'mdi-check-bold' : 'mdi-close-thick'}}
                                            </v-icon>
                                          </v-avatar>
                                        </v-chip>
                                      </v-col>
                                      <v-col cols="12" class="grey--text text-admin-body fw-300" v-if="!program.program_detail.itinerary || (program.program_detail.itinerary && program.program_detail.itinerary.length === 0)">No data</v-col>
                                      <v-col cols="12" class="fc-black text-admin-body fw-300" v-if="program.program_detail.itinerary && program.program_detail.itinerary.length > 0">
                                        <v-row v-for="(itinerary,index) in program.program_detail.itinerary" :key="index">
                                          <v-col cols="12" class="pb-0" style="white-space: break-spaces;">{{itinerary.description}}</v-col>
                                          <v-col cols="12" class="pt-0" v-if="itinerary.timeslot && itinerary.timeslot.length > 0">
                                            <v-row class="ma-0" v-for="(timeslot,index) in itinerary.timeslot" :key="index">
                                              <v-col cols="2" md="1" class="pa-0">{{timeslot.time}}</v-col>
                                              <v-col cols="10" md="11" class="pa-0">{{timeslot.description}}</v-col>
                                            </v-row>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                    </v-row>
                                  </v-col>

                                  <!-- WHAT TO BRING -->
                                  <v-col cols="12" class="py-2">
                                    <v-row>
                                      <v-col cols="6" class="fc-black fw-500 pb-0">What to bring or wear</v-col>
                                      <v-col cols="6" class="text-right pb-0">
                                        <v-chip small color="bg-teal" text-color="white">
                                          Show <v-avatar right class="grey lighten-3">
                                            <v-icon :class="program.program_detail.whattobring_show === 1 ? 'success--text' : 'error--text'">
                                              {{program.program_detail.whattobring_show === 1 ? 'mdi-check-bold' : 'mdi-close-thick'}}
                                            </v-icon>
                                          </v-avatar>
                                        </v-chip>
                                      </v-col>
                                      <v-col cols="12" class="grey--text text-admin-body fw-300 py-1" v-if="program.program_detail.whattobring.length === 0">
                                        No data
                                      </v-col>
                                      <v-row class="ma-0" v-if="program.program_detail.whattobring.length > 0">
                                        <v-col cols="12" class="fc-black text-admin-body fw-300 py-1" v-for="(whattobring,index) in program.program_detail.whattobring" :key="index">
                                          <v-icon class="mdi mdi-check mr-3"></v-icon>{{whattobring}}
                                        </v-col>
                                      </v-row>
                                    </v-row>
                                  </v-col>

                                  <!-- INCLUSION -->
                                  <v-col cols="12" class="py-2">
                                    <v-row>
                                      <v-col cols="6" class="fc-black fw-500 pb-0">What's included ?</v-col>
                                      <v-col cols="6" class="text-right pb-0">
                                        <v-chip small color="bg-teal" text-color="white">
                                          Show <v-avatar right class="grey lighten-3">
                                            <v-icon :class="program.program_detail.inclusion_show === 1 ? 'success--text' : 'error--text'">
                                              {{program.program_detail.inclusion_show === 1 ? 'mdi-check-bold' : 'mdi-close-thick'}}
                                            </v-icon>
                                          </v-avatar>
                                        </v-chip>
                                      </v-col>
                                      <v-col cols="12" class="grey--text text-admin-body fw-300 py-1" v-if="program.program_detail.inclusion.length === 0">
                                        No data
                                      </v-col>
                                      <v-row class="ma-0" v-if="program.program_detail.inclusion.length > 0">
                                        <v-col cols="12" class="fc-black text-admin-body fw-300 py-1" v-for="(inclusion,index) in program.program_detail.inclusion" :key="index">
                                          <v-icon class="mdi mdi-check mr-3"></v-icon>{{inclusion}}
                                        </v-col>
                                      </v-row>
                                    </v-row>
                                  </v-col>

                                  <!-- EXCLUSION -->
                                  <v-col cols="12" class="py-2">
                                    <v-row>
                                      <v-col cols="6" class="fc-black fw-500 pb-0">What's excluded ?</v-col>
                                      <v-col cols="6" class="text-right pb-0">
                                        <v-chip small color="bg-teal" text-color="white">
                                          Show <v-avatar right class="grey lighten-3">
                                            <v-icon :class="program.program_detail.exclusion_show === 1 ? 'success--text' : 'error--text'">
                                              {{program.program_detail.exclusion_show === 1 ? 'mdi-check-bold' : 'mdi-close-thick'}}
                                            </v-icon>
                                          </v-avatar>
                                        </v-chip>
                                      </v-col>
                                      <v-col cols="12" class="grey--text text-admin-body fw-300 py-1" v-if="program.program_detail.exclusion.length === 0">
                                        No data
                                      </v-col>
                                      <v-row class="ma-0" v-if="program.program_detail.exclusion.length > 0">
                                        <v-col cols="12" class="fc-black text-admin-body fw-300 py-1" v-for="(exclusion,index) in program.program_detail.exclusion" :key="index">
                                          <v-icon class="mdi mdi-check mr-3"></v-icon>{{exclusion}}
                                        </v-col>
                                      </v-row>
                                    </v-row>
                                  </v-col>

                                  <!-- GROUP -->
                                  <v-col cols="12" class="py-2">
                                    <v-row>
                                      <v-col cols="6" class="fc-black fw-500 pb-0">Groups</v-col>
                                      <v-col cols="6" class="text-right pb-0">
                                        <v-chip small color="bg-teal" text-color="white">
                                          Show <v-avatar right class="grey lighten-3">
                                            <v-icon :class="program.program_detail.group_show === 1 ? 'success--text' : 'error--text'">
                                              {{program.program_detail.group_show === 1 ? 'mdi-check-bold' : 'mdi-close-thick'}}
                                            </v-icon>
                                          </v-avatar>
                                        </v-chip>
                                      </v-col>
                                      <v-col cols="12" class="fc-black text-admin-body fw-300" :class="program.program_detail.group ? '' : 'grey--text'" style="white-space: break-spaces;">{{program.program_detail.group ? program.program_detail.group : 'No data'}}</v-col>
                                    </v-row>
                                  </v-col>

                                </v-row>
                              </v-card>
                            </v-expansion-panel-content>

                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                    </v-row>

                  </v-card>
                </v-col>
              </v-row>
            </v-slide-y-transition>
            
          </v-flex>

        </v-layout>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import config from '../../../config';
  import { mapState, mapGetters, mapActions } from 'vuex';
  import FormProgram from '../../components/AdminApp/FormProgram';
  import DialogDeleteConfirmation from '../../components/AdminApp/DialogDeleteConfirmation';

  export default {
    metaInfo: {
      title: 'Bali Conservation Adventures',
    },

    components: {
      FormProgram,
      DialogDeleteConfirmation
    },

    data: () => ({
      isLoadingData: false,
      snackbar: { show: false, color: '', message: ''},

      refreshImage: '',
      imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
      searchValue: '',
      panel: null,
    }),

    computed: {
      ...mapState({
        program: state => state.admin_program.data,
      }),
      ...mapGetters({
        programList: 'admin_program/searchProgram',
      }),
      programListCount(){
        return this.programList(this.searchValue).length;
      },
    },

    watch: {
      'programListCount': {
        handler: function(newValue, oldValue) {
          if(oldValue !== newValue){
            this.panel = null;
          }
        },
        deep: true
      },
    },

    mounted(){
      this.isLoadingData = true;
      this.getProgram().then((response) => {
        if(response.errors){
          this.callSnackbar('error',response.errors);
          return;
        }
        this.isLoadingData = false;
      });
    },

    methods: {
      ...mapActions({
        getProgram: 'admin_program/getProgram'
      }),

      actionRefreshImage(){
        const now = Date.now();
        this.refreshImage = '?'+now;
      },

      openFormProgram(action, item){
        this.$refs.formProgram.openForm(action, item);
      },

      openDialogDeleteConfirmation(id, name){
        this.$refs.dialogDeleteConfirmation.openDialog(id, name);
      },

      createUpdateData(data){
        if(data.errors){
          this.callSnackbar('error',data.errors)
          return;
        }
        this.callSnackbar('success',data.success)
        this.actionRefreshImage()
      },

      deleteData(id){
        this.isLoadingData = true;
        this.$store.dispatch('admin_program/deleteProgram',id).then((data) => {
          this.isLoadingData = false;
          if(data.errors){
            this.callSnackbar('error',data.errors);
            return;
          }
          this.callSnackbar('success',data.success);
          this.actionRefreshImage()
        });
      },

      callSnackbar(color,message){
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
      }
    }

  }
</script>