<template>
  <v-row justify="center">
    <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" scrollable width="900" v-model="isOpened">
      <v-card>

        <v-card-title class="bg-teal white--text">
          COVID-19 Protocol
          <v-spacer></v-spacer>
          <v-btn icon dark @click="isOpened = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pa-10">
          <v-row>
            <v-col cols="12" class="text-justify text-sm-center text-body">
              Our programs’ venues are not the typical busy tourist attractions. Therefore, you will not be
              around a large crowd. Activities are conducted mostly in open space where fresh air circulates.
              However, the covid-19 protocols still have to be adhered to.
            </v-col>
          </v-row>

          <v-row class="my-0 my-sm-10">
            <v-col cols="0" sm="1" md="2"></v-col>
            <v-col cols="12" sm="10" md="8">
              <v-img height="100%" eager :src="require('@/assets/program-covid.png')">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
            <v-col cols="0" sm="1" md="2"></v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="text-justify text-sm-center text-body">
              Should you require more information regarding this, <span v-if="$vuetify.breakpoint.smAndUp"><br/></span>
              please do not hesitate to contact us.
            </v-col>
          </v-row>

        </v-card-text>

      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
  export default {
    data: () => ({
      isOpened: null
    }),

    methods: {
      open() {
        this.isOpened = true;
      }
    },
  }
</script>

