import api from "../../api/api"

// initial state
const state = () => ({
	data: []
})

// getters
const getters = {
	searchProgram: (state) => (value) => {
		const searchValue = value.toString().toLowerCase();
		function deepSearch(item){
			return Object.keys(item).some(obj => {
				if(item[obj] && obj !== 'id' && obj !== 'position'){
					return typeof item[obj] === 'object' ? deepSearch(item[obj]) : item[obj].toString().toLowerCase().includes(searchValue);
				}
			})
		}
		const searchResult = state.data.filter(item => {
			return deepSearch(item);
		})
		return searchResult;
	}
}

// actions
const actions = {
	getProgram({commit}){
		return new Promise((resolve,reject) => {
			api.getProgram().then(response => {
				commit('SET_PROGRAM', response.data)
				resolve({'success':response.data});
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	addProgram({commit},program){
		return new Promise((resolve,reject) => {
			api.addProgram(program).then(response => {
				commit('ADD_PROGRAM', response.data.data)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	updateProgram({commit},program){
		return new Promise((resolve,reject) => {
			api.updateProgram(program.id,program).then(response => {
				commit('UPDATE_PROGRAM', response.data.data)
				resolve(response.data);
				}).catch(error => {
					const errorResponse = error && error.response && error.response.data;
					resolve({'errors':errorResponse && errorResponse.message});
				})
		})
	},

	deleteProgram({commit},id){
		return new Promise((resolve,reject) => {
			api.deleteProgram(id).then(response => {
				commit('DELETE_PROGRAM', id)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error.response.data;
				resolve({'errors':errorResponse.message});
			})
		})
	}
}

// mutations
const mutations = {
	SET_PROGRAM(state, program){
		program.forEach((dt) => {
			dt.program_detail.itinerary = dt.program_detail.itinerary ? JSON.parse(dt.program_detail.itinerary) : [];
		})
		state.data = program;
	},

	ADD_PROGRAM(state, program){
		program.program_detail.itinerary = program.program_detail.itinerary ? JSON.parse(program.program_detail.itinerary) : [];
		state.data.unshift(program);
	},

	UPDATE_PROGRAM(state, program){
		program.program_detail.itinerary = program.program_detail.itinerary ? JSON.parse(program.program_detail.itinerary) : [];
		const updateIndex = state.data.findIndex((data) => data	.id === program.id);
		state.data.splice(updateIndex,1,program);
	},

	DELETE_PROGRAM(state, id){
		const deleteIndex = state.data.findIndex((data) => data.id === id);
		state.data.splice(deleteIndex, 1);
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}