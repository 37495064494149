import axios from 'axios'
import instagramApi from '../../api/instagram-api'

// initial state
const state = () => ({
	data: [],
})

// getters
const getters = {}

// actions
const actions = {
  getInstagramFeeds({commit}){
    return new Promise((resolve, reject) => {
      instagramApi.getIgToken().then(response => {
        if(response && response.data){
          const token = response.data.igtoken;
          const refreshTokenUrl = 'https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&access_token='+token;

          const today = new Date();
          const tokenDate = new Date(response.data.date);
          const diffTime = Math.abs(today - tokenDate);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

          if(diffDays > 10){
            axios.get(refreshTokenUrl).then((response) => {
              const newToken = response.data.access_token;
              const newDate = today.getFullYear()+'-'+(('0'+(today.getMonth()+1)).slice(-2))+'-'+(('0'+today.getDate()).slice(-2));
              instagramApi.setIgToken(1,{igtoken: newToken, date: newDate}).then((response) => {
                instagramApi.getInstagramFeeds(newToken).then(response => {
                  const feeds = response.data.data || [];
                  const instagramFeeds = feeds.map((feed) => !feed.children ? feed : feed.children.data[0]);
                  commit('SET_FEED', instagramFeeds)
                  resolve(instagramFeeds);
                })
              })
            })
          }else{
            instagramApi.getInstagramFeeds(token).then(response => {
              const feeds = response.data.data || [];
              const instagramFeeds = feeds.map((feed) => !feed.children ? feed : feed.children.data[0]);
              commit('SET_FEED', instagramFeeds)
              resolve(instagramFeeds);
            })
          }
        }
      }).catch(error => {
          console.error('error',error)
          resolve([])
      })
    })
  }
}

// mutations
const mutations = {
	SET_FEED(state, feeds){
		state.data = feeds;
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}