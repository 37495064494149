// import api from '../../api/webapp-api';

// initial state
const state = () => ({
	data: [],
})

// getters
const getters = {}

// actions
const actions = {
	getAbout({commit}){
		return new Promise((resolve,reject) => {
			const data = {
				whoweare: {
					image:'turtle2.jpeg',
					description: `Bali Conservation Adventures is an organization formed to support wildlife conservation in Bali through tourism.\n\nThe Indonesian archipelago consists of over 13,000 islands and home to around 300,000 animal species (equals to 17% of worldwide fauna) of which hundreds of them are categorized as endangered under IUCN Red List. Sadly, trade in wild animals and loss of habitats due to irresponsible acts of humans cause major threats, driving countless amount of species to the edge of survival. Major conservation efforts are vital if these species are not to become extinct in near future.\n\nBali, one of the eastern islands of Indonesian archipelago, has developed into a popular tourist destination since nearly a hundred years ago. Rich in culture and natural attractions, it attracts visitors from all over the world. Each year millions of tourists come and go, but yet not many are aware that there are a number of conservation efforts in this island struggling to sustain the existence of endangered species solely on their own limited resources.\n\nWe work with local conservation facilities that are still in need of supports. When these local facilities are improved, so will the chance of survival of the endangered species they are working on.\n\nJoin us to help save endangered species. Where ever you come from, whatever your background is, you CAN always contribute. Support a good cause while you are in Bali. It could be an adventure that you will cherish for a long time...\n\nHope to see you soon,\nBali Conservation Adventures Team`
				},
				whatwedo: {
					image:'dog1.jpg',
					description: `Bali Conservation Adventures combines wildlife conservation activities with tourism hospitality, making the visit to conservation facilities an educative and enjoyable experience. We find places with great conservation efforts and created an educational module especially designed for the facility and the species they are fighting for.\n\nCurrently our main programs feature sea turtle, bali starling, bali dog and greenhouse farming. However we also carry additional programs such as forest walk, coral conservation and wildlife rescue centre.\n\nOur clientele ranges from small groups, families, school groups, corporate companies to special interest segments such as vet group and biologists.\n\nWe welcome tailor made arrangements such as team building, BBQ beach clean-up, classroom activity, school field trip and student volunteer-hours requirement. Please contact us before hand for special events so we can plan it with you accordingly.`
				}
			}

			commit('SET_ABOUT', data)
			resolve({'success':data});
		})
	},
}

// mutations
const mutations = {
	SET_ABOUT(state, about){
		state.data = about;
	},
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}