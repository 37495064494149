import api from "../../api/api"

// initial state
const state = () => ({
	data: []
})

// getters
const getters = {
	searchGallery: (state) => (value) => {
		const searchValue = value.toString().toLowerCase();
		function deepSearch(item){
			return Object.keys(item).some(obj => {
				if(item[obj] && obj !== 'id' && obj !== 'position' && typeof item[obj] !== 'object'){
					return item[obj].toString().toLowerCase().includes(searchValue);
				}
			})
		}
		const searchResult = state.data.filter(item => {
			return deepSearch(item);
		})
		return searchResult;
	},
}

// actions
const actions = {
	getGallery({commit}){
		return new Promise((resolve,reject) => {
			api.getGallery().then(response => {
				commit('SET_GALLERY', response.data)
				resolve({'success':response.data});
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	addGallery({commit},gallery){
		return new Promise((resolve,reject) => {
			api.addGallery(gallery).then(response => {
				commit('SET_GALLERY', response.data.data)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	updateGallery({commit},gallery){
		return new Promise((resolve,reject) => {
			api.updateGallery(gallery.id,gallery).then(response => {
				commit('SET_GALLERY', response.data.data)
				resolve(response.data);
				}).catch(error => {
					const errorResponse = error && error.response && error.response.data;
					resolve({'errors':errorResponse && errorResponse.message});
				})
		})
	},

	deleteGallery({commit},id){
		return new Promise((resolve,reject) => {
			api.deleteGallery(id).then(response => {
				commit('DELETE_GALLERY', id)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error.response.data;
				resolve({'errors':errorResponse.message});
			})
		})
	}
}

// mutations
const mutations = {
	SET_GALLERY(state, gallery){
		gallery.forEach((program) => {
			const image = program.program_gallery.filter((gallery) => gallery.image.type === "1");
			const video = program.program_gallery.filter((gallery) => gallery.image.type === "2");

			program.program_image = image;
			program.program_video = video;
			program.view = 'image';
		})
		state.data = gallery;
	},

	ADD_GALLERY(state, gallery){
		const addIndex = state.data.findIndex((data) => data.id === gallery.id);
		state.data.splice(addIndex, 1, gallery);
	},

	UPDATE_GALLERY(state, gallery){
		const updateIndex = state.data.findIndex((data) => data.id === gallery.id);
		state.data.splice(updateIndex,1,gallery);
	},

	DELETE_GALLERY(state, id){
		let galleryIndex = null;
		const programIndex = state.data.findIndex((program) => {
			const targetGalleryIndex = program.program_gallery.findIndex((gallery) => gallery.id === id)
			if(targetGalleryIndex >= 0){
				galleryIndex = targetGalleryIndex
			}
			return targetGalleryIndex >= 0
		})
		state.data[programIndex].program_gallery.splice(galleryIndex,1)

		state.data.forEach((program) => {
			const image = program.program_gallery.filter((gallery) => gallery.image.type === "1");
			const video = program.program_gallery.filter((gallery) => gallery.image.type === "2");

			program.program_image = image;
			program.program_video = video;
		})
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}