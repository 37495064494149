import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"width":"900"},model:{value:(_vm.isOpened),callback:function ($$v) {_vm.isOpened=$$v},expression:"isOpened"}},[_c(VCard,[_c(VCardTitle,{staticClass:"bg-teal white--text"},[_c(VRow,[_c(VCol,[_vm._v("Thank you for sending us a message")]),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.isOpened = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1)],1),_c(VCardText,{staticClass:"pt-5"},[_c(VRow,{staticClass:"pr-5 pl-3 mb-5"},[_c(VCol,{staticClass:"text-body text-center",attrs:{"cols":"12"}},[_vm._v(" Our team will contact you as soon as posibble ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }