import api from "../../api/api"

// initial state
const state = () => ({
	data: []
})

// getters
const getters = {
	getImageByType: (state) => (type) => {
		if(!type){
			return []
		}
		const images = state.data;
		return images.filter((image) => image.type === (type === 'image' ? '1' : '2'));
	}
}

// actions
const actions = {
	getImage({commit}){
		return new Promise((resolve,reject) => {
			api.getImage().then(response => {
				commit('SET_IMAGE', response.data)
				resolve({'success':response.data});
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	addImage({commit},image){
		return new Promise((resolve,reject) => {
			api.addImage(image).then(response => {
				commit('ADD_IMAGE', response.data.data)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	addVideo({commit},image){
		return new Promise((resolve,reject) => {
			api.addVideo(image).then(response => {
				commit('ADD_IMAGE', response.data.data)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	deleteImage({commit},id){
		return new Promise((resolve,reject) => {
			api.deleteImage(id).then(response => {
				commit('DELETE_IMAGE', id)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error.response.data;
				resolve({'errors':errorResponse.message});
			})
		})
	}
}

// mutations
const mutations = {
	SET_IMAGE(state, image){
		state.data = image;
	},

	ADD_IMAGE(state, image){
		state.data.unshift(image);
	},

	DELETE_IMAGE(state, id){
		const deleteIndex = state.data.findIndex((data) => data.id === id);
		state.data.splice(deleteIndex, 1);
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}