import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.isFormOpened),callback:function ($$v) {_vm.isFormOpened=$$v},expression:"isFormOpened"}},[_c(VSnackbar,{attrs:{"color":_vm.snackbar.color,"timeout":"5000"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar.show = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_c('span',[_vm._v(_vm._s(_vm.snackbar.message))])]),_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[(_vm.isFormOpened)?_c(VCard,[_c(VToolbar,{staticClass:"bg-teal",attrs:{"dark":""}},[_c(VToolbarTitle,[_vm._v("Upload "+_vm._s(_vm.type === 'image' ? 'Image' : 'Video'))]),_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.isLoadingSubmit,"icon":"","dark":""},on:{"click":function($event){_vm.isFormOpened = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VProgressLinear,{style:(_vm.isLoadingSubmit ? '' : 'visibility:hidden'),attrs:{"indeterminate":"","color":"secondary"}}),_c(VCardText,[_c(VContainer,[(_vm.type === 'image')?_c(VRow,[_c(VFileInput,{ref:"imageInput",attrs:{"disabled":_vm.isLoadingSubmit,"counter":"","prepend-icon":"mdi-camera","show-size":1000,"rules":[
                  v => !!v || 'Image is required',
                  v => !v || v.size < 10000000 || 'Image size should be less than 10 MB!'
                ],"accept":"image/png, image/jpeg, image/jpg"},on:{"change":function($event){return _vm.onImagePicked($event)}}})],1):_vm._e(),(_vm.type === 'video')?_c(VRow,[_c(VFileInput,{ref:"videoInput",attrs:{"disabled":_vm.isLoadingSubmit,"counter":"","prepend-icon":"mdi-video","show-size":1000,"rules":[
                  v => !!v || 'Video is required',
                  v => !v || v.size < 50000000 || 'Video size should be less than 50 MB!'
                ],"accept":"video/mp4"},on:{"change":function($event){return _vm.onImagePicked($event)}}})],1):_vm._e()],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.isLoadingSubmit,"color":"grey darken-1","text":""},on:{"click":function($event){_vm.isFormOpened = false;}}},[_vm._v("Cancel")]),_c(VBtn,{staticClass:"fc-teal",attrs:{"disabled":_vm.isLoadingSubmit,"text":""},on:{"click":function($event){return _vm.formSave()}}},[_vm._v("Save")])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }