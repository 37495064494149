import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"overlay-opacity":".9","content-class":"h-100 no-box-shadow"},model:{value:(_vm.isOpened),callback:function ($$v) {_vm.isOpened=$$v},expression:"isOpened"}},[_c(VCard,{class:_vm.$vuetify.breakpoint.smAndDown ? 'h-100 pa-0 bg-black' : 'h-100 pa-0 transparent',staticStyle:{"height":"600px"},attrs:{"tile":""}},[_c(VRow,{staticClass:"h-100 p-relative ma-0"},[_c(VCol,{staticClass:"h-100 pa-0",attrs:{"cols":"12"}},[_c(VCarousel,{staticClass:"carousel-white",attrs:{"hide-delimiter-background":"","height":"100%","prev-icon":"mdi-arrow-left","next-icon":"mdi-arrow-right"},model:{value:(_vm.selectedGallery),callback:function ($$v) {_vm.selectedGallery=$$v},expression:"selectedGallery"}},_vm._l((_vm.galleries),function(gallery,i){return _c(VCarouselItem,{key:i,staticClass:"h-100"},[_c(VImg,{attrs:{"contain":"","height":"100%","src":gallery.imgError ? gallery.imgError : gallery.image && gallery.image.filename ? _vm.imgPath.path+gallery.image.filename : _vm.imgPath.imgEmpty},on:{"error":function($event){gallery.imgError = _vm.imgPath.imgError}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{staticClass:"fc-teal",attrs:{"indeterminate":""}})],1)]},proxy:true}],null,true)})],1)}),1)],1),_c(VCol,{staticClass:"p-absolute d-flex justify-end",attrs:{"cols":"12"}},[_c(VBtn,{staticStyle:{"border":"2px solid white"},attrs:{"fab":"","small":""},on:{"click":function($event){_vm.isOpened = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }