<template>

  <v-main class="h-100 w-100 pa-0" ref="main-application" id="main-application">

    <Loader></Loader>

    <v-app-bar height="80px" app color="rgba(255,255,255,.7)" dark shrink-on-scroll elevation prominent :scroll-threshold="scrollThreshold" style="min-height: 70px;" ref="appbar">
      <v-row class="d-flex justify-space-between w-100 h-100 black--text px-0 px-lg-7 p-absolute" style="top:0; min-height:70px; left: 0;">

        <v-col cols="2" class="h-100 pa-0 d-none d-md-block">
          <v-row class="h-100">
            <v-col class="h-100 pa-2 cursor-pointer">
              <a href="/"><v-img eager height="100%" :src="require('../../assets/logo/logo.png')" contain></v-img></a>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="auto" class="h-100 pa-0 d-none d-md-block">
          <v-row class="text-menu fw-500 d-flex align-center h-100">
            
            <!-- ABOUT -->
            <v-col cols="auto" class="py-0 btn-menu px-1 px-lg-3">
              <a :class="currentRouteName === 'About' ? 'fc-teal' : 'fc-black'" :href="currentRouteName === 'About' ? false : '/#about'" @click="scrollTo('#about')">ABOUT</a>
            </v-col>

            <!-- PROGRAM -->
            <v-col cols="auto" class="py-0 btn-menu px-1 px-lg-3">
              <v-menu open-on-hover offset-y transition="slide-x-transition">
                <template v-slot:activator="{ on, attrs }">
                  <a v-bind="attrs" v-on="on"
                    :class="currentRouteName === 'Program' || currentRouteName === 'ProgramDetail' ? 'fc-teal' : 'fc-black'" 
                    :href="currentRouteName === 'Program' ? false : '/#program'" 
                    @click="scrollTo('#program')"
                  >PROGRAMS</a>
                </template>
                <v-list>
                  <v-list-item-group>
                    <v-list-item v-for="(program) in programs" :key="program.id" :href="'/program/'+program.programId">
                      <v-list-item-title><span :class="$route.params.id === program.programId ? 'fc-teal' : 'fc-black'">{{ program.name }}</span></v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-col>

            <!-- STORIES -->
            <v-col cols="auto" class="py-0 btn-menu px-1 px-lg-3">
              <v-menu open-on-hover offset-y transition="slide-x-transition" hide-on-leave>
                <template v-slot:activator="{ on, attrs }">
                  <a v-bind="attrs" v-on="on"
                    :class="currentRouteName === 'Story' || currentRouteName === 'StoryDetail' ? 'fc-teal' : 'fc-black'" 
                    :href="currentRouteName === 'Story' ? false : '/#story'" 
                    @click="scrollTo('#story')"
                  >STORIES</a>
                </template>
                <v-list>
                  <v-subheader>STORIES</v-subheader>
                  <v-list-item-group v-if="getStoryAndBlog(null).stories">
                    <v-list-item v-for="(story) in getStoryAndBlog(null).stories" :key="story.id" :href="'/story/'+story.storyId">
                      <v-list-item-title><span :class="$route.params.id === story.storyId ? 'fc-teal' : 'fc-black'">{{ story.name }}</span></v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>

                  <v-subheader>BLOGS</v-subheader>
                  <v-list-item-group v-if="getStoryAndBlog(null).blogs">
                    <v-list-item v-for="(blog) in getStoryAndBlog(null).blogs" :key="blog.id" :href="'/story/'+blog.storyId">
                      <v-list-item-title><span :class="$route.params.id === blog.storyId ? 'fc-teal' : 'fc-black'">{{ blog.name }}</span></v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                  
                  <v-list-item-group>
                    <v-divider></v-divider>
                    <v-list-item href="/story"><v-list-item-title>See All Stories & Blogs...</v-list-item-title></v-list-item>
                  </v-list-item-group>
                  
                </v-list>
              </v-menu>
            </v-col>

            <!-- TESTIMONIALS -->
            <v-col cols="auto" class="py-0 btn-menu px-1 px-lg-3">
              <a class="fc-black" href="/#testimonial" @click="scrollTo('#testimonial')">TESTIMONIALS</a>
            </v-col>

            <!-- GALLLERY -->
            <v-col cols="auto" class="py-0 btn-menu px-1 px-lg-3">
              <a href="/#gallery" @click="scrollTo('#gallery')" :class="currentRouteName === 'Gallery' ? 'fc-teal' : 'fc-black'">GALLERY</a>
            </v-col>

            <!-- CONTACT US -->
            <v-col cols="auto" class="py-0 btn-menu px-1 px-lg-3">
              <a class="fc-black" href="/#contact" @click="scrollTo('#contact')">CONTACT US</a>
            </v-col>

            <v-col cols="auto" class="py-0 mr-5 ml-4 d-flex">
              <v-btn icon small color="black" href="https://www.facebook.com/baliconservationadventures/" target="_blank"><v-icon>mdi-facebook</v-icon></v-btn>
              <v-btn icon small class="ml-1" color="black" href="https://www.instagram.com/baliconservationadventures/" target="_blank"><v-icon>mdi-instagram</v-icon></v-btn>
              <v-btn icon small class="ml-1" href="https://www.youtube.com/channel/UCG82ssodzVHCUYzQoPw4UUA" target="_blank" color="black"><v-icon style="font-size:30px">mdi-youtube</v-icon></v-btn>
              <v-btn icon small class="ml-1" color="black" href="https://wa.me/6281529000480" target="_blank"><v-icon>mdi-whatsapp</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="2" class="h-100 pa-0 d-block d-md-none">
          <v-row class="text-menu fw-500 d-flex align-center justify-center h-100 ma-0">
            <v-col cols="auto" class="pa-0 d-flex">
              <v-btn icon outlined class="fc-teal" @click="drawer = true" ><v-icon dark>mdi-menu</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="5" class="h-100 pa-0 d-block d-md-none">
          <v-row class="text-menu fw-500 d-flex align-center h-100 ma-0">
            <v-col cols="12" class="d-flex h-100 w-100 py-4">
              <a href="/" class="w-100"><v-img eager height="100%" width="100%" :src="require('../../assets/logo/logo.png')" contain></v-img></a>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="auto" class="h-100 pa-0 d-block d-md-none">
          <v-row class="text-menu fw-500 d-flex align-center h-100 ma-0">
            <v-col cols="12" class="py-0 d-flex">
              <v-btn icon small color="black" href="https://www.facebook.com/baliconservationadventures/" target="_blank"><v-icon>mdi-facebook</v-icon></v-btn>
              <v-btn icon small class="ml-1" href="https://www.instagram.com/baliconservationadventures/" target="_blank" color="black"><v-icon>mdi-instagram</v-icon></v-btn>
              <v-btn icon small class="ml-1" href="https://www.youtube.com/channel/UCG82ssodzVHCUYzQoPw4UUA" target="_blank" color="black"><v-icon style="font-size:30px">mdi-youtube</v-icon></v-btn>
              <v-btn icon small class="ml-1" color="black" href="https://wa.me/6281529000480" target="_blank"><v-icon>mdi-whatsapp</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-col>

      </v-row>
    </v-app-bar>

    <v-navigation-drawer width="100%" class="bg-yellow main-drawer" v-model="drawer" app v-if="$vuetify.breakpoint.smAndDown" style="z-index:9;">
      <v-row class="fw-700 pa-5 text-center text-drawer">
        <v-col cols="12" class="text-right">
          <v-btn text icon x-large @click.stop="drawer = !drawer"><v-icon>mdi mdi-close</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-list>

        <!-- ABOUT -->
        <v-list-item-group class="pa-0 pa-sm-5">
          <v-list-item :href="currentRouteName === 'About' ? false : '/#about'" @click="scrollTo('#about')" :ripple="false">
            <v-list-item-title class="text-drawer fw-700 text-center main-drawer-text">ABOUT</v-list-item-title>
          </v-list-item>
        </v-list-item-group>

        <!-- PROGRAMS -->
        <v-list-group class="pa-0 pa-sm-5" active-class="fc-teal" :value="$route.name === 'Program' || $route.name === 'ProgramDetail'" :ripple="false">
          <template v-slot:activator>
            <v-list-item-content class="h-100">
              <v-list-item-title class="d-flex align-center justify-center text-drawer fw-700 text-center p-absolute w-100 h-100 main-drawer-text" style="left:0;top:0;">PROGRAMS</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item class="mt-0 mt-sm-5" v-for="(program) in programs" :key="program.id" :href="'/program/'+program.programId" :ripple="false">
            <v-list-item-title class="text-center">
              <span class=" main-drawer-text text-drawer-item" :class="$route.params.id === program.programId ? 'fc-teal' : 'fc-black'">{{ program && program.name }}</span>
            </v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>
          <v-list-item href="/program" class="text-center mt-0 mt-sm-5" :ripple="false">
            <v-list-item-title class="fw-500 main-drawer-text text-drawer-item">See All Programs</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <!-- STORIES -->
        <v-list-group class="pa-0 pa-sm-5" active-class="fc-teal" :value="$route.name === 'Story' || $route.name === 'StoryDetail'" :ripple="false">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="d-flex align-center justify-center text-drawer fw-700 text-center p-absolute w-100 h-100 main-drawer-text" style="left:0;top:0;">STORIES</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-subheader class="mt-0 mt-sm-5 d-flex justify-center fc-teal text-drawer-item">STORIES</v-subheader>
          <v-list-item class="mt-0 mt-sm-5" v-for="(story) in getStoryAndBlog(null).stories" :key="story.id" :href="'/story/'+story.storyId" :ripple="false">
            <v-list-item-title class="text-center text-drawer-item">
              <span class=" main-drawer-text" :class="$route.params.id === story.storyId ? 'fc-teal' : 'fc-black'">{{ story && story.name }}</span>
            </v-list-item-title>
          </v-list-item>

          <v-subheader class="mt-0 mt-sm-5 d-flex justify-center fc-teal text-drawer-item">BLOGS</v-subheader>
          <v-list-item class="mt-0 mt-sm-5" v-for="(blog) in getStoryAndBlog(null).blogs" :key="blog.id" :href="'/story/'+blog.storyId" :ripple="false">
            <v-list-item-title class="text-center text-drawer-item">
              <span class=" main-drawer-text" :class="$route.params.id === blog.storyId ? 'fc-teal' : 'fc-black'">{{ blog && blog.name }}</span>
            </v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>
          <v-list-item href="/story" class="text-center" :ripple="false">
            <v-list-item-title class="fw-500 main-drawer-text text-drawer-item">See All Stories & Blogs</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <!-- TESTIMONIALS -->
        <v-list-item-group class="pa-0 pa-sm-5">
          <v-list-item href="/#testimonial" @click="scrollTo('#testimonial')" :ripple="false">
            <v-list-item-title class="text-drawer fw-700 text-center main-drawer-text">TESTIMONIALS</v-list-item-title>
          </v-list-item>
        </v-list-item-group>

        <!-- GALLERY -->
        <v-list-item-group class="pa-0 pa-sm-5">
          <v-list-item href="/#gallery" @click="scrollTo('#gallery')" :ripple="false">
            <v-list-item-title class="text-drawer fw-700 text-center main-drawer-text">GALLERY</v-list-item-title
          ></v-list-item>
        </v-list-item-group>

        <!-- CONTACT US -->
        <v-list-item-group class="pa-0 pa-sm-5">
          <v-list-item href="/#contact" @click="scrollTo('#contact')" :ripple="false">
            <v-list-item-title class="text-drawer fw-700 text-center main-drawer-text">CONTACT US</v-list-item-title>
          </v-list-item>
        </v-list-item-group>


      </v-list>
    </v-navigation-drawer>

    <v-slide-y-transition class="ma-0">
      <v-flex v-if="displayArrowUp" class="pa-10" style="position:fixed;z-index:100;bottom:0;right:0;">
        <v-flex>
          <v-btn @click="scrollTo('#main-application')" icon x-large class="bg-teal white--text"><v-icon>mdi mdi-arrow-up</v-icon></v-btn>
        </v-flex>
      </v-flex>
    </v-slide-y-transition>



    <router-view></router-view>

    <v-container v-if="currentRouteName !== 'Gallery'" fluid class="bg-teal py-10">
      <v-row>
        <v-col cols="12" class="pa-0 px-5 d-flex justify-center opensans white--text text-center">
          © 2020 Bali Conservation Adventures. All rights reserved.
        </v-col>
      </v-row>
    </v-container>

  </v-main>

</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex'
  import Loader from '../../components/WebApp/Loader';

  export default {
    metaInfo: {
      title: 'Bali Conservation Adventures',
      meta: [
        { name: 'description', content: 'Bali Conservation Adventures is an organization formed to support wildlife conservation in Bali through tourism.'},
        { property: 'og:locale', content: "en_US"},
        { property: 'og:type', content: 'website'},    
        { property: 'og:title', content: "Bali Conservation Adventures"},
        { property: 'og:description', content: 'Bali Conservation Adventures is an organization formed to support wildlife conservation in Bali through tourism.'},
        { property: 'og:url', content: 'https://www.baliconservationadventures.com/'},
        { property: 'og:image', content: 'https://baliconservationadventures.com/assets/logo/logo.png'},
        { property: 'og:site_name', content: 'Bali Conservation Adventures'},
        { name: 'robots', content: 'index,follow'} 
      ],
      link: [
        {rel: 'canonical', href: 'https://www.baliconservationadventures.com'}
      ]
    },

    components: {Loader},

    data: () => ({
      scrollThreshold: 500,
      appBarcolor: 'rgba(255,255,255,.7)',
      titleLineHeight: 'line-height: 1.5rem',
      drawer: null,
      displayArrowUp: false,
      dataReady: false
    }),

    computed: {
      currentRouteName() {
        return this.$route.name;
      },
      ...mapState({
				programs: state => state.programs.data,
				storyAndBlog: state => state.stories.data,
      }),
      ...mapGetters({
        getStoryAndBlog: 'stories/getStoryAndBlog'
      }),
    },

    mounted(){
      if(this.currentRouteName === 'Home'){
        this.getAllData().then((respose) => {
          this.dataReady = true;
        });
      }

      if(this.$route.hash){
        this.$nextTick(() => {
          const loop = setInterval(() => {
            if(this.dataReady){
              setTimeout(() => {
                this.scrollTo(this.$route.hash)
              }, 200);
              clearInterval(loop)
            }
          })
        })
      }

      window.addEventListener('scroll',(event) => {
        const minOffset = 500;
        const maxOffset = 1000;
        const currentOpacity = (window.pageYOffset / this.scrollThreshold )+ 0.7;
        const maxOpacity = 0.9;
        const opacity = currentOpacity > maxOpacity ? maxOpacity : currentOpacity;
        this.appBarcolor = 'rgba(255,255,255,'+opacity.toString()+')';

        const maxLineHeight = 1.5;
        const minLineHeight = 1.2;
        const currentLineHeight = 1.5 - (window.pageYOffset / this.scrollThreshold );
        const lineHeight = currentLineHeight < minLineHeight ? minLineHeight : currentLineHeight;
        this.titleLineHeight = 'line-height: '+lineHeight+'rem';


        if(window.pageYOffset > 1500){
          this.displayArrowUp = true;
        }else{
          this.displayArrowUp = false;
        }
      });
    },

    methods: {
      ...mapActions({
        getBanner: 'banner/getBanner',
        getAbout: 'about/getAbout',
        getInstagramFeeds: 'feeds/getInstagramFeeds',
        getProgram: 'programs/getProgram',
        getQuote: 'quotes/getQuote',
        getStory: 'stories/getStory',
        getTestimonial: 'testimonials/getTestimonial',
        getGalleryHome: 'galleries/getGalleryHome',
        getContact: 'contacts/getContact'
      }),

      getAllData(){
        return Promise.all([
          this.getBanner(),
          this.getAbout(),
          this.getInstagramFeeds(),
          this.getProgram(),
          this.getQuote(),
          this.getStory(),
          this.getTestimonial(),
          this.getGalleryHome(),
          this.getContact()
        ])
      },

      scrollTo(targetRef){
        if(this.currentRouteName !== 'Home' && targetRef !== '#main-application'){
          return;
        }
        const scrollOption = {
          duration: 1000,
          offset: targetRef === '#about' ? 84 : 14,
          easing: 'easeInOutCubic',
        }
        
        this.$vuetify.goTo(targetRef, scrollOption)
      }
    },

  }
</script>
