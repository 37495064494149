import axios from 'axios'
import config from '../../config';
import store from '../store'
import router from '../router';

const api = axios.create({
    baseURL: config.API_URL
})

const failedRequest = [];

api.interceptors.request.use((request) => {
    return request;
})

api.interceptors.response.use(
    response => response,
    error => {
        const errorStatus = error.response.status;
        const isRefreshTokenState = store.getters['admin_auth/isRefreshTokenState'];

        failedRequest.push(error.config);

        // check if error is unauthenticated and not in refreshing token state
        if(errorStatus === 401 && !isRefreshTokenState){

            // check if refresh token is available
            const refresh_token = store.getters['admin_auth/refreshToken'];
            if(refresh_token && (refresh_token !== 'undefined')){
                store.dispatch('admin_auth/setRefreshTokenState',true);
                store.dispatch('admin_auth/refreshToken').then((data) => {
                    store.dispatch('admin_auth/setRefreshTokenState',false);
                    if(data.errors){
                        router.push({name:'Login'}).catch(()=>{});
                        return Promise.reject(error);
                    }
                    

                    const previousRequest = error.config;
                    previousRequest.headers['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
                    return axios.request(previousRequest);
                })
                return;
            }

            // if refresh token is not available we should redirect to login page
            return router.push({name:'Login'}).catch(()=>{});
        }

        return Promise.reject(error);
    }
)

export default {
    // AUTH
    login(data){
        return api.post('login',data);
    },
    refreshToken(data){
        return api.post('refreshToken',{'refresh_token': data});
    },
    logout(){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.post('logout'); 
    },
    
    // PROFILE
    getProfile(){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.get('profile');
    },
    updateProfile(data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.patch('profile/updateProfile',data);
    },
    updatePassword(data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.patch('profile/updatePassword',data);
    },

    // USER
    getUser(){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.get('user');
    },
    addUser(data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.post('user',data);
    },
    updateUser(id,data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.patch('user/'+id,data);
    },
    deleteUser(id){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.delete('user/'+id);
    },

    // IMAGE
    getImage(){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.get('image');
    },
    addImage(data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.post('image',data);
    },
    addVideo(data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.post('video',data,
            {headers:{'Content-Type' : 'multipart/form-data'}}
        );
    },
    deleteImage(id){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.delete('image/'+id);
    },

    // BANNER
    getBanner(){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.get('banner');
    },
    updateBanner(id,data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.patch('banner/'+id,data);
    },

    // PROGRAM
    getProgram(){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.get('program');
    },
    addProgram(data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.post('program',data);
    },
    updateProgram(id,data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.patch('program/'+id,data);
    },
    deleteProgram(id){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.delete('program/'+id);
    },

    // STORY
    getStory(){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.get('story');
    },
    addStory(data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.post('story',data);
    },
    updateStory(id,data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.patch('story/'+id,data);
    },
    deleteStory(id){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.delete('story/'+id);
    },

    // GALLERY
    getGallery(){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.get('gallery');
    },
    addGallery(data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.post('gallery',data);
    },
    updateGallery(id,data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.patch('gallery/'+id,data);
    },
    deleteGallery(id){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.delete('gallery/'+id);
    },

    // QUOTE
    getQuote(){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.get('quote');
    },
    addQuote(data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.post('quote',data);
    },
    updateQuote(id,data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.patch('quote/'+id,data);
    },
    deleteQuote(id){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.delete('quote/'+id);
    },

    // TESTIMONIAL
    getTestimonial(){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.get('testimonial');
    },
    addTestimonial(data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.post('testimonial',data);
    },
    updateTestimonial(id,data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.patch('testimonial/'+id,data);
    },
    deleteTestimonial(id){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.delete('testimonial/'+id);
    },

    // CONTACT
    getContact(){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.get('contact');
    },
    updateContact(data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['admin_auth/accessToken'];
        return api.patch('contact', data);
    },
    
}