<template>
	<v-main class="w-100 pa-0 bg-yellow">

    <!-- SLIDER -->
    <v-container fluid fill-height class="pa-0 white" style="height:100vh;">
      <v-carousel cycle hide-delimiters height="100%" class="carousel-white" prev-icon="mdi-arrow-left" next-icon="mdi-arrow-right" v-if="getProgramById($route.params.id)">
        <v-carousel-item v-for="(slider) in getProgramById($route.params.id).program_slider" :key="slider.id">
          <v-img class="h-100" eager 
            :src="slider.imgError ? slider.imgError : slider.image && slider.image.filename ? imgPath.path+slider.image.filename : imgPath.imgEmpty"
            v-on:error="slider.imgError = imgPath.imgError"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-container>

    <!-- ABOUT -->
    <v-container fluid>

      <v-row v-if="getProgramById($route.params.id)">
        <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="2"></v-col>

        <v-col cols="12" sm="10" md="10" lg="8" class="fc-black p-relative">
          <v-card>
            <v-row style="margin-top:-90px;">
              <v-col cols="12" md="10" class="pa-10 fc-black" style="opacity:.9;min-height:400px">
                <v-row>
                  <v-col cols="12" class="text-header fw-700 fc-black px-10 text-center text-md-left">{{getProgramById($route.params.id).name}}</v-col>
                  <v-col cols="12" md="9" lg="10" class="text-body fw-300 text-justify fc-black px-10">
                    {{getProgramById($route.params.id).brief ? getProgramById($route.params.id).brief : 'No Description'}}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>

          <v-card v-if="$vuetify.breakpoint.mdAndUp" class="p-absolute pa-7" 
            :style="$vuetify.breakpoint.lgAndUp ? 'bottom:-50px;right:-125px;min-height: 300px;width:300px' : 'bottom:-50px;right:-50px;min-height: 300px;width:300px'"
          >
            <v-row class="w-100 h-100">
              <v-col cols="12" class="fc-teal fw-700" style="font-size: 1.2rem;">
                EXPLORE
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-list flat>
                  <v-list-item :ripple="false" v-for="(program) in getProgramExplore($route.params.id)" :key="program.id" class="pa-0 fc-black opensans text-content mb-3" :href="'/program/'+program.programId" 
                    style="letter-spacing:.5px"
                  >
                    <v-icon class="mr-2">mdi mdi-chevron-right</v-icon> {{program.name}}
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card>

        </v-col>

        <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="2"></v-col>
      </v-row>

    </v-container>

    <!-- CONTENT -->
    <v-container fluid class="mt-10">
      <v-row class="mt-10" v-if="getProgramById($route.params.id)">
        <v-col cols="1" lg="2"></v-col>
        <v-col cols="10" lg="8">

          <v-row>
            <v-col cols="12">
              <p class="d-flex d-md-block float-md-left mr-md-5 mb-5 mb-md-1 mt-2">
                <v-img contain width="500px"
                  :src="getProgramById($route.params.id).program_detail.imgError ? getProgramById($route.params.id).program_detail.imgError : getProgramById($route.params.id).program_detail.image && getProgramById($route.params.id).program_detail.image.filename ? imgPath.path+getProgramById($route.params.id).program_detail.image.filename : imgPath.imgEmpty"
                  v-on:error="getProgramById($route.params.id).program_detail.imgError = imgPath.imgError"
                >
                </v-img>
              </p>
              <p class="ws-pre-line text-justify opensans fc-black text-description fw-300">{{getProgramById($route.params.id).program_detail.content}}</p>
            </v-col>
          </v-row>

          <v-row class="mb-5" v-if="getProgramById($route.params.id).program_detail.itinerary_show === 1">
            <v-col cols="12" class="fc-teal fw-600 text-subtitle">Typical Itinerary</v-col>
            <v-col cols="12" class="ws-pre-line text-justify fw-300 fc-black text-description" v-if="getProgramById($route.params.id).program_detail.itinerary && getProgramById($route.params.id).program_detail.itinerary.length > 0">
              <v-row v-for="(itinerary,index) in getProgramById($route.params.id).program_detail.itinerary" :key="index">
                <v-col cols="12" class="pb-0" style="white-space: break-spaces;">{{itinerary.description}}</v-col>
                <v-col cols="12" class="pt-0" v-if="itinerary.timeslot && itinerary.timeslot.length > 0">
                  <v-row class="ma-0" v-for="(timeslot,index) in itinerary.timeslot" :key="index">
                    <v-col cols="2" md="1" class="pa-0">{{timeslot.time}}</v-col>
                    <v-col cols="10" md="11" class="pa-0">{{timeslot.description}}</v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

          </v-row>

          <v-row class="mb-5" v-if="getProgramById($route.params.id).program_detail.whattobring_show === 1">
            <v-col cols="12" class="fc-teal fw-600 text-subtitle">What to bring or wear</v-col>
            <v-col cols="12" class="py-2 text-justify fw-300 fc-black text-description" 
              v-for="(item,i) in getProgramById($route.params.id).program_detail.whattobring" :key="i"
            >
              <v-row class="ma-0">
                <v-col cols="auto" class="pa-0"><v-icon class="mdi mdi-check mr-3"></v-icon></v-col>
                <v-col class="pa-0">{{item}}</v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="mb-5" v-if="getProgramById($route.params.id).program_detail.inclusion_show === 1">
            <v-col cols="12" class="fc-teal fw-600 text-subtitle">What's included ?</v-col>
            <v-col cols="12" class="py-2 text-justify fw-300 fc-black text-description" 
              v-for="(item,i) in getProgramById($route.params.id).program_detail.inclusion" :key="i"
            >
              <v-row class="ma-0">
                <v-col cols="auto" class="pa-0"><v-icon class="mdi mdi-check mr-3"></v-icon></v-col>
                <v-col class="pa-0">{{item}}</v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="mb-5" v-if="getProgramById($route.params.id).program_detail.exclusion_show === 1">
            <v-col cols="12" class="fc-teal fw-600 text-subtitle">What's excluded ?</v-col>
            <v-col cols="12" class="py-2 text-justify fw-300 fc-black text-description" 
              v-for="(item,i) in getProgramById($route.params.id).program_detail.exclusion" :key="i"
            >
              <v-row class="ma-0">
                <v-col cols="auto" class="pa-0"><v-icon class="mdi mdi-check mr-3"></v-icon></v-col>
                <v-col class="pa-0">{{item}}</v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="mb-5" v-if="getProgramById($route.params.id).program_detail.group_show === 1">
            <v-col cols="12" class="fc-teal fw-600 text-subtitle">Groups</v-col>
            <v-col cols="12">
              <span class="ws-pre-line text-justify fw-300 fc-black text-description">{{getProgramById($route.params.id).program_detail.group}}</span>
            </v-col>
          </v-row>

        </v-col>
        <v-col cols="1" lg="2"></v-col>
      </v-row>

    </v-container>

    <!-- EXPLORE -->
    <v-container fluid class="bg-primary pa-0">
      <v-row class="mt-10 ma-0">
        <v-col cols="12" class="pb-0 pa-0">
          <v-card tile class="p-relative">
            <v-img class="p-absolute w-100 h-100" :src="require('@/assets/assets/dog1.jpg')"></v-img>

            <v-row class="mt-10 ma-0">
              <v-col cols="1"></v-col>
              <v-col cols="12" sm="10">
                <v-card flat tile style="background:none">
                  <v-row>
                    <v-col cols="12" class="text-header fw-700 fc-black mt-10">MORE...</v-col>
                    <!-- <v-col cols="12" class="text-body fw-300 text-justify fc-black">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    </v-col> -->
                  </v-row>
                </v-card>

                <v-row>

                  <v-col cols="12" md="4" v-for="(program) in getProgramExploreMore($route.params.id)" :key="program.id">
                    <v-card class="h-100">
                      <v-row class="ma-0 h-100 d-flex flex-md-column">

                        <v-col cols="4" md="auto" class="pa-0 d-flex" :style="$vuetify.breakpoint.mdAndUp ? 'height:300px;max-height:300px;' : ''">
                          <v-img height="100%"
                            :src="program.imgError ? program.imgError : program.image && program.image.filename ? imgPath.path+program.image.filename : imgPath.imgEmpty"
														v-on:error="program.imgError = imgPath.imgError"
                          ></v-img>
                        </v-col>

                        <v-col cols="8" md="auto" class="d-flex flex-fill">
                          <v-row class="ma-0">
                            <v-col cols="12" class="d-flex align-start fc-black text-subtitle fw-700 pa-5">{{program.name}}</v-col>
                            <v-col cols="12" class="text-body fw-300 fc-black text-justify pa-5 pt-0">{{program.brief ? program.brief : 'No Description'}}</v-col>
                            <v-col cols="12" class="d-flex align-end justify-end justify-md-center pa-5 pt-0 pb-7">
                              <v-btn tile class="bg-teal" dark :href="'/program/'+program.programId">EXPLORE</v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                        
                      </v-row>
                    </v-card>
                  </v-col>

                </v-row>
              </v-col>
              <v-col cols="1"></v-col>
            </v-row>

            <v-row class="mt-10 ma-0 d-flex justify-center">
              <v-col cols="auto" class="justify-center mb-10">
                <v-card rounded x-large dark class="text-description fc-black fw-400 text-center mailtoui" href="mailto:info@baliconservationadventures.com?Subject=Hello"
                  target="_top"
                  style="text-transform:none;background:rgba(245, 245, 245, .7);height:100%;border-radius:25px!important;"
                >
                  <v-row class="ma-0 pa-5">
                    <v-col cols="12" class="pa-0">
                      If you cannot find the species that you are interested in or would <span v-if="$vuetify.breakpoint.mdAndUp"><br/></span>
                      like to arrange a tailor-made program please feel free to contact us
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
           
          </v-card>
        </v-col>
      </v-row>

    </v-container>

  </v-main>

</template>

<script>
  import config from '../../../config';
  import MailtoUI from '../../plugins/mailtoui';
  import { mapState, mapActions, mapGetters } from 'vuex'
	
  export default {
    metaInfo: {
      title: 'Bali Conservation Adventures | Program',
      meta: [
        { name: 'description', content: 'Bali Conservation Adventures is an organization formed to support wildlife conservation in Bali through tourism.'},
        { property: 'og:locale', content: "en_US"},
        { property: 'og:type', content: 'website'},    
        { property: 'og:title', content: "Bali Conservation Adventures | Program"},
        { property: 'og:description', content: 'Bali Conservation Adventures is an organization formed to support wildlife conservation in Bali through tourism.'},
        { property: 'og:url', content: 'https://www.baliconservationadventures.com/program'},
        { property: 'og:image', content: 'https://baliconservationadventures.com/assets/logo/logo.png'},
        { property: 'og:site_name', content: 'Bali Conservation Adventures'},
        { name: 'robots', content: 'index,follow'} 
      ],
      link: [
        {rel: 'canonical', href: 'https://www.baliconservationadventures.com/progam'}
      ]
    },

    data: () => ({
			imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
		}),

		computed: {
			...mapState({
				programs: state => state.programs.data,
			}),
      ...mapGetters({
        getProgramById: 'programs/getProgramById',
        getProgramExplore: 'programs/getProgramExplore',
        getProgramExploreMore: 'programs/getProgramExploreMore'
      }),
		},

    mounted(){
      this.getProgram();
      this.getStory();
      MailtoUI.run();
		},

		methods:{
			...mapActions({
        getProgram: 'programs/getProgram',
        getStory: 'stories/getStory'
      }),
    }
  }
</script>