<template>
	<v-container fluid class="pt-10 pb-10">

		<v-row class="pb-10">
			<v-col cols="1" sm="2" class="black--text"></v-col>

			<v-col cols="10" sm="8" class="black--text">

				<v-row>
          <v-col cols="12" class="text-header fc-teal fw-700 text-center">LIVE INSTAGRAM FEEDS</v-col>
				</v-row>

        <v-row dense class="mt-10">
          <v-col cols="6" sm="6" md="3" style="aspect-ratio: 1/1" v-for="cRow in $vuetify.breakpoint.smAndDown ? 4 : 8" :key="cRow">
            <v-card tile class="h-100 w-100" v-if="instagramFeeds[cRow-1]">

              <v-img v-if="instagramFeeds[cRow-1].media_type === 'IMAGE' || instagramFeeds[cRow-1].media_type === 'CAROUSEL_ALBUM'" eager height="100%" :src="instagramFeeds[cRow-1].media_url">
								<template v-slot:placeholder>
									<v-row class="fill-height ma-0" align="center" justify="center">
										<v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
									</v-row>
								</template>	
							</v-img> 

							<video v-if="instagramFeeds[cRow-1].media_type === 'VIDEO'" width="100%" height="100%" autoplay loop muted style="object-fit:fill">
								<source class="h-100" :src="instagramFeeds[cRow-1].media_url" type="video/mp4">
								Your browser does not support HTML video.
							</video>


            </v-card>
          </v-col>
				</v-row>

			</v-col>

			<v-col cols="1" sm="2" class="black--text"></v-col>
		</v-row>

	</v-container>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex'
	export default {
			computed: {
				...mapState({
					instagramFeeds: state => state.feeds.data,
				})
			},
	
	}
</script>