import api from "../../api/api";

// initial state
const state = () => ({
	data: []
})

// getters
const getters = {
	searchQuote: (state) => (value) => {
		const searchValue = value.toString().toLowerCase();
		function deepSearch(item){
			return Object.keys(item).some(obj => {
				if(item[obj] && obj !== 'id' && obj !== 'position'){
					return typeof item[obj] === 'object' ? deepSearch(item[obj]) : item[obj].toString().toLowerCase().includes(searchValue);
				}
			})
		}
		const searchResult = state.data.filter(item => {
			return deepSearch(item);
		})
		return searchResult;
	}
}

// actions
const actions = {
	getQuote({commit}){
		return new Promise((resolve,reject) => {
			api.getQuote().then(response => {
				commit('SET_QUOTE', response.data)
				resolve({'success':response.data});
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	addQuote({commit},quote){
		return new Promise((resolve,reject) => {
			api.addQuote(quote).then(response => {
				commit('ADD_QUOTE', response.data.data)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	updateQuote({commit},quote){
		return new Promise((resolve,reject) => {
			api.updateQuote(quote.id,quote).then(response => {
				commit('UPDATE_QUOTE', response.data.data)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	deleteQuote({commit},id){
		return new Promise((resolve,reject) => {
			api.deleteQuote(id).then(response => {
				commit('DELETE_QUOTE', id)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	}
}

// mutations
const mutations = {
	SET_QUOTE(state, quote){
		state.data = quote;
	},

	ADD_QUOTE(state, quote){
		state.data.unshift(quote);
	},

	UPDATE_QUOTE(state, quote){
		const updateIndex = state.data.findIndex((data) => data	.id === quote.id);
		state.data.splice(updateIndex,1,quote);
	},

	DELETE_QUOTE(state, id){
		const deleteIndex = state.data.findIndex((data) => data.id === id);
		state.data.splice(deleteIndex, 1);
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}