import api from "../../api/api";

// initial state
const state = () => ({
	data: []
})

// getters
const getters = {
	searchTestimonial: (state) => (value) => {
		const searchValue = value.toString().toLowerCase();
		function deepSearch(item){
			return Object.keys(item).some(obj => {
				if(item[obj]  && obj !== 'id' && obj !== 'position' && obj !== 'image' && obj !== 'type'){
					return typeof item[obj] === 'object' ? deepSearch(item[obj]) : item[obj].toString().toLowerCase().includes(searchValue);
				}
			})
		}
		const searchResult = state.data.filter(item => {
			return deepSearch(item);
		})
		return searchResult;
	}
}

// actions
const actions = {
	getTestimonial({commit}){
		return new Promise((resolve,reject) => {
			api.getTestimonial().then(response => {
				commit('SET_TESTIMONIAL', response.data)
				resolve({'success':response.data});
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	addTestimonial({commit},testimonial){
		return new Promise((resolve,reject) => {
			api.addTestimonial(testimonial).then(response => {
				commit('ADD_TESTIMONIAL', response.data.data)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	updateTestimonial({commit},testimonial){
		return new Promise((resolve,reject) => {
			api.updateTestimonial(testimonial.id,testimonial).then(response => {
				commit('UPDATE_TESTIMONIAL', response.data.data)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	deleteTestimonial({commit},id){
		return new Promise((resolve,reject) => {
			api.deleteTestimonial(id).then(response => {
				commit('DELETE_TESTIMONIAL', id)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	}
}

// mutations
const mutations = {
	SET_TESTIMONIAL(state, testimonial){
		state.data = testimonial;
	},

	ADD_TESTIMONIAL(state, testimonial){
		state.data.unshift(testimonial);
	},

	UPDATE_TESTIMONIAL(state, testimonial){
		const updateIndex = state.data.findIndex((data) => data	.id === testimonial.id);
		state.data.splice(updateIndex,1,testimonial);
	},

	DELETE_TESTIMONIAL(state, id){
		const deleteIndex = state.data.findIndex((data) => data.id === id);
		state.data.splice(deleteIndex, 1);
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}