<template>
  <v-container fluid class="pa-0">

		<v-row class="w-100 ma-0">
			<v-parallax class="w-100" height="400" :src="require('@/assets/about-parallax.jpg')" style="filter: brightness(90%)">
				<v-row class="d-flex align-center">
					<v-col cols="12" class="d-flex justify-center">
						<v-card @click="openAboutModal()" class="pa-5 text-subtitle fc-black text-center h-100 fw-600" style="text-transform:none;background:rgba(245, 245, 245, .7);border-radius:25px;">
							<v-row>
								<v-col cols="12" class="fc-teal">Why book Bali Conservation Adventures ?</v-col>
							</v-row>
						</v-card>
					</v-col>
				</v-row>
			</v-parallax>
		</v-row>
    <AboutModal ref="aboutModalRef"></AboutModal>

  </v-container>
</template>

<script>
  import AboutModal from './AboutModal';
  import config from '../../../config';
  import { mapState, mapActions } from 'vuex'

  export default {
		components: {AboutModal},

    methods: {
			openAboutModal(){
				this.$refs.aboutModalRef.open();
			},
		}
  }
</script>
