<template>
  <v-row justify="center">
    <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" overlay-opacity=".9" content-class="h-100 no-box-shadow" v-model="isOpened">
      <v-card tile :class="$vuetify.breakpoint.smAndDown ? 'h-100 pa-0 bg-black' : 'h-100 pa-0 transparent'" style="height: 600px">

        <v-row class="h-100 p-relative ma-0">
          <v-col cols="12" class="h-100 pa-0">
            <v-carousel hide-delimiter-background v-model="selectedGallery" height="100%" class="carousel-white" prev-icon="mdi-arrow-left" next-icon="mdi-arrow-right">
              <v-carousel-item class="h-100" v-for="(gallery,i) in galleries" :key="i">
                <v-img contain height="100%"
                  :src="gallery.imgError ? gallery.imgError : gallery.image && gallery.image.filename ? imgPath.path+gallery.image.filename : imgPath.imgEmpty"
                  v-on:error="gallery.imgError = imgPath.imgError"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </v-col>

          <v-col cols="12" class="p-absolute d-flex justify-end">
            <v-btn fab small style="border:2px solid white" @click="isOpened = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-col>
        </v-row>

      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
	import config from '../../../config';
  export default {
    data: () => ({
      isOpened: null,
      selectedGallery: 0,
      galleries: [],
      imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
			},
    }),

    methods: {
      open(items, index) {
        this.isOpened = true;
        this.galleries = items;
        this.selectedGallery = index;
      }
    },
  }
</script>