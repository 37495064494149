<template>
  <v-row justify="center">
    <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" width="900" v-model="isOpened">
      <v-card>

        <v-card-title class="bg-teal white--text">
          <v-row>
            <v-col>Thank you for sending us a message</v-col>
            <v-col cols="auto">
              <v-btn icon dark @click="isOpened = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text class="pt-5">
          <v-row class="pr-5 pl-3 mb-5">
            <v-col cols="12" class="text-body text-center">
              Our team will contact you as soon as posibble
            </v-col>
          </v-row>

        </v-card-text>

      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
  export default {
    data: () => ({
      isOpened: null
    }),

    methods: {
      open() {
        this.isOpened = true;
      }
    },
  }
</script>

