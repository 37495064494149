const URL = 'https://apis.baliconservationadventures.com/'
// const URL = 'http://localhost:8001/'
const API_URL = URL+'api/'

export default {
  API_URL: API_URL,
  WEBAPP_API_URL: API_URL+'frontend/',
  EDITOR_API_URL: API_URL+'image-editor',
  STORAGE_URL: URL+'assets/',
  IMG_LAZY: URL+'image-lazy.png',
  IMG_EMPTY: URL+'image-empty.png',
  IMG_ERROR: URL+'image-error.png',
  INSTAGRAM_API_URL: 'https://graph.instagram.com/me/media',
  INSTAGRAM_TOKEN: 'IGQVJVTHRZAX2JackpUbmZATUGI4bDBpUThkYzZAPSk1BNHdCbWY1TERaQW11Q3g5ZAnZAtLWhSbWNpZAlc3eG5XeU5NMFdwU24zeWt3SEp2OUM0SEZAFSEgyQ09ablZAyNDhKYVhrazJGOEpISUs0UDJFSW1IYQZDZD',
  CAPTCHA_KEY: '6LfOTqAkAAAAAGfkOOvNgs977vDA5DBOTQ927YkQ'
}