<template>
  <v-container fluid fill-height class="pa-0">

    <v-flex class="d-block d-lg-none" style="margin-top:80px">
      <v-img class="h-100" eager :src="banner.imgError ? banner.imgError : banner.image && banner.image.filename ? imgPath.path+banner.image.filename : '/'">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-flex>

    <v-flex class="d-lg-block d-none" style="height:100vh">
      <v-img class="h-100" eager :src="banner.imgError ? banner.imgError : banner.image && banner.image.filename ? imgPath.path+banner.image.filename : '/'">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-flex>

  </v-container>
</template>

<script>
  import config from '../../../config';
  import { mapState, mapActions } from 'vuex'

  export default {
    data: () => ({
			imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
		}),
		
		computed: mapState({
			banner: state => state.banner.data,
		})
  }
</script>
