import { VCard } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VParallax } from 'vuetify/lib/components/VParallax';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VParallax,{class:_vm.$vuetify.breakpoint.xs ? 'dog-small' : '',staticStyle:{"filter":"brightness(90%)"},attrs:{"height":"500","src":require('@/assets/assets/dog3.jpg')}},[_c(VRow,{staticClass:"d-flex align-center h-100"},[_c(VCarousel,{staticClass:"carousel-white delimiter-black",attrs:{"cycle":"","hide-delimiter-background":"","hide-delimiters":_vm.$vuetify.breakpoint.smAndUp,"show-arrows":_vm.$vuetify.breakpoint.smAndUp,"height":"100%","prev-icon":"mdi-arrow-left","next-icon":"mdi-arrow-right"}},_vm._l((_vm.quotes),function(quote){return _c(VCarouselItem,{key:quote.id},[_c(VRow,{staticClass:"h-100 d-flex align-center"},[_c(VCol,{attrs:{"cols":"1"}}),_c(VCol,{attrs:{"cols":"10"}},[_c(VCard,{staticClass:"pa-10 text-quote text-center fw-300 quote-hover",attrs:{"tile":""}},[_c('div',{staticClass:"text-left p-absolute w-100",staticStyle:{"top":"-40px","left":"-30px"}},[_c(VIcon,{staticStyle:{"font-size":"80px"},attrs:{"x-large":"","color":"white"}},[_vm._v("mdi-format-quote-open")])],1),_c('div',{staticClass:"fw-300 font-italic pt-5 ws-break-spaces"},[_vm._v(_vm._s(quote.quote))]),_c('div',{staticClass:"text-quote-caption fw-600 text-center mt-5 ws-break-spaces"},[_vm._v(_vm._s(quote.author))]),_c('div',{staticClass:"text-right p-absolute w-100",staticStyle:{"bottom":"-40px","left":"30px"}},[_c(VIcon,{staticStyle:{"font-size":"80px"},attrs:{"x-large":"","color":"white"}},[_vm._v("mdi-format-quote-close")])],1)])],1),_c(VCol,{attrs:{"cols":"1"}})],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }