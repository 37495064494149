<template>

 <v-app>

    <!-- SIDEBAR -->
    <v-navigation-drawer v-model="sidebar" app clipped>

      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon large>mdi-account-circle</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="fw-500">{{profile && profile.name}}</v-list-item-title>
            <v-list-item-subtitle class="mb-2 text-caption">{{profile && profile .email}}</v-list-item-subtitle>
            <v-list-item-subtitle class="mt-4">
              <v-btn color="bg-teal" dark x-small to="/admin">
                View Profile
              </v-btn>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <v-list dense>
        <template v-for="menu in configMenu">

          <!-- MENU WITH NO CHILD -->
          <template v-if="!menu.child">
            <v-list-item :key="menu.id" :to="menu.link" active-class="fc-teal">
              <v-list-item-action>
                <v-icon v-text="menu.icon"></v-icon>
              </v-list-item-action>
              <v-list-item-title v-text="menu.title"></v-list-item-title>
          </v-list-item>
          </template>

          <!-- MENU WITH CHILD -->
          <template v-if="menu.child">
            <v-list-group :key="menu.id" :prepend-icon="menu.icon" no-action active-class="fc-teal">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="menu.title"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item v-for="child in menu.child" :key="child.id" :to="child.link" active-class="fc-teal">
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                  <v-list-item-action>
                    <v-icon v-text="child.icon"></v-icon>
                  </v-list-item-action>
              </v-list-item>

            </v-list-group>
          </template>

        </template>

      </v-list>
    </v-navigation-drawer>

    <!-- TOOLBAR -->
    <v-app-bar app dark clipped-left color="bg-teal">
      <v-app-bar-nav-icon @click.stop="sidebar = !sidebar"></v-app-bar-nav-icon>
      <v-toolbar-title class="d-flex align-center">
          <span class="fw-600">Bali Conservation Adventures</span>
          <span class="fw-400 ml-2">| Admin Panel</span>
        </v-toolbar-title>
      <v-spacer></v-spacer>
      
      <!-- PROFILE ICON -->
      <v-menu
        transition="slide-x-transition"
        :close-on-content-click="false"
        offset-y
        :nudge-width="200"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>

        <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{profile && profile.name}}</v-list-item-title>
              <v-list-item-subtitle>{{profile && profile.email}}</v-list-item-subtitle >
            </v-list-item-content>

            <v-list-item-action>
              <v-btn text color="grey" small @click="logout()"><v-icon left style="font-size:15px">mdi mdi-logout</v-icon> Logout</v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>

      </v-menu>

    </v-app-bar>

    <v-main style="height:0px;" class="grey lighten-4">
        <router-view></router-view>
    </v-main>

  </v-app>

</template>


<script>
  import { mapState, mapActions, mapGetters } from 'vuex'

  export default {
    metaInfo: {
      title: 'Bali Conservation Adventures',
    },

    data: () => ({
      sidebar: null,
      isLoadingData: false
    }),

    computed: {
      ...mapState({
        profile: state => state.admin_profile.data,
        configMenu: state => state.admin_config.menu
      }),
    },

    mounted(){
      this.isLoadingData = true;
      this.getProfile().then((response) => {
        if(response.errors){
          this.callSnackbar('error',response.errors);
          return;
        }
        this.isLoadingData = false;
      });
    },

    methods: {
      ...mapActions({
        getProfile: 'admin_profile/getProfile'
      }),

      logout(){
        this.$store.dispatch('admin_auth/logout').then((data) => {
          if(data.errors){
            this.callSnackbar('error','Logout failed');
            return;
          }
          this.$router.push({name:'Login'}).catch(()=>{});
        });
        return;
      }
    }

  }
</script>