<template>
  <v-container fill-height fluid class="overflow-auto px-sm-7 pb-7">

    <FormTestimonial ref="formTestimonial" @formTestimonialSaved="createUpdateData"></FormTestimonial>
    <DialogDeleteConfirmation ref="dialogDeleteConfirmation" @dialogDeleteConfirmed="deleteData"></DialogDeleteConfirmation>

    <v-snackbar :color="snackbar.color" v-model="snackbar.show" timeout="5000">
      <span>{{snackbar.message}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>
    
    <v-row class="justify-center align-self-start">
      <v-col cols="12" sm="10" md="10">
        <v-layout column>
          
          <!-- DETAIL -->
          <v-flex>

            <!-- TITLE -->
            <v-row>
              <v-col cols="6">
                <span class="title d-flex"><v-icon class="mr-2">mdi-comment-text-multiple-outline</v-icon>Testimonials</span>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn :disabled="isLoadingData" small color="bg-teal" :dark="!isLoadingData" @click="openFormTestimonial('add')">
                  <v-icon left small>mdi-plus</v-icon>Add New
                </v-btn>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-progress-linear :style="isLoadingData ? '' : 'visibility:hidden'" indeterminate color="bg-teal"></v-progress-linear>

            <v-row>
              <!-- SEARCH BOX -->
              <v-col cols="12">
                <v-text-field :disabled="isLoadingData" dense solo v-model="searchValue" append-icon="mdi-magnify" label="Search" single-line hide-details>
                </v-text-field>
              </v-col>
            </v-row>

          </v-flex>
          
          <!-- TABLE -->
          <v-flex xs12 class="mt-2" style="height:0px;">
              <v-row>

                <v-col cols="12" class="mt-5 grey--text text-caption-1 text-center" v-if="testimonialList(this.searchValue).length === 0">
                  No Data Available
                </v-col>

                <v-slide-x-transition hide-on-leave group class="row ma-0">

                  <v-col cols="12" v-for="testi in testimonialList(this.searchValue)" :key="testi.id">
                    <v-card class="mx-auto pa-5 pa-sm-5">

                      <v-row>

                        <v-col cols="12" sm="11">
                          <v-row>

                            <v-col cols="12" sm="4" md="2" class="d-flex justify-center">
                              <div class="d-flex">
                                <v-img height="90px" width="90px" style="border-radius:100%"
                                  :src="testi.imgError ? testi.imgError : testi.image && testi.image.filename ? imgPath.path+testi.image.filename+refreshImage : imgPath.imgEmpty"
                                  :lazy-src="imgPath.lazyPath"
                                  v-on:error="testi.imgError = imgPath.imgError"
                                >
                                  <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </div>
                            </v-col>

                            <v-col cols="12" sm="8" md="10">
                              <v-row class="ma-0">
                                <v-col cols="12" class="pa-0">
                                  <v-row class="ma-0">
                                    <v-col cols="6" class="pa-0">
                                      <v-row class="ma-0">
                                        <v-col cols="12" class="pa-0 text-admin-title">{{testi.name}}</v-col>
                                        <v-col cols="12" class="text-admin-caption grey--text pa-0">{{testi.caption}}</v-col>
                                      </v-row>
                                    </v-col>
                                    <v-col cols="6" class="text-right pa-0">
                                      <v-chip small color="bg-teal" text-color="white">
                                        Position <v-avatar right class="grey lighten-3 black--text">{{testi.position}}</v-avatar>
                                      </v-chip>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" class="text-caption-2 fw-300 pa-0 pt-5" style="white-space: break-spaces;">{{testi.testimonial}}</v-col>
                              </v-row>
                            </v-col>

                          </v-row>
                        </v-col>

                        <v-col cols="2" sm="1" class="text-right" :style="$vuetify.breakpoint.xs ? 'position:absolute;right:0;top:0;' : ''">
                          <v-menu transition="slide-x-transition" bottom left offset-y :nudge-width="100">
                            <template v-slot:activator="{ on }">
                              <v-btn :disabled="isLoadingData" small icon v-on="on">
                                <v-icon small>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>

                            <v-list dense>
                              <v-list-item :disabled="isLoadingData" @click="openFormTestimonial('edit',testi)">
                                <v-list-item-icon class="mr-1"><v-icon small>mdi-pencil-outline</v-icon></v-list-item-icon>
                                <v-list-item-title>Edit</v-list-item-title>
                              </v-list-item>
                              <v-list-item :disabled="isLoadingData" @click="openDialogDeleteConfirmation(testi.id,testi.name)">
                                <v-list-item-icon class="mr-1"><v-icon small>mdi-trash-can-outline</v-icon></v-list-item-icon>
                                <v-list-item-title>Delete</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-col>


                      </v-row>

                    </v-card>

                  </v-col>

                </v-slide-x-transition>
              </v-row>
          </v-flex>

        </v-layout>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import config from '../../../config';
  import { mapState, mapGetters, mapActions } from 'vuex';
  import FormTestimonial from '../../components/AdminApp/FormTestimonial';
  import DialogDeleteConfirmation from '../../components/AdminApp/DialogDeleteConfirmation';

  export default {
    metaInfo: {
      title: 'Bali Conservation Adventures',
    },

    components: {
      FormTestimonial,
      DialogDeleteConfirmation
    },

    data: () => ({
      isLoadingData: false,
      snackbar: { show: false, color: '', message: ''},

      refreshImage: '',
      imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },

      searchValue: '',
    }),

    computed: {
      ...mapState({
        testimonial: state => state.admin_testimonial.testimonial,
      }),
      ...mapGetters({
        testimonialList: 'admin_testimonial/searchTestimonial',
      })
    },

    mounted(){
      this.isLoadingData = true;
      this.getTestimonial().then((response) => {
        if(response.errors){
          this.callSnackbar('error',response.errors);
          return;
        }
        this.isLoadingData = false;
      });
    },

    methods: {
      ...mapActions({
        getTestimonial: 'admin_testimonial/getTestimonial'
      }),

      actionRefreshImage(){
        const now = Date.now();
        this.refreshImage = '?'+now;
      },

      openFormTestimonial: function (action, item){
        this.$refs.formTestimonial.openForm(action, item);
      },

      openDialogDeleteConfirmation: function (id, name){
        this.$refs.dialogDeleteConfirmation.openDialog(id, name);
      },

      createUpdateData(data){
        if(data.errors){
          this.callSnackbar('error',data.errors)
          return;
        }
        this.callSnackbar('success',data.success)
      },

      deleteData(id){
        this.isLoadingData = true;
        this.$store.dispatch('admin_testimonial/deleteTestimonial',id).then((data) => {
          this.isLoadingData = false;
          if(data.errors){
            this.callSnackbar('error',data.errors);
            return;
          }
          this.callSnackbar('success',data.success);
        });
      },

      callSnackbar(color,message){
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
      }
    }

  }
</script>