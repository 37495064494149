import api from "../../api/api";

// initial state
const state = () => ({
	accessToken: localStorage.getItem('access_token') || null,
	refreshToken: localStorage.getItem('refresh_token') || null,
	isRefreshTokenState: false
})

// getters
const getters = {
	loggedIn: (state) => {
		return (state.accessToken !== null) && (state.accessToken !== 'undefined');
	},

	accessToken: (state) => {
		return state.accessToken;
	},

	refreshToken: (state) => {
		return state.refreshToken;
	},

	isRefreshTokenState: (state) => {
		return state.isRefreshTokenState;
	}
}

// actions
const actions = {
	login({commit},credentials){
		return new Promise((resolve,reject) => {
			api.login(credentials).then(response => {
				if(response.data.error){
					resolve({'errors':response.data.error});
				}
				commit('SET_ACCESS_TOKEN', response.data.access_token)
				commit('SET_REFRESH_TOKEN', response.data.refresh_token)
				resolve({'success':response.data});
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},
	refreshToken({commit, state}){
		commit('SET_REFRESH_TOKEN_STATE', true)
		return new Promise((resolve,reject) => {
			api.refreshToken(state.refreshToken).then(response => {
				if(response.data.error){
					resolve({'errors':response.data.error});
				}
				commit('SET_ACCESS_TOKEN', response.data.access_token)
				commit('SET_REFRESH_TOKEN', response.data.refresh_token)
				resolve({'success':response.data});
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},
	logout({commit}){
		return new Promise((resolve,reject) => {
			api.logout().then(response => {
				if(response.data.error){
					resolve({'errors':response.data.error});
				}
				commit('REMOVE_ACCESS_TOKEN')
				commit('REMOVE_REFRESH_TOKEN')
				resolve({'success':response.data});
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},
	setRefreshTokenState({commit},data){
		commit('SET_REFRESH_TOKEN_STATE', data)
	}
}

// mutations
const mutations = {
	SET_ACCESS_TOKEN(state, accessToken){
		localStorage.setItem('access_token',accessToken)
		state.accessToken = accessToken;
	},
	SET_REFRESH_TOKEN(state, refreshToken){
		localStorage.setItem('refresh_token',refreshToken)
		state.refreshToken = refreshToken;
	},

	REMOVE_ACCESS_TOKEN(state){
		localStorage.removeItem('access_token')
		state.accessToken = null;
	},
	REMOVE_REFRESH_TOKEN(state){
		localStorage.removeItem('refresh_token')
		state.refreshToken = null;
	},

	SET_REFRESH_TOKEN_STATE(state, data){
		state.isRefreshTokenState = data;
	},
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}