import api from "../../api/api"

// initial state
const state = () => ({
	data: {}
})

// getters
const getters = {}

// actions
const actions = {
	getProfile({commit}){
		return new Promise((resolve,reject) => {
			api.getProfile().then(response => {
				commit('SET_PROFILE', response.data)
				resolve({'success':response.data});
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	updateProfile({commit},profile){
		return new Promise((resolve,reject) => {
			api.updateProfile(profile).then(response => {
				commit('UPDATE_PROFILE', response.data.data)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	updatePassword({commit},password){
		return new Promise((resolve,reject) => {
			api.updatePassword(password).then(response => {
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},
}

// mutations
const mutations = {
	SET_PROFILE(state, profile){
		state.data = profile;
	},

	UPDATE_PROFILE(state, profile){
		state.data = profile;
	},
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}