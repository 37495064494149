
// initial state
const state = () => ({
	menu: [
		{
			id: 'user-management',
			icon: 'mdi-account-outline',
			title: 'Users',
			link: '/admin/user',
		},
		{
			id: 'banner-management',
			icon: 'mdi-tooltip-image-outline',
			title: 'Banner',
			link: '/admin/banner',
		},
		{
			id: 'program-management',
			icon: 'mdi-clipboard-text-multiple-outline',
			title: 'Programs',
			link: '/admin/program',
		},
		{
			id: 'story-management',
			icon: 'mdi-post-outline',
			title: 'Stories & Blogs',
			link: '/admin/story',
		},
		{
			id: 'gallery-management',
			icon: 'mdi-image-multiple-outline',
			title: 'Galleries',
			link: '/admin/gallery',
		},
		{
			id: 'quote-management',
			icon: 'mdi-comment-quote-outline',
			title: 'Quotes',
			link: '/admin/quote',
		},
		{
			id: 'testimonial-management',
			icon: 'mdi-comment-text-multiple-outline',
			title: 'Testimonials',
			link: '/admin/testimonial',
		},
		{
			id: 'contact-management',
			icon: 'mdi-office-building-marker-outline',
			title: 'Contacts',
			link: '/admin/contact',
		},
	],
})

// getters
const getters = {
	getConfigById: (state) => (id) => {
		function findConfig(object, value) {
			if(object.id && object.id === value){
				return object;
			}
			for(let i = 0; i < object.length; i++ ){
				const found = findConfig(object[i], value);
				if(found){
					return found;
				}

				if(!found && object[i].child){
					const foundChild = findConfig(object[i].child, value)
					if(foundChild){
						return foundChild;
					}
				}
			}
		}
		return findConfig(state.menu, id);
  }
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}