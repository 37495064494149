import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"pt-10 pb-10",attrs:{"fluid":""}},[_c(VRow,{staticClass:"pb-10"},[_c(VCol,{staticClass:"black--text",attrs:{"cols":"1","sm":"2"}}),_c(VCol,{staticClass:"black--text",attrs:{"cols":"10","sm":"8"}},[_c(VRow,[_c(VCol,{staticClass:"text-header fc-teal fw-700 text-center",attrs:{"cols":"12"}},[_vm._v("LIVE INSTAGRAM FEEDS")])],1),_c(VRow,{staticClass:"mt-10",attrs:{"dense":""}},_vm._l((_vm.$vuetify.breakpoint.smAndDown ? 4 : 8),function(cRow){return _c(VCol,{key:cRow,staticStyle:{"aspect-ratio":"1/1"},attrs:{"cols":"6","sm":"6","md":"3"}},[(_vm.instagramFeeds[cRow-1])?_c(VCard,{staticClass:"h-100 w-100",attrs:{"tile":""}},[(_vm.instagramFeeds[cRow-1].media_type === 'IMAGE' || _vm.instagramFeeds[cRow-1].media_type === 'CAROUSEL_ALBUM')?_c(VImg,{attrs:{"eager":"","height":"100%","src":_vm.instagramFeeds[cRow-1].media_url},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{staticClass:"fc-teal",attrs:{"indeterminate":""}})],1)]},proxy:true}],null,true)}):_vm._e(),(_vm.instagramFeeds[cRow-1].media_type === 'VIDEO')?_c('video',{staticStyle:{"object-fit":"fill"},attrs:{"width":"100%","height":"100%","autoplay":"","loop":"","muted":""},domProps:{"muted":true}},[_c('source',{staticClass:"h-100",attrs:{"src":_vm.instagramFeeds[cRow-1].media_url,"type":"video/mp4"}}),_vm._v(" Your browser does not support HTML video. ")]):_vm._e()],1):_vm._e()],1)}),1)],1),_c(VCol,{staticClass:"black--text",attrs:{"cols":"1","sm":"2"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }