import axios from 'axios'
import config from '../../config';
const api = axios.create({
    baseURL: config.WEBAPP_API_URL
})

export default {
  getIgToken(){
    return api.get('get-igtoken');
  },

  setIgToken(id, data){
    return api.patch('set-igtoken/'+id,data);
  },

  getInstagramFeeds(token){
    return axios.get(config.INSTAGRAM_API_URL,{
      params: {
        fields:"id,media_type,media_url,caption,children{media_type,media_url,thumbnail_url}",
        limit:8,
        access_token:token
      }
    })
  },
}