import Vue from 'vue'
import Vuex from 'vuex'

import banner from './webapp/banner';
import about from './webapp/about';
import programs from './webapp/programs';
import quotes from './webapp/quotes';
import stories from './webapp/stories';
import testimonials from './webapp/testimonials';
import galleries from './webapp/galleries';
import contacts from './webapp/contacts';
import feeds from './webapp/feeds';

import admin_config from './admin/admin_config';
import admin_auth from './admin/admin_auth';
import admin_profile from './admin/admin_profile';
import admin_user from './admin/admin_user';
import admin_image from './admin/admin_image';
import admin_banner from './admin/admin_banner';
import admin_program from './admin/admin_program';
import admin_story from './admin/admin_story';
import admin_gallery from './admin/admin_gallery';
import admin_quote from './admin/admin_quote';
import admin_testimonial from './admin/admin_testimonial';
import admin_contact from './admin/admin_contact';

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({

  modules: {
    banner, about, programs, quotes, stories, testimonials, galleries, contacts, feeds,
    admin_config, admin_auth, admin_profile, admin_user, admin_image, admin_banner, admin_program, admin_story, admin_gallery, admin_quote, admin_testimonial, admin_contact
  }

})
