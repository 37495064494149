<template>
	<v-container fluid class="pt-10">

		<ProgramModal ref="programModalRef"></ProgramModal>

		<v-row>
			<v-col cols="12" class="black--text">

				<!-- HEADER -->
				<v-row class="fc-black">
					<v-col cols="1"></v-col>
					<v-col cols="10">
						<v-row>
							<v-col cols="12" class="text-header text-center fw-700 fc-teal">PROGRAMS</v-col>
							<v-col cols="12" class="text-body fw-300 text-justify pb-0">
								Programs of Bali Conservation Adventures are designed to increase awareness of endangered species while at the same time helping the facilities to get better.
								Each of our programs provides insight into the species we fight for and the conservation efforts carried out by the facilities visited.
								The end goal is for participants to understand why it is important to conserve these endangered species, the challenges that they are facing right now and simple actions we can do to help improve their chance of survival.
								Our program venues are not the typical busy tourist attractions. Therefore, you will not be around a large crowd. Activities are conducted mostly in open space where fresh air circulates. However, the covid-19 protocols still have to be adhered to.
								If you cannot find the species that you are interested in or would like to arrange a tailor-made program please feel free to contact us…
							</v-col>
							<v-col cols="12" class="pt-5 text-center text-md-right mt-10 mt-md-0">
								<v-btn dark small class="bg-teal pa-2 h-100" @click="openProgramModal()">
									<v-icon class="mr-2">mdi mdi-alert</v-icon>
									<span class="text-body" style="text-transform:none;">COVID-19 Protocol</span>
								</v-btn>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="1"></v-col>
				</v-row>

				<!-- PROGRAM LIST -->
				<v-row v-for="(carousel, carouselIndex) in Object.keys(programSlider)" :key="carouselIndex" 
					:class="{ 'd-block d-sm-none': carousel === 'oneItemPerSlide', 'd-none d-sm-block d-md-none': carousel === 'twoItemPerSlide',  'd-none d-md-block': carousel === 'threeItemPerSlide'}"
				>
					<v-carousel class="carousel-black" hide-delimiter-background :cycle="$vuetify.breakpoint.xs" :hide-delimiters="$vuetify.breakpoint.smAndUp" :show-arrows="$vuetify.breakpoint.smAndUp" height="100%" prev-icon="mdi-arrow-left" next-icon="mdi-arrow-right">
            <v-carousel-item v-for="(slider,indexSlider) in programSlider[carousel]" :key="indexSlider">
							<v-row>
								<v-col cols="0" sm="1"></v-col>
								<v-col cols="12" sm="10">
									<v-row class="d-flex justify-center">
										<v-col cols="12" sm="6" md="4" class="fc-black pa-5" ref="programList" v-for="(program,indexProgram) in slider" :key="indexProgram">

											<v-hover v-slot:default="{ hover }">
												<v-card class="hover-main" height="600px" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
													<v-img eager height="100%" class="hover-zoom" 
														:src="program.imgError ? program.imgError : program.image && program.image.filename ? imgPath.path+program.image.filename : imgPath.imgEmpty"
														v-on:error="program.imgError = imgPath.imgError"
													>

														<template v-slot:placeholder>
															<v-row class="fill-height ma-0" align="center" justify="center">
																<v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
															</v-row>
														</template>

														<v-flex class="hover-container ma-0 p-absolute d-flex align-center justify-center v-card--reveal white--text mou-caption-1 w-100"
															style="bottom:0;"
														>
															<v-row class="ma-0 pa-5">
																<v-col cols="12" class="d-flex justify-center text-title white--text fw-700 text-uppercase text-center">{{program.name}}</v-col>
																<v-expand-transition>
																	<v-row v-if="hover" align="stretch">
																		<v-col ref="test" v-if="program.brief" cols="12" class="d-flex justify-center opensans text-content white--text text-center px-5 mb-10">
																			{{program.brief}}
																		</v-col>
																		<v-col cols="12" class="d-flex justify-center text-body white--text fw-500">
																			<v-btn tile large class="bg-teal" :href="'/program/'+program.programId">EXPLORE</v-btn>
																		</v-col>
																	</v-row>
																</v-expand-transition>
															</v-row>
														</v-flex>
														
													</v-img>
												</v-card>
											</v-hover>
										</v-col>
									</v-row>
								</v-col>
								<v-col cols="0" sm="1"></v-col>
							</v-row>
						</v-carousel-item>
          </v-carousel>
				</v-row>

				<!-- FOOTER -->
				<v-row class="text-center mt-10">
					<v-col cols="12" class="text-content fw-600">
						<v-btn outlined x-large class="btn-solid" href="/program">SEE MORE</v-btn>
					</v-col>
				</v-row>

			</v-col>

		</v-row>

		<template v-slot:tolol>
			WKWKWKW
		</template>

	</v-container>
</template>

<script>
	import ProgramModal from './ProgramModal';
	import config from '../../../config';
  import { mapState, mapActions, mapGetters } from 'vuex'
	
  export default {
		components: {
      ProgramModal
		},
		
    data: () => ({
			imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
			},
		}),

		computed: {
			...mapState({
				programs: state => state.programs.data,
			}),
      ...mapGetters({
        programSlider: 'programs/getHomeProgramSlider'
      }),
		},

		methods:{
			openProgramModal(){
				this.$refs.programModalRef.open();
			},
    }
  }
</script>