<template>
	<v-container fluid class="pt-10 pb-10">

    <SendMessageModal ref="sendMessageModalRef"></SendMessageModal>

		<v-row>
			<v-col cols="12" class="black--text" v-if="contacts">

				<v-row class="text-left">

          <v-col cols="1"></v-col>

          <v-col cols="10" md="5" lg="4" class="mb-10">
            <v-row class="mb-10">
              <v-col cols="12" class="text-header fw-700 fc-teal">CONTACT US</v-col>
            </v-row>

            <v-row class="mb-5">
              <v-col cols="12" class="fc-black text-subtitle fw-600 pb-0" style="letter-spacing: -1px">EMAIL</v-col>
              <v-col cols="12" class="fc-black" v-for="email in contacts.contacts.emails" :key="email.id" style="word-break: break-all;">
                <v-row class="ma-0">
                  <v-col cols="auto" class="pa-0"><v-icon class="mr-3" :class="email.icon"></v-icon></v-col>
                  <v-col class="pa-0">{{email.address}}</v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="mb-5">
              <v-col cols="12" class="fc-black text-subtitle fw-600 pb-0" style="letter-spacing: -1px">PHONE</v-col>
              <v-col cols="12" class="fc-black" v-for="phone in contacts.contacts.numbers" :key="phone.id">
                <v-row class="ma-0">
                  <v-col cols="auto" class="pa-0"><v-icon class="mr-3" :class="phone.icon"></v-icon></v-col>
                  <v-col class="pa-0">{{phone.number}} {{phone.detail}}</v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="fc-black text-subtitle fw-600 pb-0" style="letter-spacing: -1px">SOCIAL MEDIA</v-col>
              <v-col cols="12" class="fc-black" v-for="social in contacts.contacts.socials" :key="social.id">
                <v-row class="ma-0">
                  <v-col cols="auto" class="pa-0"><v-icon class="mr-3" :class="social.icon"></v-icon></v-col>
                  <v-col class="pa-0"><a class="fc-black" :href="social.url" target="_blank">{{social.account}}</a></v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6" lg="7" class="mt-10 mt-md-0">
            <v-form ref="form" v-model="isFormValid" lazy-validation>

              <v-row class="h-100 p-relative">
                <v-col class="d-flex justify-center" cols="12" md="11" lg="8" style="z-index:1;">
                  <v-card class="pa-10 contact-hover">

                    <v-row>
                      <v-col cols="12" class="pb-0 fc-black text-quote fw-600">Name</v-col>
                      <v-col cols="12" class="pt-0">
                        <v-text-field :disabled="isLoadingSubmit" v-model="formData.name" :rules="nameRules" placeholder="Your Name Here" required></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="pb-0 oswald fc-black text-quote fw-600">Email</v-col>
                      <v-col cols="12" class="pt-0">
                        <v-text-field :disabled="isLoadingSubmit" v-model="formData.email" :rules="emailRules" placeholder="Your Email Here" required></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="pb-0 oswald fc-black text-quote fw-600">How did you discover us?</v-col>
                      <v-col cols="12" class="pt-0">
                        <v-select :disabled="isLoadingSubmit" v-model="formData.discover" :rules="discoverRules" placeholder="Friend / Colleague" :items="['Friend / Colleague','Internet','Other']"></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="pb-0 oswald fc-black text-quote fw-600">Type Your Message</v-col>
                      <v-col cols="12" class="pt-0">
                        <v-textarea :disabled="isLoadingSubmit" v-model="formData.text" :rules="messageRules" placeholder="Type Your Message"></v-textarea>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" class="d-flex oswald white--text fw-500 mt-5">
                        <vue-recaptcha
                          ref="invisibleRecaptcha"
                          size="invisible"
                          @verify="onVerify"
                          @expired="onExpired"
                          :sitekey="captchaKey"
                        >
                        </vue-recaptcha>
                        <v-btn :disabled="isLoadingSubmit" tile class="bg-teal" :dark="!isLoadingSubmit"  width="120" @click="actionSend()">SEND</v-btn>
                      </v-col>
                    </v-row>

                  </v-card>
                </v-col>

                <v-col cols="2">
                  <div class="p-absolute" style="height:900px;width:100%;right:0;top:0">
                    <div class="h-100 w-100 p-absolute" style="top:-70px;">
                      <v-img height="100%" eager :src="require('@/assets/assets/dog6.jpg')">
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>
                  </div>
                </v-col>
              </v-row>

            </v-form>
          </v-col>

				</v-row>

			</v-col>
		</v-row>

	</v-container>
</template>

<script>
  import { VueRecaptcha } from 'vue-recaptcha';
  import SendMessageModal from './SendMessageModal';
  import config from '../../../config';
  import { mapState, mapActions } from 'vuex'

  export default {
    components: {SendMessageModal, VueRecaptcha},

    data: () => ({
			imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },

      isLoadingSubmit: false,
      isFormValid: true,
      formData: {
        name: null,
        email: null,
        discover: null,
        text: null
      },

      captchaKey: config.CAPTCHA_KEY,

      nameRules: [ v => (!!v) || 'Name is required', v => (v && !!v.trim()) || 'Name is required'],
      emailRules: [ v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
      discoverRules: [ v => !!v || 'Discover is required'],
      messageRules: [ v => !!v || 'Testimonial is required', v => (v && !!v.trim()) || 'Testimonial is required'],
		}),
		
		computed: mapState({
			contacts: state => state.contacts.data,
		}),

    mounted(){
      this.initVariable();
    },

    methods: {
      initVariable(){
        this.formData.name = null;
        this.formData.email = null;
        this.formData.discover = null;
        this.formData.text = null;
      },

      onVerify(response) {
        // console.log('Verify: ' + response)
      },

      onExpired() {
        // console.log('Expired')
      },
      
      actionSend(){
        this.$refs.invisibleRecaptcha.execute();
        this.isLoadingSubmit = true;
        if(this.$refs.form.validate()){
          this.$store.dispatch('contacts/sendMessage', this.formData).then((data) => {
            this.isLoadingSubmit = false;
            if(data.errors){
              this.callSnackbar('error',data.errors);
              return;
            }
            this.$refs.sendMessageModalRef.open();
          });
          return;
        }
        this.$refs.invisibleRecaptcha.reset();
        this.isLoadingSubmit = false;
      },
		}
  }
</script>