<template>
	<v-main class="w-100 pa-0 bg-yellow">
    <!-- SLIDER -->
    <v-container fluid fill-height class="pa-0 white" style="height:100vh;">
      <v-carousel cycle hide-delimiters height="100%" class="carousel-white" prev-icon="mdi-arrow-left" next-icon="mdi-arrow-right">
        <v-carousel-item v-for="(gallery) in getGalleryProgram" :key="gallery.id">
          <v-img class="h-100" eager 
            :src="gallery.imgError ? gallery.imgError : gallery.image && gallery.image.filename ? imgPath.path+gallery.image.filename : '/'"
            v-on:error="gallery.imgError = imgPath.imgError"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-container>

    <!-- ABOUT -->
    <v-container fluid>

      <v-row>
        <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="2"></v-col>

        <v-col cols="12" sm="10" md="10" lg="8" class="fc-black p-relative">
          <v-card>
            <v-row style="margin-top:-90px;">
              <v-col cols="12" class="pa-10 fc-black" style="opacity:.9;min-height:400px">
                <v-row>
                  <v-col cols="12" class="text-header fw-700 fc-teal px-10 text-center text-md-left">OUR PROGRAMS</v-col>
                  <v-col cols="12" lg="10" class="text-body fw-300 text-justify fc-black px-10">
                    Programs of Bali Conservation Adventures are designed to increase awareness of endangered species while at the same time helping the facilities to get better. Each experience provides insight into the species we fight for and the conservation efforts carried out by the facilities visited. The end goal is for participants to understand why it is important to conserve these endangered species, the challenges that they are facing right now and simple actions we can do to help improve their chance of survival.
                  </v-col>
                  <v-col cols="12" lg="10" class="fw-300 text-center px-10 d-flex justify-center">
                    <i><a href="/#contact" class="fw-500 text-body" style="color:#005457;text-transform:none;">Please contact us directly for prices</a></i>
								    <!-- <v-btn text href="/#contact"><span class="fw-500 text-body" style="color:#005457;text-transform:none;"><i>Please contact us directly for prices</i></span></v-btn> -->
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>

          <v-card v-if="$vuetify.breakpoint.lgAndUp" class="p-absolute pa-7" style="bottom:-50px;right:-125px;min-height: 300px;width:300px">
            <v-row class="w-100 h-100">
              <v-col cols="12" class="fc-teal fw-700" style="font-size: 1.2rem;">
                EXPLORE
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-list flat>
                  <v-list-item :ripple="false" v-for="(program) in getProgramExplore(null)" :key="program.id" class="pa-0 fc-black opensans text-content mb-3" :href="'/program/'+program.programId" 
                    style="letter-spacing:.5px"
                  >
                    <v-icon class="mr-2">mdi mdi-chevron-right</v-icon> {{program.name}}
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card>

        </v-col>

        <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="2"></v-col>
      </v-row>

    </v-container>

    <v-container fluid class="bg-primary mt-0 mt-sm-10">
      <v-row class="mt-10">
        <v-col cols="1" lg="2"></v-col>

        <v-col cols="12" sm="10" lg="8">
          <v-card v-for="(program) in programs" :key="program.id" :class="$vuetify.breakpoint.smAndUp ? 'mb-10 hover-zoom' : 'mb-10'" :style="$vuetify.breakpoint.mdAndUp ? 'height:350px' : ''">
            <v-row class="ma-0">

              <v-col cols="12" md="4" class="pa-0 p-relative" :style="$vuetify.breakpoint.mdAndUp ? 'height:350px;z-index:1;' : 'height:350px'">
                <v-img height="100%" eager
                  :src="program.imgError ? program.imgError : program.image && program.image.filename ? imgPath.path+program.image.filename : imgPath.imgEmpty"
                  v-on:error="program.imgError = imgPath.imgError"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>

              <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="1" class="p-relative pa-0" style="z-index:2;">
                <div class="bg-skew w-100 pa-0"></div>
              </v-col>

              <v-col cols="12" md="7" class="pa-7 pl-7 pl-md-0" :style="$vuetify.breakpoint.mdAndUp ? 'height:350px;z-index:3;' : 'height:350px;'">
                <v-flex class="h-100 d-flex flex-column">

                  <v-flex class="pa-2 fc-teal text-title fw-700" style="flex: 0 0 auto;">
                    {{program.name}}
                  </v-flex>

                  <v-flex class="pa-2 text-body fw-300 text-justify fc-black overflow-hidden">
                    <span>{{program.brief ? program.brief : 'No Description'}}</span>
                  </v-flex>

                  <v-flex class="pa-2 d-flex align-end justify-end" style="flex: 0 0 auto;">
                    <v-btn dark tile class="bg-teal" :href="'/program/'+program.programId">EXPLORE</v-btn>
                  </v-flex>

                </v-flex>

              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="1" lg="2"></v-col>
      </v-row>

      <v-row class="mt-10 d-flex justify-center">
        <v-col cols="auto" class="justify-center mb-10">
          <v-card rounded x-large dark class="text-description fw-400 text-center bg-teal mailtoui" href="mailto:info@baliconservationadventures.com?subject=Hello"
            target="_top"
            style="text-transform:none;background:rgba(245, 245, 245, .7);height:100%;border-radius:25px!important;"
          >
            <v-row class="ma-0 pa-5">
              <v-col cols="12" class="pa-0">
                If you cannot find the species that you are interested in or would <span v-if="$vuetify.breakpoint.mdAndUp"><br/></span>
                like to arrange a tailor-made program please feel free to contact us
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

    </v-container>

  </v-main>

</template>

<script>
  import config from '../../../config';
  import MailtoUI from '../../plugins/mailtoui';
  import { mapState, mapActions, mapGetters } from 'vuex'
	
  export default {
    metaInfo: {
      title: 'Bali Conservation Adventures | Program',
      meta: [
        { name: 'description', content: 'Bali Conservation Adventures is an organization formed to support wildlife conservation in Bali through tourism.'},
        { property: 'og:locale', content: "en_US"},
        { property: 'og:type', content: 'website'},    
        { property: 'og:title', content: "Bali Conservation Adventures | Program"},
        { property: 'og:description', content: 'Bali Conservation Adventures is an organization formed to support wildlife conservation in Bali through tourism.'},
        { property: 'og:url', content: 'https://www.baliconservationadventures.com/program'},
        { property: 'og:image', content: 'https://baliconservationadventures.com/assets/logo/logo.png'},
        { property: 'og:site_name', content: 'Bali Conservation Adventures'},
        { name: 'robots', content: 'index,follow'} 
      ],
      link: [
        {rel: 'canonical', href: 'https://www.baliconservationadventures.com/progam'}
      ]
    },

    data: () => ({
			imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
		}),

		computed: {
			...mapState({
				galleries: state => state.galleries.data,
				programs: state => state.programs.data,
			}),
      ...mapGetters({
        getGalleryProgram: 'galleries/getGalleryProgram',
        getProgramExplore: 'programs/getProgramExplore'
      }),
		},

    mounted(){
			this.getGallery();
      this.getProgram();
      this.getStory();
      MailtoUI.run();
		},

		methods:{
			...mapActions({
        getGallery: 'galleries/getGalleryHome',
        getProgram: 'programs/getProgram',
        getStory: 'stories/getStory'
      }),
    }
  }
</script>