import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"scrollable":"","width":"900"},model:{value:(_vm.isOpened),callback:function ($$v) {_vm.isOpened=$$v},expression:"isOpened"}},[_c(VCard,[_c(VCardTitle,{staticClass:"bg-teal white--text"},[_c(VRow,[_c(VCol,[_vm._v("Why Book Bali Conservation Adventures?")]),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.isOpened = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1)],1),_c(VCardText,{staticClass:"pt-5"},_vm._l((_vm.items),function(item,i){return _c(VRow,{key:i,staticClass:"pr-5 pl-3 mb-5"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{staticClass:"ma-0"},[_c(VCol,{staticClass:"pa-0 text-center",attrs:{"cols":"2","sm":"1"}},[_c(VImg,{attrs:{"eager":"","src":require('@/assets/check.png'),"height":"40","width":"40"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{staticClass:"fc-teal",attrs:{"indeterminate":""}})],1)]},proxy:true}],null,true)})],1),_c(VCol,{staticClass:"pa-0 fc-teal d-flex align-center",attrs:{"cols":"10","sm":"11"}},[_c('span',{staticClass:"segoesc fw-500",staticStyle:{"font-size":"1.25rem"}},[_vm._v(_vm._s(item.title))]),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(item.subtitle))])])],1),_c(VRow,{staticClass:"ma-0 pt-3"},[(_vm.$vuetify.breakpoint.smAndUp)?_c(VCol,{staticClass:"pa-0",attrs:{"cols":"1"}}):_vm._e(),_c(VCol,{staticClass:"pa-0 text-body text-justify text-content fw-300",attrs:{"cols":"12","sm":"11"}},[_vm._v(_vm._s(item.description))])],1)],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }