<template>

  <v-dialog v-model="isFormOpened" persistent max-width="600px">

    <v-snackbar :color="snackbar.color" v-model="snackbar.show" timeout="5000">
      <span>{{snackbar.message}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-form ref="form" v-model="isFormValid" lazy-validation>
      <v-card v-if="isFormOpened">

        <!-- TOOLBAR -->
        <v-toolbar dark class="bg-teal">
          <!-- TITLE -->
          <v-toolbar-title>Upload {{type === 'image' ? 'Image' : 'Video'}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- CLOSE BUTTON -->
          <v-btn :disabled="isLoadingSubmit" icon dark @click="isFormOpened = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-progress-linear :style="isLoadingSubmit ? '' : 'visibility:hidden'" indeterminate color="secondary"></v-progress-linear>

        <!-- FORM INPUT -->
        <!-- <v-card-text>
          <v-container>
            <v-row>

              <v-col cols="12" class="d-flex justify-center">
                <v-hover v-slot:default="{ hover }">
                  <div class="d-flex cursor-pointer p-relative w-100" @click="openImageUpload()">

                    <v-flex v-if="type === 'image' || (type === 'video' && !formData.imgObj)">
                      <v-img height="350px" width="100%" contain
                        :src="isImageError ? imgPath.imgError : formData.imgSrc"
                        :lazy-src="imgPath.lazyPath"
                        v-on:error="isImageError = true"
                      >
                      </v-img>
                    </v-flex>

                    <v-flex v-if="type === 'video' && formData.imgObj">
                      <video width="100%" controls>
                        <source class="h-100" :src="formData.imgObj" type="video/mp4">
                        Your browser does not support HTML video.
                      </video>
                    </v-flex>

                    <div class="p-absolute d-flex w-100 h-100 justify-center align-center" v-if="hover">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn fab small dark color="blue-grey" class="mr-1" v-bind="attrs" v-on="on">
                              <v-icon>mdi mdi-image-search</v-icon>
                          </v-btn>
                        </template>
                        <span>Change {{type === 'image' ? 'Image' : 'Video'}}</span>
                      </v-tooltip>
                    </div>

                  </div>
                </v-hover>
              </v-col>

              <v-col cols="12" class="pa-0 pt-3">
                <v-row class="ma-0">

                  <v-col cols="12" class="pa-0" v-if="type === 'image'">
                    <v-file-input :disabled="isLoadingSubmit" class="imageInput" ref="imageInput" 
                      v-on:change="onImagePicked($event)" 
                      :rules="[
                        v => (formData.imgId ? true : (!!v)) || 'Image is required',
                        v => !v || v.size < 10000000 || 'Image size should be less than 10 MB!'
                      ]"
                      accept="image/png, image/jpeg, image/jpg"
                    ></v-file-input>
                  </v-col>

                  <v-col cols="12" class="pa-0" v-if="type === 'video'">
                    <v-file-input :disabled="isLoadingSubmit" class="imageInput" ref="imageInput" 
                      v-on:change="onImagePicked($event)" 
                      :rules="[
                        v => (formData.imgId ? true : (!!v)) || 'Video is required',
                        v => !v || v.size < 50000000 || 'Video size should be less than 50 MB!'
                      ]"
                      accept="video/mp4"
                    ></v-file-input>
                  </v-col>

                </v-row>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text> -->

          <v-card-text>
            <v-container>
              <v-row v-if="type === 'image'">
                <v-file-input :disabled="isLoadingSubmit" counter prepend-icon="mdi-camera" ref="imageInput" v-on:change="onImagePicked($event)" 
                  :show-size="1000"
                  :rules="[
                    v => !!v || 'Image is required',
                    v => !v || v.size < 10000000 || 'Image size should be less than 10 MB!'
                  ]"
                  accept="image/png, image/jpeg, image/jpg"
                >
                </v-file-input>
              </v-row>

              <v-row v-if="type === 'video'">
                <v-file-input :disabled="isLoadingSubmit" counter prepend-icon="mdi-video" ref="videoInput" v-on:change="onImagePicked($event)" 
                  :show-size="1000"
                  :rules="[
                    v => !!v || 'Video is required',
                    v => !v || v.size < 50000000 || 'Video size should be less than 50 MB!'
                  ]"
                  accept="video/mp4"
                >
                </v-file-input>
              </v-row>
            </v-container>
          </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="isLoadingSubmit" color="grey darken-1" text @click="isFormOpened = false;">Cancel</v-btn>
          <v-btn :disabled="isLoadingSubmit" class="fc-teal" text @click="formSave()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>

</template>

<script>
  import config from '../../../config';
  export default {

    data: () => ({
      imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },

      refreshImage: '',
      action: null,
      type: null,
      isImageError: false,
      isFormOpened: false,
      isFormValid: true,
      isLoadingSubmit: false,
      snackbar: { show: false, color: '', message: ''},

      file: null
    }),

    methods: {
      initVariable(){
        this.action = 'add';
        this.file = null;
      },

      openForm(type){
        this.type = type;
        this.initVariable();
        this.isFormOpened = true;
      },

      formSave(){
        this.isLoadingSubmit = true;
        if(this.$refs.form.validate()){
          const target = (this.type === 'image') ? 'admin_image/addImage' :  'admin_image/addVideo';
          this.$store.dispatch(target, this.file).then((data) => {
            this.isLoadingSubmit = false;
            if(data.errors){
              this.callSnackbar('error',data.errors);
              return;
            }
            this.$emit('formImageSaved',data)
            this.isFormOpened = false;  
          });
          return;
        }

        this.callSnackbar('error','Please fill all the required field');
        this.isLoadingSubmit = false;
      },

      openImageUpload(){
        this.$refs.imageInput.$children[0].$el.click();
      },

      onImagePicked(event){
        if(event){
          this.file = new FormData();
          this.file.append('file', event);
        }
      },

      callSnackbar(color,message){
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
      }
    },

  }
</script>