<template>
  <v-main class="w-100 h-100 pa-0 bg-yellow" v-resize="onResize">
    <v-row class="overflow-hidden p-relative h-100 ma-0" style="padding-top:90px;">

      <v-col cols="1"></v-col>

      <v-col cols="10">
        <v-row>
          <v-col cols="12">
            <v-tabs class="px-3" background-color="bg-yellow" color="teal lighten-1" v-model="tab">
              <v-tab>Stories</v-tab>
              <v-tab>Blogs</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" class="bg-yellow">

              <!-- STORIES -->
              <v-tab-item>
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" sm="6" md="4" lg="3" v-for="story in storyList" :key="story.id">
                      <v-card class="overflow-hidden">
                        <v-row class="ma-0">
                          <v-col cols="12" class="pa-0 overflow-hidden">
                            <v-img class="white--text align-end hover-zoom" height="250px" eager
                              :src="story.imgError ? story.imgError : story.image && story.image.filename ? imgPath.path+story.image.filename : imgPath.imgEmpty"
                              v-on:error="story.imgError = imgPath.imgError"
                            ></v-img>
                          </v-col>
                          <v-col cols="12" class="px-5 overflow-hidden text-menu fc-teal fw-600">{{story.name}}</v-col>
                          <v-col cols="12" class="px-5 overflow-hidden text-content fc-black fw-300 text-ellipsis-story" style="height:110px;max-height:110px;">
                            {{story.brief ? story.brief : 'No Description'}}
                          </v-col>
                          <v-col cols="12" class="d-flex justify-end"><v-btn class="fc-teal" text :href="'/story/'+story.storyId">Read More</v-btn></v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <!-- BLOGS -->
              <v-tab-item>
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" sm="6" md="4" lg="3" v-for="blog in blogList" :key="blog.id">
                      <v-card class="overflow-hidden">
                        <v-row class="ma-0">
                          <v-col cols="12" class="pa-0 overflow-hidden">
                            <v-img class="white--text align-end hover-zoom" height="250px" eager
                              :src="blog.imgError ? blog.imgError : blog.image && blog.image.filename ? imgPath.path+blog.image.filename : imgPath.imgEmpty"
                              v-on:error="blog.imgError = imgPath.imgError"
                            ></v-img>
                          </v-col>
                          <v-col cols="12" class="px-5 overflow-hidden text-menu fc-teal fw-600">{{blog.name}}</v-col>
                          <v-col cols="12" class="px-5 overflow-hidden text-content fc-black fw-300 text-ellipsis-story" style="height:110px;max-height:110px;">
                            {{blog.brief ? blog.brief : 'No Description'}}
                          </v-col>
                          <v-col cols="12" class="d-flex justify-end"><v-btn class="fc-teal" text :href="'/story/'+blog.storyId">Read More</v-btn></v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

            </v-tabs-items>

          </v-col>
        </v-row>
      </v-col>

      <v-col cols="1"></v-col>

    </v-row>
  </v-main>
</template>

<script>
	import config from '../../../config';
  import { mapState, mapActions, mapGetters } from 'vuex'
	
  export default {
    metaInfo: {
      title: 'Bali Conservation Adventures | Story',
      meta: [
        { name: 'description', content: 'Bali Conservation Adventures is an organization formed to support wildlife conservation in Bali through tourism.'},
        { property: 'og:locale', content: "en_US"},
        { property: 'og:type', content: 'website'},    
        { property: 'og:title', content: "Bali Conservation Adventures | Story"},
        { property: 'og:description', content: 'Bali Conservation Adventures is an organization formed to support wildlife conservation in Bali through tourism.'},
        { property: 'og:url', content: 'https://www.baliconservationadventures.com/story'},
        { property: 'og:image', content: 'https://baliconservationadventures.com/assets/logo/logo.png'},
        { property: 'og:site_name', content: 'Bali Conservation Adventures'},
        { name: 'robots', content: 'index,follow'} 
      ],
      link: [
        {rel: 'canonical', href: 'https://www.baliconservationadventures.com/story'}
      ]
    },

    data: () => ({
			imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
      tab: 0,
      storyList: [],
      blogList: []
    }),
    
    computed: {
			...mapState({
				storiesAndBlogs: state => state.stories.data,
			}),
		},

    mounted(){
      this.getStory().then((data) => {
        if(!data.success){
          return;
        }

        const storiesAndBlogs = data.success;
        this.storyList = storiesAndBlogs.filter((data) => data.type === 1);
        this.blogList = storiesAndBlogs.filter((data) => data.type === 2);
      });
      this.onResize();
		},

		methods:{
			...mapActions({
        getStory: 'stories/getStory'
      }),
      onResize () {
				const elements = document.getElementsByClassName("text-ellipsis-story");

				if(elements && elements.length > 0){
					for(let i=0;i<elements.length;i++){
						const isOverflowing = elements[i].clientHeight < elements[i].scrollHeight;
						if(isOverflowing){
							elements[i].classList.add("is-ellipsis");
						}else{
							elements[i].classList.remove("is-ellipsis");
						}
					}
				}
			},
    }
  }
</script>