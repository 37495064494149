<template>
  <v-main class="h-100 w-100 pa-0 bg-yellow">

    <v-container fluid class="bg-primary" style="margin-top:90px;" v-if="about.whoweare && about.whatwedo">

      <v-row>
        <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1"></v-col>

        <v-col cols="12" sm="10" md="10">
          <v-row>

            <v-col cols="12" md="6">
              <v-img eager :src="require('@/assets/assets/'+about.whoweare.image)" class="h-100">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>

            <v-col cols="12" md="6" class="pa-5 pa-sm-10">
              
              <v-row class="ma-0">
                <v-col cols="12" class="text-header fw-700 fc-teal text-center text-md-left">WHO WE ARE</v-col>
                <v-col cols="12" class="text-body fw-300 text-justify fc-black ws-break-spaces">{{about.whoweare.description}}</v-col>
              </v-row>
              
            </v-col>
          </v-row>
        </v-col>

        <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1"></v-col>
      </v-row>

      <v-row class="mt-5">
        <v-col cols="1"></v-col>
        <v-col cols="10"><v-row></v-row><hr/></v-col>
        <v-col cols="1"></v-col>
      </v-row>

      <v-row id="whatwedo">
        <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1"></v-col>

        <v-col cols="12" sm="10" md="10">
          <v-row>

            <v-col cols="12" md="6" class="pa-10">
              <v-row>
                <v-col cols="12" class="text-header fw-700 fc-teal text-center text-md-left">WHAT WE DO</v-col>
                <v-col cols="12" class="text-body fw-300 text-justify fc-black ws-break-spaces">{{about.whatwedo.description}}</v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="6">
              <v-img eager :src="require('@/assets/assets/'+about.whatwedo.image)" class="h-100">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>

          </v-row>
        </v-col>

        <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1"></v-col>
      </v-row>

    </v-container>
  </v-main>
</template>

<script>
  import config from '../../../config';
  import { mapState, mapActions } from 'vuex'

  export default {
    metaInfo: {
      title: 'Bali Conservation Adventures | About',
      meta: [
        { name: 'description', content: 'Bali Conservation Adventures is an organization formed to support wildlife conservation in Bali through tourism.'},
        { property: 'og:locale', content: "en_US"},
        { property: 'og:type', content: 'website'},    
        { property: 'og:title', content: "Bali Conservation Adventures | About"},
        { property: 'og:description', content: 'Bali Conservation Adventures is an organization formed to support wildlife conservation in Bali through tourism.'},
        { property: 'og:url', content: 'https://www.baliconservationadventures.com/about'},
        { property: 'og:image', content: 'https://baliconservationadventures.com/assets/logo/logo.png'},
        { property: 'og:site_name', content: 'Bali Conservation Adventures'},
        { name: 'robots', content: 'index,follow'} 
      ],
      link: [
        {rel: 'canonical', href: 'https://www.baliconservationadventures.com/about'}
      ]
    },

    data: () => ({
			imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
		}),
		
		computed: mapState({
			about: state => state.about.data,
		}),

    mounted(){
      this.getAbout();
      this.getProgram();
      this.getStory();
    },

    methods: {
      ...mapActions({
        getAbout: 'about/getAbout',
        getProgram: 'programs/getProgram',
        getStory: 'stories/getStory'
			}),
		}
  }
</script>
