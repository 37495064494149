<template>
  <v-container fluid class="pa-0" v-if="about && about.whoweare">

    <v-row class="ma-0" v-resize="onResize">

      <v-col cols="1" md="2" lg="2" xl="2" class="d-none d-sm-block"></v-col>

			<v-col cols="12" sm="10" md="8" lg="8" xl="8" class="black--text pa-0">

				<v-row class="mx-0 pt-0 pt-md-10 mt-0 mt-lg-10">
					<v-col cols="12" lg="6" class="pa-0 overflow-hidden order-1 order-md-0" v-if="$vuetify.breakpoint.lgAndUp">
						<v-img eager :src="require('@/assets/assets/'+about.whoweare.image)" class="h-100 hover-zoom">
							<template v-slot:placeholder>
								<v-row class="fill-height ma-0" align="center" justify="center">
									<v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
								</v-row>
							</template>
						</v-img>
					</v-col>
					<v-col cols="12" lg="6"  class="py-10 px-md-10 white fc-black d-flex align-xl-center" style="opacity:.9;">
						<v-row>
							<v-col cols="12" class="oswald text-subheader fw-700 px-10 text-center text-lg-left fc-teal">
								WHO WE ARE
							</v-col>
							<v-col cols="12" class="opensans text-body px-10 fw-300 text-justify text-ellipsis overflow-hidden" style="max-height:400px">{{about.whoweare.description}}</v-col>
							<v-col cols="12" class="oswald text-content fw-600 px-10 text-center text-lg-left">
								<v-btn outlined large class="btn-solid" href="/about">Read More<v-icon>mdi-chevron-right</v-icon></v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>

        <v-row class="ma-0" v-if="$vuetify.breakpoint.mdAndDown">
          <v-col cols="6" class="pa-0 overflow-hidden">
            <v-img eager :src="require('@/assets/assets/'+about.whoweare.image)" class="h-100 hover-zoom">
							<template v-slot:placeholder>
								<v-row class="fill-height ma-0" align="center" justify="center">
									<v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
								</v-row>
							</template>
						</v-img>
          </v-col>
          <v-col cols="6" class="pa-0 overflow-hidden">
            <v-img eager :src="require('@/assets/assets/'+about.whatwedo.image)" class="h-100 hover-zoom">
							<template v-slot:placeholder>
								<v-row class="fill-height ma-0" align="center" justify="center">
									<v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
								</v-row>
							</template>
						</v-img>
          </v-col>
        </v-row>

				<v-row style="min-height:500px;" class="ma-0">
					<v-col cols="12" lg="6" class="white py-10 px-md-10 fc-black order-1 order-md-0 d-flex align-xl-center">
						<v-row>
							<v-col cols="12" class="oswald text-subheader fw-700 px-10 text-center text-lg-left fc-teal">
								WHAT WE DO
							</v-col>
							<v-col cols="12" class="opensans text-body px-10 fw-300 text-justify text-ellipsis overflow-hidden" style="max-height:400px;">{{about.whatwedo.description}}</v-col>
							<v-col cols="12" class="oswald text-content fw-600 px-10 text-center text-lg-left">
								<v-btn outlined large class="btn-solid" href="/about#whatwedo">Read More<v-icon>mdi-chevron-right</v-icon></v-btn>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" lg="6" class="pa-0 overflow-hidden" v-if="$vuetify.breakpoint.lgAndUp">
						<v-img eager :src="require('@/assets/assets/'+about.whatwedo.image)" class="h-100 hover-zoom">
							<template v-slot:placeholder>
								<v-row class="fill-height ma-0" align="center" justify="center">
									<v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
								</v-row>
							</template>
						</v-img>
					</v-col>
				</v-row>

			</v-col>

      <v-col cols="1" md="2" lg="2" xl="2" class="d-none d-sm-block"></v-col>


    </v-row>

  </v-container>
</template>

<script>
  import config from '../../../config';
  import { mapState, mapActions } from 'vuex'

  export default {
    data: () => ({
			imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
		}),
		
		computed: mapState({
			about: state => state.about.data,
		}),

    mounted(){
			this.onResize();
    },

    methods: {
			onResize () {
				const elements = document.getElementsByClassName("text-ellipsis");

				if(elements && elements.length > 0){
					for(let i=0;i<elements.length;i++){
						const isOverflowing = elements[i].clientHeight < elements[i].scrollHeight;
						if(isOverflowing){
							elements[i].classList.add("is-ellipsis");
						}else{
							elements[i].classList.remove("is-ellipsis");
						}
					}
				}
			},
			
		}
  }
</script>
