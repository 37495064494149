import api from '../../api/webapp-api';

// initial state
const state = () => ({
	data: null,
})

// getters
const getters = {}

// actions
const actions = {
	getContact({commit}){
		return new Promise((resolve,reject) => {
			api.getContact().then(response => {
				commit('SET_CONTACT', response.data)
				resolve({'success':response.data});
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	sendMessage({commit},message){
		return new Promise((resolve,reject) => {
			api.sendMessage(message).then(response => {
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},
}

// mutations
const mutations = {
	SET_CONTACT(state, contact){
		state.data = contact;
	},
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}