import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"scrollable":"","width":"900"},model:{value:(_vm.isOpened),callback:function ($$v) {_vm.isOpened=$$v},expression:"isOpened"}},[_c(VCard,[_c(VCardTitle,{staticClass:"bg-teal white--text"},[_vm._v(" COVID-19 Protocol "),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.isOpened = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"pa-10"},[_c(VRow,[_c(VCol,{staticClass:"text-justify text-sm-center text-body",attrs:{"cols":"12"}},[_vm._v(" Our programs’ venues are not the typical busy tourist attractions. Therefore, you will not be around a large crowd. Activities are conducted mostly in open space where fresh air circulates. However, the covid-19 protocols still have to be adhered to. ")])],1),_c(VRow,{staticClass:"my-0 my-sm-10"},[_c(VCol,{attrs:{"cols":"0","sm":"1","md":"2"}}),_c(VCol,{attrs:{"cols":"12","sm":"10","md":"8"}},[_c(VImg,{attrs:{"height":"100%","eager":"","src":require('@/assets/program-covid.png')},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{staticClass:"fc-teal",attrs:{"indeterminate":""}})],1)]},proxy:true}])})],1),_c(VCol,{attrs:{"cols":"0","sm":"1","md":"2"}})],1),_c(VRow,[_c(VCol,{staticClass:"text-justify text-sm-center text-body",attrs:{"cols":"12"}},[_vm._v(" Should you require more information regarding this, "),(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_c('br')]):_vm._e(),_vm._v(" please do not hesitate to contact us. ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }