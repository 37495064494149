<template>
  <v-container fill-height fluid class="px-sm-7 pb-7">
    
    <ImageManager ref="imageManager" @onImageSelected="onImageSelected"></ImageManager>

    <v-snackbar :color="snackbar.color" v-model="snackbar.show" timeout="5000">
      <span>{{snackbar.message}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-row class="h-100 justify-center align-self-start">
      <v-col  cols="12" sm="12" md="10" class="h-100">
        <v-layout column class="h-100">
      
          <!-- TITLE -->
          <v-row style="height:8%;">
            <v-col cols="6">
              <span class="title d-flex"><v-icon class="mr-2">mdi-tooltip-image-outline</v-icon>Banner</span>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn :disabled="isLoadingData" small :dark="!isLoadingData" color="bg-teal" @click="openImageManager('update',banner && banner.image)">
                <v-icon left small>mdi-pencil</v-icon>Change Banner
              </v-btn>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <v-progress-linear :style="isLoadingData ? '' : 'visibility:hidden'" indeterminate color="bg-teal"></v-progress-linear>

          <!-- CONTENT -->
          <v-row style="height:92%">
            <v-col cols="12" class="h-100">
              <v-img height="100%" 
              :src="banner.imgError ? banner.imgError : banner.image && banner.image.filename ? imgPath.path+banner.image.filename+refreshImage : imgPath.imgEmpty"
              :lazy-src="imgPath.lazyPath"
              v-on:error="banner.imgError = imgPath.imgError"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            </v-col>
          </v-row>

        </v-layout>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import config from '../../../config';
  import { mapState, mapActions } from 'vuex'
  import ImageManager from '../../components/AdminApp/ImageManager';
  import DialogDeleteConfirmation from '../../components/AdminApp/DialogDeleteConfirmation';

  export default {
    metaInfo: {
      title: 'Bali Conservation Adventures',
    },

    components: {
      ImageManager,
      DialogDeleteConfirmation
    },

    data: () => ({
      isLoadingData: false,
      snackbar: { show: false, color: '', message: ''},
      refreshImage: '',
      imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
    }),

    mounted(){
      this.$store.dispatch('admin_banner/getBanner');
    },

    computed: {
      ...mapState({
        banner: state => state.admin_banner.data,
      }),
    },

    methods: {
      actionRefreshImage(){
        const now = Date.now();
        this.refreshImage = '?'+now;
      },

      openImageManager: function (action,currentImage){
        this.$refs.imageManager.openImageManager(action,'image',currentImage);
      },

      onImageSelected(response){
        if(response.action === 'update'){
          this.updateData(response.selectedImage)
        }
      },

      openDialogDeleteConfirmation: function (id, name){
        this.$refs.dialogDeleteConfirmation.openDialog(id, name);
      },

      updateData(data){
        const updateData = {image_id: data.id};
        this.isLoadingData = true;
        this.$store.dispatch('admin_banner/updateBanner', updateData).then((data) => {
          this.isLoadingData = false;
          if(data.errors){
            this.callSnackbar('error',data.errors);
            return;
          }
          this.actionRefreshImage();
        });
      },

      callSnackbar(color,message){
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
      }
    }

  }
</script>