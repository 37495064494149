<template>
  <v-container fill-height fluid class="overflow-auto px-sm-7 pb-7">

    <FormStory ref="formStory" @formStorySaved="createUpdateData"></FormStory>
    <DialogDeleteConfirmation ref="dialogDeleteConfirmation" @dialogDeleteConfirmed="deleteData"></DialogDeleteConfirmation>

    <v-snackbar :color="snackbar.color" v-model="snackbar.show" timeout="5000">
      <span>{{snackbar.message}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>
    
    <v-row class="justify-center align-self-start">
      <v-col cols="12" sm="10" md="10">
        <v-layout column>
          
          <!-- DETAIL -->
          <v-flex>

            <!-- TITLE -->
            <v-row>
              <v-col cols="6">
                <span class="title d-flex"><v-icon class="mr-2">mdi-post-outline</v-icon>Stories</span>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn :disabled="isLoadingData" small color="bg-teal" :dark="!isLoadingData" @click="openFormStory('add')">
                  <v-icon left small>mdi-plus</v-icon>Add New
                </v-btn>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-progress-linear :style="isLoadingData ? '' : 'visibility:hidden'" indeterminate color="bg-teal"></v-progress-linear>

            <v-row class="d-flex justify-space-between">
              <!-- SEARCH BOX -->
              <v-col cols="12" md="6">
                <v-text-field :disabled="isLoadingData" dense solo v-model="searchValue" append-icon="mdi-magnify" label="Search" single-line hide-details>
                </v-text-field>
              </v-col>

              <!-- FILTER BOX -->
              <v-col cols="12" md="3">
                <v-select clearable solo dense hide-details 
                  :disabled="isLoadingData"
                  v-model="selectedCategory"
                  :items="category"
                  item-text="name"
                  item-value="id"
                  label="Category"
                ></v-select>
              </v-col>
            </v-row>

          </v-flex>
          
          <!-- TABLE -->
          <v-flex xs12 class="mt-2" style="height:0px;">
            <v-row class="ma-0">

              <v-col cols="12" class="mt-5 mou-grey--text mou-caption-1 text-center" v-if="storyList(this.searchValue, this.selectedCategory).length === 0">
                No Data Available
              </v-col>

              <v-slide-y-transition hide-on-leave group class="row ma-0 w-100">
                <v-row class="w-100" v-for="story in storyList(this.searchValue, this.selectedCategory)" :key="story.id">
                  <v-col cols="12">
                    <v-card class="pa-5">

                      <!-- STORY MAIN -->
                      <v-row>
                        <v-col cols="12" md="11">
                          <v-row>
                            
                            <!-- IMAGE -->
                            <v-col cols="12" md="3" class="d-flex justify-center">
                              <div class="d-flex w-100">
                                <v-img width="225" height="300"
                                  :src="story.imgError ? story.imgError : (story.image && story.image.filename) ? imgPath.path+story.image.filename+refreshImage : imgPath.imgEmpty"
                                  :lazy-src="imgPath.lazyPath"
                                  v-on:error="story.imgError = imgPath.imgError"
                                >
                                  <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </div>
                            </v-col>

                            <!-- NAME BRIEF SHOW POSITION -->
                            <v-col cols="12" md="9">
                              <v-row class="ma-0">
                                <v-col cols="12" class="pa-0">
                                  <v-row class="ma-0">
                                    <v-col cols="12" md="8" class="pa-0">
                                      <v-row class="ma-0">
                                        <v-col cols="12" class="fc-black text-admin-title fw-500 pa-0">{{story.name}}</v-col>
                                        <v-col cols="12" class="fc-black text-admin-body fw-300 pa-0 mt-5" style="white-space: break-spaces;">{{story.brief}}</v-col>
                                      </v-row>
                                    </v-col>
                                    <v-col cols="12" md="4" class="text-right pa-0">
                                      <v-chip small color="bg-teal" text-color="white" class="mb-1">
                                        Show <v-avatar right class="grey lighten-3">
                                          <v-icon :class="story.show === 1 ? 'success--text' : 'error--text'">{{story.show === 1 ? 'mdi-check-bold' : 'mdi-close-thick'}}</v-icon>
                                        </v-avatar>
                                      </v-chip>
                                      <v-chip class="ml-2 mb-1" small color="bg-teal" text-color="white">
                                        Position <v-avatar right class="grey lighten-3 black--text">{{story.position}}</v-avatar>
                                      </v-chip>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-col>

                          </v-row>
                        </v-col>
                        
                        <!-- ACTION DOTS -->
                        <v-col cols="2" md="1" class="text-right" :style="$vuetify.breakpoint.mdAndDown ? 'position:absolute;right:0;top:0;' : ''">
                          <v-menu transition="slide-x-transition" bottom left offset-y :nudge-width="100">
                            <template v-slot:activator="{ on }">
                              <v-btn :disabled="isLoadingData" small icon v-on="on">
                                <v-icon small>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>

                            <v-list dense>
                              <v-list-item :disabled="isLoadingData"  @click="openFormStory('edit',story)">
                                <v-list-item-icon class="mr-1"><v-icon small>mdi-pencil-outline</v-icon></v-list-item-icon>
                                <v-list-item-title>Edit</v-list-item-title>
                              </v-list-item>
                              <v-list-item :disabled="isLoadingData" @click="openDialogDeleteConfirmation(story.id,story.name)">
                                <v-list-item-icon class="mr-1"><v-icon small>mdi-trash-can-outline</v-icon></v-list-item-icon>
                                <v-list-item-title>Delete</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-col>
                      </v-row>

                      <!-- STORY DETAIL -->
                      <v-row class="ma-0">
                        <v-col cols="12" class="pa-0">
                          <v-expansion-panels class="expansion-transparent">
                            <v-expansion-panel>

                              <v-expansion-panel-header class="pa-0">
                                <v-btn small text :ripple="false" class="d-flex justify-end expansion-button">VIEW DETAIL</v-btn>
                              </v-expansion-panel-header>

                              <v-expansion-panel-content eager class="mt-5">
                                <span v-html="story.content"></span>
                              </v-expansion-panel-content>

                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
                      </v-row>

                    </v-card>
                  </v-col>
                </v-row>
              </v-slide-y-transition>

            </v-row>
          </v-flex>

        </v-layout>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import config from '../../../config';
  import { mapState, mapGetters, mapActions } from 'vuex';
  import FormStory from '../../components/AdminApp/FormStory';
  import DialogDeleteConfirmation from '../../components/AdminApp/DialogDeleteConfirmation';

  export default {
    metaInfo: {
      title: 'Bali Conservation Adventures',
    },

    components: {
      FormStory,
      DialogDeleteConfirmation
    },

    data: () => ({
      isLoadingData: false,
      snackbar: { show: false, color: '', message: ''},

      refreshImage: '',
      imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
      searchValue: '',
      selectedCategory: null,
      category: [
        {id:1,name:'Story'},
        {id:2,name:'Blog'}
      ]
    }),

    computed: {
      ...mapState({
        story: state => state.admin_story.data,
      }),
      ...mapGetters({
        storyList: 'admin_story/searchStory',
      })
    },

    mounted(){
      this.isLoadingData = true;
      this.getStory().then((response) => {
        if(response.errors){
          this.callSnackbar('error',response.errors);
          return;
        }
        this.isLoadingData = false;
      });
    },

    methods: {
      ...mapActions({
        getStory: 'admin_story/getStory',
      }),

      actionRefreshImage(){
        const now = Date.now();
        this.refreshImage = '?'+now;
      },

      openFormStory: function (action, item){
        this.$refs.formStory.openForm(action, item);
      },

      openDialogDeleteConfirmation: function (id, name){
        this.$refs.dialogDeleteConfirmation.openDialog(id, name);
      },

      createUpdateData(data){
        if(data.errors){
          this.callSnackbar('error',data.errors)
          return;
        }
        this.callSnackbar('success',data.success)
        this.actionRefreshImage()
      },

      deleteData(id){
        this.isLoadingData = true;
        this.$store.dispatch('admin_story/deleteStory',id).then((data) => {
          this.isLoadingData = false;
          if(data.errors){
            this.callSnackbar('error',data.errors);
            return;
          }
          this.callSnackbar('success',data.success);
          this.actionRefreshImage()
        });
      },

      callSnackbar(color,message){
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
      }
    }

  }
</script>