<template>
	<v-container fluid class="pt-10">

    <GalleryModal ref="galleryModalRef"></GalleryModal>

    <v-row>
			<v-col cols="1"></v-col>

      <!-- <v-col cols="10">

        <v-row>
          <v-col cols="3" v-for="(gallery, carouselIndex) in gallerySlider" :key="carouselIndex" style="height:200px; background: red">
            <v-card class="h-100 w-100">
              <img height="100%" width="100%" :src="imgPath.path+gallery.filename">
            </v-card>
          </v-col>
        </v-row>

      </v-col> -->

      <v-col cols="10">
        <v-row v-for="(carousel, carouselIndex) in Object.keys(gallerySlider)" :key="carouselIndex" style="height:900px"
					:class="{ 'd-flex d-md-none': carousel === 'fourItemPerSlide', 'd-none d-md-flex d-sm-none': carousel === 'sixItemPerSlide'}"  
          :style="$vuetify.breakpoint.mdAndDown ? 'margin-bottom: -200px;height:900px' : 'height:900px'"
				>

          <v-col cols="12" md="6" class="h-100">
            <v-row class="h-100" :class="$vuetify.breakpoint.mdAndUp ? '' : 'align-content-start'">
              
              <v-col cols="12" class="d-flex" 
                :class="$vuetify.breakpoint.mdAndUp ? 'pb-10 pl-0 pr-10' : ''" 
                :style="$vuetify.breakpoint.mdAndUp ? 'height: 60%' : ''"
              >
                <v-row class="h-100 d-flex align-content-start mt-10">
                  <v-col cols="12" class="text-header fw-700 fc-teal text-center text-md-left">GALLERY</v-col>
                  <v-col cols="12" class="oswald text-content fw-600 mt-5 text-center text-md-left">
                    <v-btn outlined x-large class="btn-solid" href="/gallery">BROWSE ALL GALLERY<v-icon>mdi-chevron-right</v-icon></v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" class="pa-0" style="height:40%" v-if="gallerySlider[carousel] && gallerySlider[carousel][1]">
                <v-row class="h-100 ma-0">

                  <v-col :cols="gallerySlider[carousel][2] ? '6' : '12'" class="pa-0 h-100 d-none d-md-flex d-sm-none">
                    <v-card tile class="h-100 w-100 overflow-hidden cursor-pointer transparent" v-if="gallerySlider[carousel][1]">
                      <v-img class="hover-zoom" height="100%" eager
                        :src="gallerySlider[carousel][1].imgError ? gallerySlider[carousel][1].imgError : gallerySlider[carousel][1] && gallerySlider[carousel][1].filename ? imgPath.path+gallerySlider[carousel][1].filename : imgPath.imgEmpty"
                        v-on:error="gallerySlider[carousel][1].imgError = imgPath.imgError" 
                        @click="openGalleryDialog(1, gallerySlider[carousel])"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img> 
                    </v-card>
                  </v-col>

                  <v-col cols="6 pa-0 h-100 d-block d-md-none" v-if="gallerySlider[carousel][0]">
                    <v-row class="h-100 ma-0">
                      <v-col cols="12" class="pa-0" :style="gallerySlider[carousel][1] ? 'height:50%' : 'height: 100%'">
                        <v-card tile class="h-100 w-100 overflow-hidden cursor-pointer transparent" v-if="gallerySlider[carousel][0]">
                          <v-img class="hover-zoom" height="100%" eager
                            :src="gallerySlider[carousel][0].imgError ? gallerySlider[carousel][0].imgError : gallerySlider[carousel][0] && gallerySlider[carousel][0].filename ? imgPath.path+gallerySlider[carousel][0].filename : imgPath.imgEmpty"
														v-on:error="gallerySlider[carousel][0].imgError = imgPath.imgError"
                            @click="openGalleryDialog(0, gallerySlider[carousel])"
                          >
                            <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img> 
                        </v-card>
                      </v-col>
                      <v-col cols="12" class="h-50 pa-0" v-if="gallerySlider[carousel][1]">
                        <v-card tile class="h-100 w-100 overflow-hidden cursor-pointer transparent" v-if="gallerySlider[carousel][1]">
                          <v-img class="hover-zoom" height="100%" eager
                            :src="gallerySlider[carousel][1].imgError ? gallerySlider[carousel][1].imgError : gallerySlider[carousel][1] && gallerySlider[carousel][1].filename ? imgPath.path+gallerySlider[carousel][1].filename : imgPath.imgEmpty"
														v-on:error="gallerySlider[carousel][1].imgError = imgPath.imgError"
                            @click="openGalleryDialog(1, gallerySlider[carousel])"
                          >
                            <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img> 
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="6 pa-0 h-100" v-if="gallerySlider[carousel][2]">
                    <v-row class="h-100 ma-0">
                      <v-col cols="12" class="pa-0" :style="gallerySlider[carousel][3] ? 'height:50%' : 'height: 100%'">
                        <v-card tile class="h-100 w-100 overflow-hidden cursor-pointer transparent" v-if="gallerySlider[carousel][2]">
                          <v-img class="hover-zoom" height="100%" eager
                            :src="gallerySlider[carousel][2].imgError ? gallerySlider[carousel][2].imgError : gallerySlider[carousel][2] && gallerySlider[carousel][2].filename ? imgPath.path+gallerySlider[carousel][2].filename : imgPath.imgEmpty"
														v-on:error="gallerySlider[carousel][2].imgError = imgPath.imgError"
                            @click="openGalleryDialog(2, gallerySlider[carousel])"
                          >
                            <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img> 
                        </v-card>
                      </v-col>
                      <v-col cols="12" class="h-50 pa-0" v-if="gallerySlider[carousel][3]">
                        <v-card tile class="h-100 w-100 overflow-hidden cursor-pointer transparent" v-if="gallerySlider[carousel][3]">
                          <v-img class="hover-zoom" height="100%" eager
                            :src="gallerySlider[carousel][3].imgError ? gallerySlider[carousel][3].imgError : gallerySlider[carousel][3] && gallerySlider[carousel][3].filename ? imgPath.path+gallerySlider[carousel][3].filename : imgPath.imgEmpty"
														v-on:error="gallerySlider[carousel][3].imgError = imgPath.imgError"
                            @click="openGalleryDialog(3, gallerySlider[carousel])"
                          >
                            <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img> 
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>

                </v-row>
              </v-col>

            </v-row>
          </v-col>

          <v-col cols="6" class="h-100 d-none d-md-block d-sm-none" v-if="gallerySlider[carousel] && gallerySlider[carousel][0]">
            <v-row :style="gallerySlider[carousel][4] ? 'height:75%' : 'height: 100%'">
              <v-col cols="12" class="pa-0 h-100">
                <v-card tile class="h-100 w-100 overflow-hidden cursor-pointer transparent" v-if="gallerySlider[carousel][0]">
                    <v-img class="hover-zoom" height="100%" eager
                      :src="gallerySlider[carousel][0].imgError ? gallerySlider[carousel][0].imgError : gallerySlider[carousel][0] && gallerySlider[carousel][0].filename ? imgPath.path+gallerySlider[carousel][0].filename : imgPath.imgEmpty"
                      v-on:error="gallerySlider[carousel][0].imgError = imgPath.imgError"
                      @click="openGalleryDialog(0, gallerySlider[carousel])"
                    >
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img> 
                </v-card>
              </v-col>
            </v-row>
            <v-row style="height:25%;" v-if="gallerySlider[carousel][4]">
              <v-col :cols="gallerySlider[carousel][5] ? '6' : '12'" class="pa-0 h-100">
                <v-card tile class="h-100 w-100 overflow-hidden cursor-pointer transparent" v-if="gallerySlider[carousel][4]">
                    <v-img class="hover-zoom" height="100%" eager
                      :src="gallerySlider[carousel][4].imgError ? gallerySlider[carousel][4].imgError : gallerySlider[carousel][4] && gallerySlider[carousel][4].filename ? imgPath.path+gallerySlider[carousel][4].filename : imgPath.imgEmpty"
                      v-on:error="gallerySlider[carousel][4].imgError = imgPath.imgError"
                      @click="openGalleryDialog(4, gallerySlider[carousel])"
                    >
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img> 
                </v-card>
              </v-col>
              <v-col cols="6" class="pa-0 h-100" v-if="gallerySlider[carousel][5]">
                <v-card tile class="h-100 w-100 overflow-hidden cursor-pointer transparent" v-if="gallerySlider[carousel][5]">
                    <v-img class="hover-zoom" height="100%" eager
                      :src="gallerySlider[carousel][5].imgError ? gallerySlider[carousel][5].imgError : gallerySlider[carousel][5] && gallerySlider[carousel][5].filename ? imgPath.path+gallerySlider[carousel][5].filename : imgPath.imgEmpty"
                      v-on:error="gallerySlider[carousel][5].imgError = imgPath.imgError"
                      @click="openGalleryDialog(5, gallerySlider[carousel])"
                    >
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img> 
                </v-card>
              </v-col>
            </v-row>
          </v-col>

        </v-row>
      </v-col>
      
			<v-col cols="1"></v-col>
    </v-row>




    <v-row>
      <v-col cols="12" class="pa-0" style="margin-bottom:-10px">
        <v-img width="100%" eager :src="require('@/assets/wave2.png')"></v-img>
      </v-col>
    </v-row>

	</v-container>
</template>

<script>
  import GalleryModal from './GalleryModal';
	import config from '../../../config';
  import { mapState, mapActions, mapGetters } from 'vuex'
	
  export default {
    components: {GalleryModal},

    data: () => ({
			imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
		}),

		computed: {
			...mapState({
				galleries: state => state.galleries.data,
			}),
      ...mapGetters({
        gallerySlider: 'galleries/getHomeGallerySlider'
      }),
		},
    
		methods:{
      openGalleryDialog(index, galleryList){
        this.$refs.galleryModalRef.open(galleryList, index);
      }
    }
  }
</script>