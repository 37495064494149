<template>

  <v-dialog scrollable v-model="isFormOpened" persistent max-width="900px">

    <ImageManager ref="imageManager" @onImageSelected="onImageSelected"></ImageManager>

    <v-snackbar :color="snackbar.color" v-model="snackbar.show" timeout="5000">
      <span>{{snackbar.message}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-form ref="form" v-model="isFormValid" lazy-validation>
      <v-card>

        <!-- TOOLBAR -->
        <v-toolbar dark color="bg-teal">
          <!-- TITLE -->
          <v-toolbar-title v-if="action == 'add'">Add New Program</v-toolbar-title>
          <v-toolbar-title v-if="action == 'edit'">Edit Program</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- CLOSE BUTTON -->
          <v-btn :disabled="isLoadingSubmit" icon dark @click="isFormOpened = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-progress-linear class="pa-1" :style="isLoadingSubmit ? '' : 'visibility:hidden'" indeterminate color="bg-teal"></v-progress-linear>

        <!-- FORM INPUT -->
        <v-card-text>
          <v-container>

            <!-- PROGRAM MAIN -->
            <v-row class="ma-0">
              <v-col cols="12" class="pa-0">
                <v-row>
                  <v-col cols="12" class="fc-black text-caption-1 d-flex align-center py-0 fw-600">Main Information</v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-card class="pa-4">
                      <v-row class="justify-center ma-0">
                        <!-- PHOTO -->
                        <v-col cols="12" md="4" class="d-flex justify-center pa-0 pr-3">
                          <v-hover v-slot:default="{ hover }">
                            <div class="d-flex cursor-pointer p-relative w-100" @click="isLoadingSubmit ? false : openImageManager('programMain',formData.image)"  
                              style="width:225px;height:300px;"
                            >
                              <v-img width="100%"
                                :src="isImageError ? imgPath.imgError+refreshImage : (formData.image && formData.image.filename) ? imgPath.path+formData.image.filename+refreshImage : imgPath.imgEmpty+refreshImage"
                                :lazy-src="imgPath.lazyPath+refreshImage"
                                v-on:error="isImageError = true"
                              >
                              </v-img>
                              <div class="p-absolute d-flex w-100 h-100 justify-center align-center" v-if="hover && !isLoadingSubmit">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn fab small dark color="blue-grey" class="mr-1" v-bind="attrs" v-on="on">
                                        <v-icon>mdi mdi-image-search</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Change Image</span>
                                </v-tooltip>
                              </div>
                            </div>
                          </v-hover>
                          <v-col cols="12" class="imageRules">
                            <v-text-field :disabled="isLoadingSubmit" v-model="formData.image" :rules="imageRules" required></v-text-field>
                          </v-col>
                        </v-col>
                        <v-col cols="12" md="8" class="pa-0 pl-3 pt-5 pt-md-0">
                          <v-row>
                            <!-- NAME -->
                            <v-col cols="12" class="pt-0">
                              <v-text-field :disabled="isLoadingSubmit" v-model="formData.name" :rules="nameRules" label="Name*" required class="mt-0"></v-text-field>
                            </v-col>

                            <!-- BRIEF -->
                            <v-col cols="12">
                              <v-textarea class="pa-0" :disabled="isLoadingSubmit" v-model="formData.brief" :rules="briefRules" label="Brief*" auto-grow required></v-textarea>
                            </v-col>

                            <!-- SHOW -->
                            <v-col cols="12" md="5" class="d-flex align-center py-0">
                              <v-switch color="success" :disabled="isLoadingSubmit" v-model="formData.show" inset label="Show on homepage"></v-switch>
                            </v-col>

                            <!-- POSITION -->
                            <v-col cols="12" md="7" class="d-flex align-center py-0">
                              <v-text-field :disabled="isLoadingSubmit" v-model="formData.position" :rules="positionRules" min=1 type="number" label="Position*" required></v-text-field>
                              <v-btn :disabled="isLoadingSubmit" icon @click="formData.position > 1 ? formData.position-- : 1"><v-icon>mdi-minus-circle</v-icon></v-btn>
                              <v-btn :disabled="isLoadingSubmit" icon @click="formData.position++" ><v-icon>mdi-plus-circle</v-icon></v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- PROGRAM SLIDER -->
            <v-row class="ma-0 mt-5">
              <v-col cols="12" class="pa-0">
                <v-row>
                  <v-col cols="6" class="fc-black text-caption-1 d-flex align-center fw-600">Program Slider</v-col>
                  <v-col cols="6" class="d-flex justify-end">
                  <v-btn :disabled="isLoadingSubmit" small color="bg-teal" :dark="!isLoadingSubmit" @click="openImageManager('programSlider')">
                    <v-icon left small>mdi-plus</v-icon>Add Slider Image
                  </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="formData.program_slider.length === 0">
                  <v-col cols="12" class="pt-0">
                    <v-card class="pa-3 grey--text d-flex justify-space-between align-center">No data</v-card>
                  </v-col>
                </v-row>
                <v-row v-if="formData.program_slider.length > 0">
                  <v-col cols="12" sm="6" md="3" class="d-flex justify-center" v-for="(slider,index) in formData.program_slider" :key="index">
                    <v-card>
                      <v-img width="100%" height="150px"
                        :src="slider.imgError ? slider.imgError : slider.image && slider.image.filename ? imgPath.path+slider.image.filename+refreshImage : imgPath.imgEmpty"
                        :lazy-src="imgPath.lazyPath"
                        v-on:error="slider.imgError = imgPath.imgError"
                      >
                      </v-img>
                      <v-row class="ma-0">
                        <v-col cols="12" class="d-flex align-center">
                          <v-text-field :disabled="isLoadingSubmit" v-model="slider.position" :rules="positionRules" min=1 type="number" label="Position*" required></v-text-field>
                          <v-btn :disabled="isLoadingSubmit" icon @click="slider.position > 1 ? slider.position-- : 1"><v-icon>mdi-minus-circle</v-icon></v-btn>
                          <v-btn :disabled="isLoadingSubmit" icon @click="slider.position++" ><v-icon>mdi-plus-circle</v-icon></v-btn>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center pa-0">
                          <v-btn :disabled="isLoadingSubmit" text color="error" @click="actionRemoveSlider(index)">Remove</v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- PROGRAM DETAIL -->
            <v-row class="ma-0 mt-5">
              <v-col cols="12" class="pa-0">
                <v-row>
                  <v-col cols="12" class="fc-black text-caption-1 d-flex align-center fw-600">Program Detail</v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4" class="pt-0">
                    <v-hover v-slot:default="{ hover }">
                      <div class="d-flex cursor-pointer p-relative w-100" @click="isLoadingSubmit ? false : openImageManager('programDetail',formData.program_detail.image)" 
                        style="width:225px;height:300px;"
                      >
                        <v-img width="100%"
                          :src="isImageError ? imgPath.imgError+refreshImage : 
                                (formData.program_detail && formData.program_detail && formData.program_detail.image && formData.program_detail.image.filename) ? 
                                  imgPath.path+formData.program_detail.image.filename+refreshImage : imgPath.imgEmpty+refreshImage"
                          :lazy-src="imgPath.lazyPath+refreshImage"
                          v-on:error="isImageError = true"
                        >
                        </v-img>
                        <div class="p-absolute d-flex w-100 h-100 justify-center align-center" v-if="hover && !isLoadingSubmit">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn fab small dark color="blue-grey" class="mr-1" v-bind="attrs" v-on="on">
                                  <v-icon>mdi mdi-image-search</v-icon>
                              </v-btn>
                            </template>
                            <span>Change Image</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </v-hover>
                  </v-col>
                  <v-col cols="12" md="8" class="pt-0">
                    <v-textarea label="Input program detail..." solo hide-details="auto" :disabled="isLoadingSubmit" v-model="formData.program_detail.content" auto-grow required rows="12"></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- ITINERARY -->
            <v-row class="ma-0 mt-5">
              <v-col cols="12" class="pa-0">
                <v-row>
                  <v-col cols="12" sm="6" class="fc-black text-caption-1 d-flex align-center justify-center justify-sm-start fw-600">Itinerary</v-col>
                  <v-col cols="12" sm="6" class="d-flex  justify-center justify-sm-end">
                    <v-switch class="mt-0" hide-details="auto" color="success" :disabled="isLoadingSubmit" v-model="formData.program_detail.itinerary_show" inset label="Show on homepage"></v-switch>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col cols="12" class="pt-0">
                    <v-textarea label="Input itinerary..." solo hide-details="auto" :disabled="isLoadingSubmit" v-model="formData.program_detail.itinerary" auto-grow required></v-textarea>
                  </v-col>
                </v-row> -->
                <!-- NULL ITINERARY -->
                <v-row v-if="formData && formData.program_detail && formData.program_detail.itinerary && formData.program_detail.itinerary.length === 0">
                  <v-col cols="12" class="pt-0">
                    <v-card class="pa-3 grey--text d-flex justify-space-between align-center">
                      No data
                      <v-btn :disabled="isLoadingSubmit" small color="bg-teal" :dark="!isLoadingSubmit" @click="actionAddItinerary()">
                        <v-icon left small>mdi-plus</v-icon>Add
                      </v-btn>
                    </v-card>
                  </v-col>
                </v-row>

                <!-- ITINERARY LIST -->
                <v-row v-if="formData && formData.program_detail && formData.program_detail.itinerary && formData.program_detail.itinerary.length > 0">
                  <v-col cols="12" class="pt-0" v-for="(itinerary, itineraryIndex) in formData.program_detail.itinerary" :key="itineraryIndex">
                    <v-card class="px-5">
                      <v-row>
                        <v-col>Itinerary Section {{itineraryIndex+1}}</v-col>
                        <v-col cols="auto">
                          <v-btn :disabled="isLoadingSubmit" small icon text color="error" @click="actionRemoveItinerary(itineraryIndex)"><v-icon>mdi mdi-trash-can-outline</v-icon></v-btn>
                        </v-col>
                      </v-row>
                      <div class="row">
                        <v-col cols="12" class="pt-0">
                          <v-text-field label="Input Itinerary description..." solo hide-details="auto" :disabled="isLoadingSubmit" v-model="formData.program_detail.itinerary[itineraryIndex].description" auto-grow></v-text-field>
                        </v-col>

                        <!-- TIMESLOT LIST -->
                        <v-col cols="12" class="pb-0" v-if="formData && formData.program_detail && formData.program_detail.itinerary && formData.program_detail.itinerary[itineraryIndex] && formData.program_detail.itinerary[itineraryIndex].timeslot && formData.program_detail.itinerary[itineraryIndex].timeslot.length > 0">
                          <v-row>
                            <v-col cols="12" class="pb-0">Schedule</v-col>
                          </v-row>
                          <v-row v-for="(timeslot, timeslotIndex) in formData.program_detail.itinerary[itineraryIndex].timeslot" :key="timeslotIndex">
                            <v-col cols="2" class="pb-0">
                              <v-text-field label="Time..." solo hide-details="auto" :disabled="isLoadingSubmit" v-model="formData.program_detail.itinerary[itineraryIndex].timeslot[timeslotIndex].time"></v-text-field>
                            </v-col>
                            <v-col class="pb-0">
                              <v-text-field label="Schedule description..." solo hide-details="auto" :disabled="isLoadingSubmit" v-model="formData.program_detail.itinerary[itineraryIndex].timeslot[timeslotIndex].description"></v-text-field>
                            </v-col>
                            <v-col cols="auto" class="pb-0">
                              <v-btn :disabled="isLoadingSubmit" small icon text color="error" @click="actionRemoveTimeslot(timeslotIndex)"><v-icon>mdi mdi-trash-can-outline</v-icon></v-btn>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col cols="12" class="text-right">
                          <v-btn :disabled="isLoadingSubmit" small color="bg-teal" :dark="!isLoadingSubmit" @click="actionAddTimeslot(itineraryIndex)">
                            <v-icon left small>mdi-plus</v-icon>Add Schedule
                          </v-btn>
                        </v-col>

                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-end pt-0">
                    <v-btn :disabled="isLoadingSubmit" small color="bg-teal" :dark="!isLoadingSubmit" @click="actionAddItinerary()">
                      <v-icon left small>mdi-plus</v-icon>Add More Itinerary
                    </v-btn>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>

            <!-- WHATTOBRING -->
            <v-row class="ma-0 mt-5">
              <v-col cols="12" class="pa-0">
                <v-row>
                  <v-col cols="12" sm="6" class="fc-black text-caption-1 d-flex align-center justify-center justify-sm-start fw-600">What to bring or wear ?</v-col>
                  <v-col cols="12" sm="6" class="d-flex  justify-center justify-sm-end">
                    <v-switch class="mt-0" hide-details="auto" color="success" :disabled="isLoadingSubmit" v-model="formData.program_detail.whattobring_show" inset label="Show on homepage"></v-switch>
                  </v-col>
                </v-row>
                <v-row v-if="formData.program_detail.whattobring.length === 0">
                  <v-col cols="12" class="pt-0">
                    <v-card class="pa-3 grey--text d-flex justify-space-between align-center">
                      No data
                      <v-btn :disabled="isLoadingSubmit" small color="bg-teal" :dark="!isLoadingSubmit" @click="actionAddWhattobring()">
                        <v-icon left small>mdi-plus</v-icon>Add
                      </v-btn>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row v-if="formData.program_detail.whattobring.length > 0">
                  <v-col cols="12" class="pt-0" v-for="(whattobring, index) in formData.program_detail.whattobring" :key="index">
                    <v-row class="ma-0">
                      <v-col class="pa-0">
                        <v-text-field label="Input list..." solo hide-details="auto" :disabled="isLoadingSubmit" v-model="formData.program_detail.whattobring[index]" auto-grow required></v-text-field>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn :disabled="isLoadingSubmit" small icon text color="error" @click="actionRemoveWhattobring(index)"><v-icon>mdi mdi-trash-can-outline</v-icon></v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-end pt-0">
                    <v-btn :disabled="isLoadingSubmit" small color="bg-teal" :dark="!isLoadingSubmit" @click="actionAddWhattobring()">
                      <v-icon left small>mdi-plus</v-icon>Add More
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- INCLUSION -->
            <v-row class="ma-0 mt-5">
              <v-col cols="12" class="pa-0">
                <v-row>
                  <v-col cols="12" sm="6" class="fc-black text-caption-1 d-flex align-center justify-center justify-sm-start fw-600">What's included ?</v-col>
                  <v-col cols="12" sm="6" class="d-flex  justify-center justify-sm-end">
                    <v-switch class="mt-0" hide-details="auto" color="success" :disabled="isLoadingSubmit" v-model="formData.program_detail.inclusion_show" inset label="Show on homepage"></v-switch>
                  </v-col>
                </v-row>
                <v-row v-if="formData.program_detail.inclusion.length === 0">
                  <v-col cols="12" class="pt-0">
                    <v-card class="pa-3 grey--text d-flex justify-space-between align-center">
                      No data
                      <v-btn :disabled="isLoadingSubmit" small color="bg-teal" :dark="!isLoadingSubmit" @click="actionAddInclusion()">
                        <v-icon left small>mdi-plus</v-icon>Add
                      </v-btn>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row v-if="formData.program_detail.inclusion.length > 0">
                  <v-col cols="12" class="pt-0" v-for="(inclusion, index) in formData.program_detail.inclusion" :key="index">
                    <v-row class="ma-0">
                      <v-col class="pa-0">
                        <v-text-field label="Input list..." solo hide-details="auto" :disabled="isLoadingSubmit" v-model="formData.program_detail.inclusion[index]" auto-grow required></v-text-field>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn :disabled="isLoadingSubmit" small icon text color="error" @click="actionRemoveInclusion(index)"><v-icon>mdi mdi-trash-can-outline</v-icon></v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-end pt-0">
                    <v-btn :disabled="isLoadingSubmit" small color="bg-teal" :dark="!isLoadingSubmit" @click="actionAddInclusion()">
                      <v-icon left small>mdi-plus</v-icon>Add More
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- EXCLUSION -->
            <v-row class="ma-0 mt-5">
              <v-col cols="12" class="pa-0">
                <v-row>
                  <v-col cols="12" sm="6" class="fc-black text-caption-1 d-flex align-center justify-center justify-sm-start fw-600">What's exclude ?</v-col>
                  <v-col cols="12" sm="6" class="d-flex  justify-center justify-sm-end">
                    <v-switch class="mt-0" hide-details="auto" color="success" :disabled="isLoadingSubmit" v-model="formData.program_detail.exclusion_show" inset label="Show on homepage"></v-switch>
                  </v-col>
                </v-row>
                <v-row v-if="formData.program_detail.exclusion.length === 0">
                  <v-col cols="12" class="pt-0">
                    <v-card class="pa-3 grey--text d-flex justify-space-between align-center">
                      No data
                      <v-btn :disabled="isLoadingSubmit" small color="bg-teal" :dark="!isLoadingSubmit" @click="actionAddExclusion()">
                        <v-icon left small>mdi-plus</v-icon>Add
                      </v-btn>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row v-if="formData.program_detail.exclusion.length > 0">
                  <v-col cols="12" class="pt-0" v-for="(exclusion, index) in formData.program_detail.exclusion" :key="index">
                    <v-row class="ma-0">
                      <v-col class="pa-0">
                        <v-text-field label="Input list..." solo hide-details="auto" :disabled="isLoadingSubmit" v-model="formData.program_detail.exclusion[index]" auto-grow required></v-text-field>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn :disabled="isLoadingSubmit" small icon text color="error" @click="actionRemoveExclusion(index)"><v-icon>mdi mdi-trash-can-outline</v-icon></v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-end pt-0">
                    <v-btn :disabled="isLoadingSubmit" small color="bg-teal" :dark="!isLoadingSubmit" @click="actionAddExclusion()">
                      <v-icon left small>mdi-plus</v-icon>Add More
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- GROUP -->
            <v-row class="ma-0">
              <v-col cols="12" class="pa-0">
                <v-row>
                  <v-col cols="12" sm="6" class="fc-black text-caption-1 d-flex align-center justify-center justify-sm-start fw-600">Group</v-col>
                  <v-col cols="12" sm="6" class="d-flex  justify-center justify-sm-end">
                    <v-switch class="mt-0" hide-details="auto" color="success" :disabled="isLoadingSubmit" v-model="formData.program_detail.group_show" inset label="Show on homepage"></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pt-0">
                    <v-textarea label="Input group policy..." solo hide-details="auto" :disabled="isLoadingSubmit" v-model="formData.program_detail.group" auto-grow required></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="isLoadingSubmit" color="grey darken-1" text @click="isFormOpened = false;">Cancel</v-btn>
          <v-btn :disabled="isLoadingSubmit" class="fc-teal" text @click="formSave()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>

</template>

<script>
  import config from '../../../config';
  import ImageManager from '../../components/AdminApp/ImageManager';

  export default {
    components: {
      ImageManager
    },

    data: () => ({
      refreshImage: '',
      imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },

      action: null,
      isImageError: false,
      isFormOpened: false,
      isFormValid: true,
      isLoadingSubmit: false,
      snackbar: { show: false, color: '', message: ''},

      formData: {
        id: null,
        image: null,
        name: null,
        brief: null,
        show: 0,
        position: 1,
        program_slider: [],
        program_detail: {
          image: null,
          content: null,
          itinerary: null,
          itinerary_show: 0,
          whattobring: [],
          whattobring_show: 0,
          inclusion: [],
          inclusion_show: 0,
          exclusion: [],
          exclusion_show: 0,
          group: null,
          group_show: 0
        }
      },

      imageRules: [ 
        v => (!!v) || 'Image is required', 
      ],
      nameRules: [ 
        v => (!!v) || 'Name is required', 
        v => (v && !!v.trim()) || 'Name is required', 
        v => (v && v.trim().length > 3) || 'Name must be more than 3 characters'
      ],
      briefRules: [ 
        v => !!v || 'Brief is required', 
        v => (v && !!v.trim()) || 'Brief is required', 
        v => (v && v.trim().length > 5) || 'Brief must be more than 5 characters'
      ],
      positionRules: [ 
        v => (!isNaN(parseInt(v))) || 'Position must be a number', 
        v => (!isNaN(parseInt(v)) && v > 0) || 'Position must be greater than 0', 
        v => (!isNaN(parseInt(v)) && (v%1 === 0)) || 'Position cannot be a decimal', 
      ],
    }),

    methods: {
      actionRefreshImage(){
        const now = Date.now();
        this.refreshImage = '?'+now;
      },

      initVariable(){
        this.action = 'add';
        this.isImageError = false;
        this.formData.id = null;
        this.formData.image = null;
        this.formData.name = null;
        this.formData.brief = null;
        this.formData.show = 0;
        this.formData.position = 1;
        this.formData.program_slider = [];
        this.formData.program_detail.image = null;
        this.formData.program_detail.content = null;
        this.formData.program_detail.itinerary = [];
        this.formData.program_detail.itinerary_show = 0;
        this.formData.program_detail.whattobring = [];
        this.formData.program_detail.whattobring_show = 0;
        this.formData.program_detail.inclusion = [];
        this.formData.program_detail.inclusion_show = 0;
        this.formData.program_detail.exclusion = [];
        this.formData.program_detail.exclusion_show = 0;
        this.formData.program_detail.group = null;
        this.formData.program_detail.group_show = 0;
        this.actionRefreshImage();
      },

      openForm(action, item){
        if(!action){
          return;
        }

        this.initVariable();
        if(action == 'edit' && item){
          const data = JSON.parse(JSON.stringify(item));
          this.action = 'edit';
          this.formData.id = data.id;
          this.formData.image = data.image;
          this.formData.name = data.name;
          this.formData.brief = data.brief;
          this.formData.show = data.show;
          this.formData.position = data.position;
          this.formData.program_slider = data.program_slider;
          this.formData.program_detail.image = data.program_detail.image;
          this.formData.program_detail.content = data.program_detail.content;
          this.formData.program_detail.itinerary = data.program_detail.itinerary || [];
          this.formData.program_detail.itinerary_show = data.program_detail.itinerary_show;
          this.formData.program_detail.whattobring = data.program_detail.whattobring;
          this.formData.program_detail.whattobring_show = data.program_detail.whattobring_show;
          this.formData.program_detail.inclusion = data.program_detail.inclusion;
          this.formData.program_detail.inclusion_show = data.program_detail.inclusion_show;
          this.formData.program_detail.exclusion = data.program_detail.exclusion;
          this.formData.program_detail.exclusion_show = data.program_detail.exclusion_show;
          this.formData.program_detail.group = data.program_detail.group;
          this.formData.program_detail.group_show = data.program_detail.group_show;
        }
        this.isFormOpened = true;
        this.$nextTick(() => this.$refs.form.resetValidation())
      },

      actionRemoveSlider(index){
        this.formData.program_slider.splice(index, 1);
      },

      actionAddItinerary(){
        const newItinerary = {
          description: '',
          timeslot: []
        }
        this.formData.program_detail.itinerary.push(newItinerary);
      },

      actionRemoveItinerary(index){
        this.formData.program_detail.itinerary.splice(index, 1);
      },

      actionAddTimeslot(index){
        const newTimeslot = {
          time: '',
          description: ''
        }
        this.formData.program_detail.itinerary[index].timeslot.push(newTimeslot);
      },

      actionRemoveTimeslot(index){
        this.formData.program_detail.itinerary[index].splice(index, 1);
      },

      actionAddWhattobring(){
        this.formData.program_detail.whattobring.push('')
      },

      actionRemoveWhattobring(index){
        this.formData.program_detail.whattobring.splice(index, 1);
      },

      actionAddInclusion(){
        this.formData.program_detail.inclusion.push('')
      },

      actionRemoveInclusion(index){
        this.formData.program_detail.inclusion.splice(index, 1);
      },

      actionAddExclusion(){
        this.formData.program_detail.exclusion.push('')
      },

      actionRemoveExclusion(index){
        this.formData.program_detail.exclusion.splice(index, 1);
      },

      formSave(){
        this.formData.image_id = this.formData && this.formData.image && this.formData.image.id;
        this.formData.program_detail.image_id = this.formData && this.formData.program_detail && this.formData.program_detail.image && this.formData.program_detail.image.id;
        this.formData.program_detail.itinerary = this.formData && this.formData.program_detail && this.formData.program_detail.itinerary && JSON.stringify(this.formData.program_detail.itinerary);
        this.formData.program_slider.forEach((slider) => {
          slider.image_id = slider.image && slider.image.id
        })

        this.isLoadingSubmit = true;
        if(this.$refs.form.validate()){
          const target = (this.action === 'edit') ? 'admin_program/updateProgram' :  'admin_program/addProgram';
          this.$store.dispatch(target, this.formData).then((data) => {
            this.isLoadingSubmit = false;
            if(data.errors){
              this.callSnackbar('error',data.errors);
              return;
            }
            this.$emit('formProgramSaved',data)
            this.isFormOpened = false;  
          });
          return;
        }

        this.callSnackbar('error','Please fill all the required field');
        this.isLoadingSubmit = false;
      },

      openImageManager(action,currentImage){
        this.$refs.imageManager.openImageManager(action,'image',currentImage);
      },

      onImageSelected(event){
        if(event.action === 'programMain'){
          this.formData.image = event.selectedImage;
        }

        if(event.action === 'programSlider'){
          const newSlider = {
            id: null,
            image: event.selectedImage,
            position: 1
          }
          this.formData.program_slider.push(newSlider);
        }

        if(event.action === 'programDetail'){
          this.formData.program_detail.image = event.selectedImage;
        }
        
      },

      callSnackbar(color,message){
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
      }

    },

  }
</script>