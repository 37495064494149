import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueMeta from 'vue-meta'
import VueCoreVideoPlayer from 'vue-core-video-player'
import './assets/style.scss';
import './assets/fonts.scss';

import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use(VueMeta)
Vue.use(VueCoreVideoPlayer)
Vue.use( CKEditor );

import VueAnalytics from 'vue-analytics';
Vue.use(VueAnalytics, {
	id: 'UA-207264129-1',
  router
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
