<template>

  <v-dialog v-model="isFormOpened" persistent max-width="600px">

    <v-snackbar :color="snackbar.color" v-model="snackbar.show" timeout="5000">
      <span>{{snackbar.message}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-form ref="form" v-model="isFormValid" lazy-validation>
      <v-card>

        <!-- TOOLBAR -->
        <v-toolbar dark color="bg-teal">
          <!-- TITLE -->
          <v-toolbar-title v-if="action == 'add'">Add New User</v-toolbar-title>
          <v-toolbar-title v-if="action == 'edit'">Edit User</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- CLOSE BUTTON -->
          <v-btn :disabled="isLoadingSubmit" icon dark @click="isFormOpened = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-progress-linear :style="isLoadingSubmit ? '' : 'visibility:hidden'" indeterminate color="secondary"></v-progress-linear>

        <!-- FORM INPUT -->
        <v-card-text>
          <v-container>
            <v-row>

              <!-- NAMA -->
              <v-col cols="12">
                <v-text-field :disabled="isLoadingSubmit" v-model="formData.name" :rules="nameRules" label="Name*" required></v-text-field>
              </v-col>

              <!-- EMAIL -->
              <v-col cols="12">
                <v-text-field :disabled="isLoadingSubmit" v-model="formData.email" :rules="emailRules" label="Email*" required></v-text-field>
              </v-col>

              <!-- PASSWORD -->
              <v-col cols="12" v-if="action == 'add'">
                <v-text-field :disabled="isLoadingSubmit"
                  v-model="formData.password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="passwordRules"
                  label="Password*"
                  @click:append="showPassword = !showPassword"
                  required
                ></v-text-field>
              </v-col>

              <!-- PASSWORD CONFIRM -->
              <v-col cols="12" v-if="action == 'add'">
                <v-text-field :disabled="isLoadingSubmit" 
                  v-model="formData.password_confirmation"
                  :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPasswordConfirmation ? 'text' : 'password'"
                  :rules="[(formData.password === formData.password_confirmation) || 'Password not match']"
                  label="Password Confirmation*"
                  @click:append="showPasswordConfirmation = !showPasswordConfirmation"
                  required
                ></v-text-field>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="isLoadingSubmit" color="grey darken-1" text @click="isFormOpened = false;">Cancel</v-btn>
          <v-btn :disabled="isLoadingSubmit" class="fc-teal" text @click="formSave()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>

</template>

<script>
  export default {

    data: () => ({
      action: null,
      isFormOpened: false,
      isFormValid: true,
      isLoadingSubmit: false,
      snackbar: { show: false, color: '', message: ''},

      showPassword: false,
      showPasswordConfirmation: false,

      formData: {
        id: null,
        email: null,
        password: null,
        password_confirmation: null,
        name: null,
      },

      nameRules: [ v => !!v || 'Name is required', v => (v && v.length > 3) || 'Name must be more than 3 characters'],
      emailRules: [ v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
      passwordRules: [ v => !!v || 'Password is required', v => (v && v.length > 3) || 'Password must be more than 3 characters'],
    }),

    methods: {
      initVariable: function(){
        this.action = 'add';
        this.formData.id = null;
        this.formData.email = null;
        this.formData.password = null;
        this.formData.password_confirmation = null;
        this.formData.name = null;
        this.showPassword = false;
        this.showPasswordConfirmation = false;
      },

      openForm(action, item){
        if(!action){
          return;
        }

        this.initVariable();
        if(action == 'edit' && item){
          this.action = 'edit';
          this.formData.id = item.id;
          this.formData.email = item.email;
          this.formData.name = item.name;
        }
        this.isFormOpened = true;
        this.$nextTick(() => this.$refs.form.resetValidation())
      },

      formSave(){
        this.isLoadingSubmit = true;

        if(this.$refs.form.validate()){
          const target = (this.action === 'edit') ? 'admin_user/updateUser' :  'admin_user/addUser';
          this.$store.dispatch(target, this.formData).then((data) => {
            this.isLoadingSubmit = false;
            if(data.errors){
              this.callSnackbar('error',data.errors);
              return;
            }
            this.$emit('formUserSaved',data)
            this.isFormOpened = false;  
            });
          return;
        }

        this.callSnackbar('error','Please fill all the required field');
        this.isLoadingSubmit = false;
      },

      callSnackbar(color,message){
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
      }

    },

  }
</script>