import api from '../../api/webapp-api';

// initial state
const state = () => ({
	data: [],
})

// getters
const getters = {
	getHomeProgramSlider: (state)  => {
		const programs = state.data;

		const oneItemPerSlide = [];
		const twoItemPerSlide = [];
		const threeItemPerSlide = [];

		let oneItemArray = [];
		let twoItemArray = [];
		let threeItemArray = [];

		for(let i=0; i<programs.length; i++){
			oneItemArray.push(programs[i]);
			twoItemArray.push(programs[i]);
			threeItemArray.push(programs[i]);

			if(oneItemArray.length%1 === 0 || i === programs.length-1){
				oneItemPerSlide.push(oneItemArray);
				oneItemArray = [];
			}
			if(twoItemArray.length%2 === 0 || i === programs.length-1){
				twoItemPerSlide.push(twoItemArray);
				twoItemArray = [];
			}
			if(threeItemArray.length%3 === 0 || i === programs.length-1){
				threeItemPerSlide.push(threeItemArray);
				threeItemArray = [];
			}
		}

		return {oneItemPerSlide,twoItemPerSlide,threeItemPerSlide};
	},

	getProgramExplore: (state) => (programId)  => {
		const programs = state.data;

		let randomProgram = programs
		.map((a) => ({sort: Math.random(), value: a}))
		.sort((a, b) => a.sort - b.sort)
		.map((a) => a.value)

		if(programId){
			randomProgram = randomProgram.filter((program) => program.programId !== programId)
		}
		const result = randomProgram.slice(0,6)
		return result;
	},

	getProgramExploreMore: (state) => (programId)  => {
		const programs = state.data;

		let randomProgram = programs
		.map((a) => ({sort: Math.random(), value: a}))
		.sort((a, b) => a.sort - b.sort)
		.map((a) => a.value)

		if(programId){
			randomProgram = randomProgram.filter((program) => program.programId !== programId)
		}
		const result = randomProgram.slice(0,3)
		return result;
	},

	getProgramById: (state) => (programId)  => {
		const programs = state.data;
		const result = programs.find((program) => program.programId === programId);
		if(result && result.program_detail && result.program_detail.itinerary && !Array.isArray(result.program_detail.itinerary)){
			result.program_detail.itinerary = JSON.parse(result.program_detail.itinerary);
		}
		return result;
	},
}

// actions
const actions = {
	getProgram({commit}){
		return new Promise((resolve,reject) => {
			api.getProgram().then(response => {
				const programs = response.data.filter((dt) => dt.show === 1) || [];
				commit('SET_PROGRAM', programs)
				resolve({'success':programs});
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},
}

// mutations
const mutations = {
	SET_PROGRAM(state, program){
		state.data = program;
	},
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}