<template>
	<v-container fluid class="pt-10 pb-0">

		<v-row>
			<v-col cols="12" class="black--text">

				<v-row class="text-left">
          <v-col cols="1"></v-col>
          <v-col cols="10">
              <v-row>
                <v-col cols="12" class="text-header text-center fw-700 fc-teal">STORIES</v-col>
                <!-- <v-col cols="12" class="text-body text-justify fw-300 fc-black">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                </v-col> -->
              </v-row>
          </v-col>
          <v-col cols="1"></v-col>
				</v-row>

        <v-row v-for="(carousel, carouselIndex) in Object.keys(storySlider)" :key="carouselIndex" 
					:class="{ 
                    'd-block d-sm-none': carousel === 'oneItemPerSlide', 
                    'd-none d-sm-block d-md-none': carousel === 'threeItemPerSlide',  
                    'd-none d-md-block d-lg-none': carousel === 'fourItemPerSlide',
                    'd-none d-lg-block': carousel === 'fiveItemPerSlide'
          }"  
				>
					<v-carousel class="carousel-black" hide-delimiter-background :hide-delimiters="$vuetify.breakpoint.smAndUp" :show-arrows="$vuetify.breakpoint.smAndUp" height="100%" prev-icon="mdi-arrow-left" next-icon="mdi-arrow-right">
            <v-carousel-item v-for="(slider,indexSlider) in storySlider[carousel]" :key="indexSlider">
							<v-row>
								<v-col cols="0" sm="1" class="d-none d-sm-flex"></v-col>
								<v-col cols="12" sm="10" class="pa-0">
                  <v-row class="d-flex justify-center pb-10">

                    <!-- FIRST -->
                    <v-col cols="12" sm="6" md="6" lg="4" class="pa-1" style="height:600px" v-if="slider[0]">
                      <v-hover v-slot:default="{ hover }">
                        <v-card class="h-100 hover-zoom-container overflow-hidden" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                          <v-img eager class="hover-zoom-target" height="100%" 
                            :src="slider[0].imgError ? slider[0].imgError : slider[0].image && slider[0].image.filename ? imgPath.path+slider[0].image.filename : imgPath.imgEmpty"
														v-on:error="slider[0].imgError = imgPath.imgError"
                          >
                            <template v-slot:placeholder>
															<v-row class="fill-height ma-0" align="center" justify="center">
																<v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
															</v-row>
														</template>
                          </v-img>
                          <v-flex class="h-100 ma-0 p-absolute d-flex align-end justify-center v-card--reveal mou-caption-1 w-100"
                            style="bottom:0;"
                          >
                            <v-row class="w-100 h-100 d-flex align-content-end ma-0">
                              <v-col cols="12" class="story-hover-title text-title white--text fw-700 text-uppercase overflow-hidden px-7 py-5"
                                style="height:90px;background-color: rgba(0, 0, 0, .4);white-space: nowrap;text-overflow: ellipsis;" 
                              >
                                {{slider[0].name}}
                              </v-col>
                              <v-expand-transition>
                                <v-col v-if="hover" cols="12" style="max-height:502px;background-color: rgba(0, 0, 0, .4);" class="px-5 pa-0 d-flex overflow-hidden">
                                  <v-row class="h-100 ma-0">
                                    <v-col style="max-height:412px;" cols="12" class="overflow-auto d-flex opensans text-content white--text"
                                        v-if="slider[0] && slider[0].brief"
                                    >
                                      {{slider[0].brief}}
                                    </v-col>
                                    <v-col style="height:90px;" cols="12" class="d-flex oswald white--text fw-500 align-center">
                                      <v-btn tile class="bg-teal" :href="'/story/'+slider[0].storyId">Read More</v-btn>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-expand-transition>
                            </v-row>
                          </v-flex>
                        </v-card>
                      </v-hover>
                    </v-col>

                    <!-- SECOND -->
                    <v-col cols="12" sm="6" md="6" lg="8" class="py-0" style="height: 600px;" v-if="slider[1]">
                      <v-row class="h-100">
                        
                        <!-- TOP -->
                        <v-col cols="12" class="py-0" :style="slider[2] ? 'height: 300px' : 'height: 600px'" v-if="slider[1]">
                          <v-row class="h-100">

                            <!-- TOP LEFT -->
                            <v-col :cols="slider[3] ? '8' : '12'" class="pa-1 h-100" v-if="slider[1]">
                              <v-hover v-slot:default="{ hover }">
                                <v-card class="h-100 hover-zoom-container overflow-hidden" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                                  <v-img eager class="hover-zoom-target" height="100%" 
                                    :src="slider[1].imgError ? slider[1].imgError : slider[1].image && slider[1].image.filename ? imgPath.path+slider[1].image.filename : imgPath.imgEmpty"
                                    v-on:error="slider[1].imgError = imgPath.imgError"
                                  >
                                    <template v-slot:placeholder>
                                      <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                  <v-flex class="h-100 ma-0 p-absolute d-flex align-end justify-center v-card--reveal mou-caption-1 w-100"
                                    style="bottom:0;"
                                  >
                                    <v-row class="w-100 h-100 d-flex align-content-end ma-0">
                                      <v-col cols="12" class="story-hover-title text-title white--text fw-700 text-uppercase overflow-hidden px-7 py-5"
                                        style="height:90px;background-color: rgba(0, 0, 0, .4);white-space: nowrap;text-overflow: ellipsis;" 
                                      >
                                        {{slider[1].name}}
                                      </v-col>
                                      <v-expand-transition>
                                        <v-col v-if="hover" cols="12" style="max-height:202px;background-color: rgba(0, 0, 0, .4);" class="px-5 pa-0 d-flex overflow-hidden">
                                          <v-row class="h-100 ma-0">
                                            <v-col style="max-height:112px;" cols="12" class="overflow-auto d-flex opensans text-content white--text"
                                               v-if="slider[1] && slider[1].brief"
                                            >
                                              {{slider[1].brief}}
                                            </v-col>
                                            <v-col style="height:90px;" cols="12" class="d-flex oswald white--text fw-500 align-center">
                                              <v-btn tile class="bg-teal" :href="'/story/'+slider[1].storyId">Read More</v-btn>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                      </v-expand-transition>
                                    </v-row>
                                  </v-flex>
                                </v-card>
                              </v-hover>
                            </v-col>

                            <!-- TOP RIGHT -->
                            <v-col cols="4" class="pa-1 h-100" v-if="slider[3]">
                              <v-hover v-slot:default="{ hover }">
                                <v-card class="h-100 hover-zoom-container overflow-hidden" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                                  <v-img eager class="hover-zoom-target" height="100%"
                                    :src="slider[3].imgError ? slider[3].imgError : slider[3].image && slider[3].image.filename ? imgPath.path+slider[3].image.filename : imgPath.imgEmpty"
                                    v-on:error="slider[3].imgError = imgPath.imgError"
                                  >
                                    <template v-slot:placeholder>
                                      <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                  <v-flex class="h-100 ma-0 p-absolute d-flex align-end justify-center v-card--reveal mou-caption-1 w-100"
                                    style="bottom:0;"
                                  >
                                    <v-row class="w-100 h-100 d-flex align-content-end ma-0">
                                      <v-col cols="12" class="story-hover-title text-title white--text fw-700 text-uppercase overflow-hidden px-7 py-5"
                                        style="height:90px;background-color: rgba(0, 0, 0, .4);white-space: nowrap;text-overflow: ellipsis;" 
                                      >
                                        {{slider[3].name}}
                                      </v-col>
                                      <v-expand-transition>
                                        <v-col v-if="hover" cols="12" style="max-height:202px;background-color: rgba(0, 0, 0, .4);" class="px-5 pa-0 d-flex overflow-hidden">
                                          <v-row class="h-100 ma-0">
                                            <v-col style="max-height:112px;" cols="12" class="overflow-auto d-flex opensans text-content white--text"
                                               v-if="slider[3] && slider[3].brief"
                                            >
                                              {{slider[3].brief}}
                                            </v-col>
                                            <v-col style="height:90px;" cols="12" class="d-flex oswald white--text fw-500 align-center">
                                              <v-btn tile class="bg-teal" :href="'/story/'+slider[3].storyId">Read More</v-btn>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                      </v-expand-transition>
                                    </v-row>
                                  </v-flex>
                                </v-card>
                              </v-hover>
                            </v-col>

                          </v-row>
                        </v-col>

                        <!-- BOTTOM -->
                        <v-col cols="12" class="py-0" style="height:300px" v-if="slider[2]">
                          <v-row class="h-100">

                            <!-- BOTTOM LEFT -->
                            <v-col :cols="slider[4] ? '4' : '12'" class="pa-1 h-100" v-if="slider[2]">
                              <v-hover v-slot:default="{ hover }">
                                <v-card class="h-100 hover-zoom-container overflow-hidden" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                                  <v-img eager class="hover-zoom-target" height="100%"
                                    :src="slider[2].imgError ? slider[2].imgError : slider[2].image && slider[2].image.filename ? imgPath.path+slider[2].image.filename : imgPath.imgEmpty"
                                    v-on:error="slider[2].imgError = imgPath.imgError"
                                  >
                                    <template v-slot:placeholder>
                                      <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                  <v-flex class="h-100 ma-0 p-absolute d-flex align-end justify-center v-card--reveal mou-caption-1 w-100"
                                    style="bottom:0;"
                                  >
                                    <v-row class="w-100 h-100 d-flex align-content-end ma-0">
                                      <v-col cols="12" class="story-hover-title text-title white--text fw-700 text-uppercase overflow-hidden px-7 py-5"
                                        style="height:90px;background-color: rgba(0, 0, 0, .4);white-space: nowrap;text-overflow: ellipsis;" 
                                      >
                                        {{slider[2].name}}
                                      </v-col>
                                      <v-expand-transition>
                                        <v-col v-if="hover" cols="12" style="max-height:202px;background-color: rgba(0, 0, 0, .4);" class="px-5 pa-0 d-flex overflow-hidden">
                                          <v-row class="h-100 ma-0">
                                            <v-col style="max-height:112px;" cols="12" class="overflow-auto d-flex opensans text-content white--text"
                                               v-if="slider[2] && slider[2].brief"
                                            >
                                              {{slider[2].brief}}
                                            </v-col>
                                            <v-col style="height:90px;" cols="12" class="d-flex oswald white--text fw-500 align-center">
                                              <v-btn tile class="bg-teal" :href="'/story/'+slider[2].storyId">Read More</v-btn>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                      </v-expand-transition>
                                    </v-row>
                                  </v-flex>
                                </v-card>
                              </v-hover>
                            </v-col>

                            <!-- BOTTOM RIGHT -->
                            <v-col cols="8" class="pa-1 h-100" v-if="slider[4]">
                              <v-hover v-slot:default="{ hover }">
                                <v-card class="h-100 hover-zoom-container overflow-hidden" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                                  <v-img eager class="hover-zoom-target" height="100%"
                                    :src="slider[4].imgError ? slider[4].imgError : slider[4].image && slider[4].image.filename ? imgPath.path+slider[4].image.filename : imgPath.imgEmpty"
                                    v-on:error="slider[4].imgError = imgPath.imgError"
                                  >
                                    <template v-slot:placeholder>
                                      <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                  <v-flex class="h-100 ma-0 p-absolute d-flex align-end justify-center v-card--reveal mou-caption-1 w-100"
                                    style="bottom:0;"
                                  >
                                    <v-row class="w-100 h-100 d-flex align-content-end ma-0">
                                      <v-col cols="12" class="story-hover-title text-title white--text fw-700 text-uppercase overflow-hidden px-7 py-5"
                                        style="height:90px;background-color: rgba(0, 0, 0, .4);white-space: nowrap;text-overflow: ellipsis;" 
                                      >
                                        {{slider[4].name}}
                                      </v-col>
                                      <v-expand-transition>
                                        <v-col v-if="hover" cols="12" style="max-height:202px;background-color: rgba(0, 0, 0, .4);" class="px-5 pa-0 d-flex overflow-hidden">
                                          <v-row class="h-100 ma-0">
                                            <v-col style="max-height:112px;" cols="12" class="overflow-auto d-flex opensans text-content white--text"
                                               v-if="slider[4] && slider[4].brief"
                                            >
                                              {{slider[4].brief}}
                                            </v-col>
                                            <v-col style="height:90px;" cols="12" class="d-flex oswald white--text fw-500 align-center">
                                              <v-btn tile class="bg-teal" :href="'/story/'+slider[4].storyId">Read More</v-btn>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                      </v-expand-transition>
                                    </v-row>
                                  </v-flex>
                                </v-card>
                              </v-hover>
                            </v-col>

                          </v-row>
                        </v-col>

                      </v-row>
                    </v-col>

                  </v-row>
                </v-col>
								<v-col cols="0" sm="1" class="d-none d-sm-flex"></v-col>
							</v-row>
            </v-carousel-item>
          </v-carousel>
        </v-row>

				<v-row class="text-center mt-5">
					<v-col cols="12" class="oswald text-content fw-600" style="z-index:1;">
						<v-btn outlined x-large class="btn-solid" href="/story">SEE MORE</v-btn>
					</v-col>
				</v-row>

			</v-col>
		</v-row>

    <v-row>
      <v-col cols="12" class="pa-0" style="margin-bottom:-10px">
        <v-img width="100%" eager :src="require('@/assets/wave.png')">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>

	</v-container>
</template>

<script>
	import config from '../../../config';
  import { mapState, mapActions, mapGetters } from 'vuex'
	
  export default {
    data: () => ({
			imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      }
		}),

		computed: {
			...mapState({
				stories: state => state.stories.data,
			}),
      ...mapGetters({
        storySlider: 'stories/getHomeStorySlider'
      }),
		}
  }
</script>