import axios from 'axios'
import config from '../../config';

const api = axios.create({
    baseURL: config.WEBAPP_API_URL
})

export default {

    getBanner(){
        return api.get('banner');
    },
    getProgram(){
        return api.get('program');
    },
    getStory(){
        return api.get('story');
    },
    getGallery(){
        return api.get('gallery');
    },
    getGalleryHome(){
        return api.get('gallery-home');
    },
    getQuote(){
        return api.get('quote');
    },
    getTestimonial(){
        return api.get('testimonial');
    },
    getContact(){
        return api.get('contact');
    },
    sendMessage(message){
        return api.post('sendMessage',message);
    },
}