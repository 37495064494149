import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.isDialogOpened),callback:function ($$v) {_vm.isDialogOpened=$$v},expression:"isDialogOpened"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"error"}},[_c(VToolbarTitle,[_vm._v("Delete Confirmation")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.isDialogOpened = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_vm._v(" Are you sure want to delete this data ? "),(_vm.dialogData.name)?_c(VCard,{staticClass:"pa-3 mt-4"},[_vm._v(" "+_vm._s(_vm.dialogData.name)+" ")]):_vm._e()],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.dialogDeleteConfirmed()}}},[_vm._v("Delete")]),_c(VBtn,{attrs:{"color":"grey darken-1","text":""},on:{"click":function($event){_vm.isDialogOpened = false;}}},[_vm._v("Cancel")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }