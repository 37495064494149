import api from '../../api/webapp-api';

// initial state
const state = () => ({
	data: [],
})

// getters
const getters = {
	getHomeStorySlider: (state)  => {
		const stories = state.data;

		const oneItemPerSlide = [];
		const threeItemPerSlide = [];
		const fourItemPerSlide = [];
		const fiveItemPerSlide = [];

		let oneItemArray = [];
		let threeItemArray = [];
		let fourItemArray = [];
		let fiveItemArray = [];

		for(let i=0; i<stories.length; i++){
			oneItemArray.push(stories[i]);
			threeItemArray.push(stories[i]);
			fourItemArray.push(stories[i]);
			fiveItemArray.push(stories[i]);

			if(oneItemArray.length%1 === 0 || i === stories.length-1){
				oneItemPerSlide.push(oneItemArray);
				oneItemArray = [];
			}
			if(threeItemArray.length%3 === 0 || i === stories.length-1){
				threeItemPerSlide.push(threeItemArray);
				threeItemArray = [];
			}
			if(fourItemArray.length%4 === 0 || i === stories.length-1){
				fourItemPerSlide.push(fourItemArray);
				fourItemArray = [];
			}
			if(fiveItemArray.length%5 === 0 || i === stories.length-1){
				fiveItemPerSlide.push(fiveItemArray);
				fiveItemArray = [];
			}
		}

		return {oneItemPerSlide,threeItemPerSlide,fourItemPerSlide,fiveItemPerSlide};
	},

	getStoryAndBlog: (state) => (exceptStoryId) => {
		let result = {};
		const dataCollection = state.data;


		let stories = dataCollection.filter((data) => data.type === 1)
		let blogs = dataCollection.filter((data) => data.type === 2)

		if(exceptStoryId){
			stories = stories.filter((data) => data.storyId !== exceptStoryId)
			blogs = blogs.filter((data) => data.storyId !== exceptStoryId)
		}

		const storyResult = stories.splice(0,3);
		const blogResult = blogs.splice(0,3);

		result = {
			stories: storyResult,
			blogs: blogResult
		}
		return result;
	},

	getStoryById: (state) => (storyId)  => {
		const stories = state.data;
		const result = stories.find((story) => story.storyId === storyId);
		if(result && result.content){
			const configuredContent = result.content.replace('<oembed','<iframe').replace('</oembed>','</iframe>').replace('url=','src=').replace('/watch?v=','/embed/');
			result.content = configuredContent
		}
		return result;
	},
}

// actions
const actions = {
	getStory({commit}){
		return new Promise((resolve,reject) => {
			api.getStory().then(response => {
				const stories = response.data.filter((st) => st.show === 1) || [];
				commit('SET_STORY', stories)
				resolve({'success':stories});
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},
}

// mutations
const mutations = {
	SET_STORY(state, story){
		state.data = story;
	},
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}