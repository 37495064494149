<template>
  <v-row justify="center">
    <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" scrollable width="900" v-model="isOpened">
      <v-card>

        <v-card-title class="bg-teal white--text">
          <v-row>
            <v-col>Why Book Bali Conservation Adventures?</v-col>
            <v-col cols="auto">
              <v-btn icon dark @click="isOpened = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text class="pt-5">
          <v-row class="pr-5 pl-3 mb-5" v-for="(item,i) in items" :key="i">
            <v-col cols="12">
              <v-row class="ma-0">
                <v-col cols="2" sm="1" class="pa-0 text-center">
                  <v-img eager :src="require('@/assets/check.png')" height="40" width="40">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate class="fc-teal"></v-progress-circular>
                    </v-row>
                  </template>
                  </v-img>
                </v-col>
                <v-col cols="10" sm="11" class="pa-0 fc-teal d-flex align-center">
                  <span class="segoesc fw-500" style="font-size:1.25rem;">{{item.title}}</span>
                  <span class="ml-3">{{item.subtitle}}</span>
                </v-col>
              </v-row>
              <v-row class="ma-0 pt-3">
                <v-col cols="1" class="pa-0" v-if="$vuetify.breakpoint.smAndUp"></v-col>
                <v-col cols="12" sm="11" class="pa-0 text-body text-justify text-content fw-300">{{item.description}}</v-col>
              </v-row>
            </v-col>
          </v-row>

        </v-card-text>

      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
  export default {
    data: () => ({
      isOpened: null,
      items: [
        {
          title: 'Open space away from crowd',
          subtitle: '',
          description: `Our programs’ venues are not the typical busy tourist attractions. Therefore, you will not be around a large crowd. 
                        Activities are conducted mostly in open space where fresh air circulates. 
                        However, the covid-19 protocols still have to be adhered to.`
        },
        {
          title: 'Pick the right place',
          subtitle: '',
          description: `You can find so many conservation facilities in Bali through the internet. Some have good
                        websites, images and stories. But the ones needing your support usually do not have fancy
                        internet representations that do justice to the important works they are doing. We will help
                        you choose the right facility for your specific interests and criteria.`
        },
        {
          title: 'Choose the right activity',
          description: `Some people enjoy half-day beach cleaning for the sea turtles, or spend a day caring for
                        stray dogs in the shelter. Others opt to volunteer in an animal rescue center, visit a wildlife
                        sanctuary or go underwater to plant baby-corals. All are equally helpful. Please feel free to
                        inform us of the interest closest to your heart.`
        },
        {
          title: 'Design your group activities',
          subtitle: '(Group starts from 10 people)',
          description: `Most conservation facilities are geared towards the welfare of animal species in their care,
                        not yours (rightfully so). If you are organizing a group activity, we can assist to combine your
                        conservation activity with coffee break, group lunch/dinner, transportation and
                        accommodation to make the visit more enjoyable.`
        },
        {
          title: 'Bali Conservation Adventures Escort',
          subtitle: '',
          description: `Whether you are coming individually or in a group, we will arrange that you are escorted by
                        a guide from Bali Conservation Adventures who will ensure that you get all the knowledge
                        and experience you come for.`
        },
        {
          title: 'Knowledge to share',
          subtitle: '',
          description: `Upon completing your experience, we will equip you with an electronic material that you can
                        use to take your action further when you get home. This e-material is ready to use for social
                        media and can easily be shared with your friends and family.`
        }
      ]
    }),

    methods: {
      open() {
        this.isOpened = true;
      }
    },
  }
</script>

