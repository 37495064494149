<template>
	<v-container fluid class="pa-0">

    <v-parallax height="500" :src="require('@/assets/assets/dog3.jpg')" style="filter: brightness(90%);" 
      :class="$vuetify.breakpoint.xs ? 'dog-small' : ''">

      <v-row class="d-flex align-center h-100">

        <v-carousel cycle class="carousel-white delimiter-black" hide-delimiter-background :hide-delimiters="$vuetify.breakpoint.smAndUp" :show-arrows="$vuetify.breakpoint.smAndUp" height="100%" prev-icon="mdi-arrow-left" next-icon="mdi-arrow-right">
          <v-carousel-item v-for="quote in quotes" :key="quote.id">
            <v-row class="h-100 d-flex align-center">
              <v-col cols="1"></v-col>

              <v-col cols="10">
                <v-card tile class="pa-10 text-quote text-center fw-300 quote-hover">
                  <div class="text-left p-absolute w-100" style="top:-40px;left:-30px;"><v-icon x-large style="font-size:80px;" color="white">mdi-format-quote-open</v-icon></div>
                  <div class="fw-300 font-italic pt-5 ws-break-spaces">{{quote.quote}}</div>
                  <div class="text-quote-caption fw-600 text-center mt-5 ws-break-spaces">{{quote.author}}</div>
                  <div class="text-right p-absolute w-100" style="bottom:-40px;left:30px;"><v-icon x-large style="font-size:80px;" color="white">mdi-format-quote-close</v-icon></div>
                </v-card>
              </v-col>

              <v-col cols="1"></v-col>
            </v-row>
          </v-carousel-item>
        </v-carousel>

      </v-row>

    </v-parallax>

	</v-container>
</template>


<script>
	import ProgramModal from './ProgramModal';
	import config from '../../../config';
  import { mapState, mapActions } from 'vuex'
	
  export default {
		components: {
      ProgramModal
		},
		
    data: () => ({
			imgPath: {
        path: config.STORAGE_URL,
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
		}),

		computed: {
			...mapState({
				quotes: state => state.quotes.data,
			}),
		}
  }
</script>

